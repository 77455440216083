import { gql } from '@apollo/client';

export const INHABILITAR_USUARIO_CURSO = gql`
    mutation newInhabilitarUsuarioCurso($data: DataUsuarioCurso!) {
        inhabilitarUsuarioCurso(data: $data)
    }
`;

export const HABILITAR_USUARIO_CURSO = gql`
    mutation newHabilitarUsuarioCurso($data: DataUsuarioCurso!) {
        habilitarUsuarioCurso(data: $data)
    }
`;

export const HABILITAR_CERTIFICADO = gql`
    mutation newHabilitarCertificadoo($data: DataUsuarioCurso!) {
        habilitarCertificado(data: $data)
    }
`;

export const ELIMINAR_USUARIO_CURSO = gql`
    mutation newEliminarUsuarioCurso($data: DataUsuarioCurso!) {
        eliminarUsuarioCurso(data: $data)
    }
`;

export const ELIMINAR_ADMINISTRADOR_CURSO = gql`
    mutation newEliminarAdministradorCurso($data: DataUsuarioCurso!) {
        eliminarAdminCurso(data: $data)
    }
`;

export const ACTUALIZAR_PERMISOS_ADMIN_CURSO = gql`
    mutation newUpdatePermisosAdministradorCurso(
        $data: DataUsuarioCurso!
        $permisos: [String]!
    ) {
        updatePermisosAdminCurso(data: $data, permisos: $permisos)
    }
`;

export const GET_ALL_ADMINS = gql`
    query {
        getAdmins {
            idAdministrador
            superior
            nombres
            apellidos
            correo
            puesto
            tipo
            permisos
            foto
            celular
            nombreCompleto
            banInactivo
            cursos {
                idCurso
                permisos
            }
        }
    }
`;

export const GET_ALL_ADMINS_CURSOS = gql`
    query {
        getAdmins {
            idAdministrador
            nombreCompleto
            tipo
            cursos {
                idCurso
                permisos
            }
        }
    }
`;

export const ADD_ADMIN = gql`
    mutation createNewAdmin($admin: AdminInput!) {
        createAdmin(admin: $admin)
    }
`;

export const UPDATE_ADMIN = gql`
    mutation editarAdministrador($user: AdminInput!) {
        editarAdmin(user: $user)
    }
`;

export const EDITAR_PERMISOS = gql`
    mutation editarPermisosAdministrador($dataPermisos: EditarPermisos!) {
        editarPermisosAdministrador(dataPermisos: $dataPermisos)
    }
`;

export const SET_CURSO_ADMIN = gql`
    mutation newAsignarAdminCurso($data: CursosAdminInput!) {
        asignarAdminCurso(data: $data)
    }
`;
