import {
    makeStyles,
    createStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './spinner.sass';
import { loginTheme } from '../../theme.styles';

const useStyles = makeStyles(() =>
    createStyles({
        displaySpinner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.5)',
            top: 0,
            left: 0,
            zIndex: 1400,
        },
        displayNoneSpinner: {
            display: 'none',
        },
    }),
);

export default function Spinner(props) {
    const classes = useStyles();

    return (
        <div className={classes[props.class]}>
            <CircularProgress color='secondary' size={100} />
        </div>
    );
}

export const LoginSpinner = (props) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={loginTheme}>
            <div className={classes[props.class]}>
                <CircularProgress color='secondary' size={100} />
            </div>
        </ThemeProvider>
    );
};
