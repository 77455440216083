import { Typography } from '@material-ui/core';
import { DetailTitleInfo } from '../../../interfaces';
import { useStyles } from '../courses-users.mat-ui';
import ProgressDetail from '../progress-user/progress-user.compt';
import './detail-title.sass';

export default function DetailTitle(props: DetailTitleInfo) {
    const classes = useStyles();

    return (
        <section className='sectionHeader'>
            <article>
                <Typography gutterBottom variant='h3' component='h2'>
                    {props.data.nombre}
                </Typography>

                <div className='divDescription'>
                    <div className='description'>
                        <Typography
                            className={classes.descriptionModule}
                            gutterBottom
                            variant='subtitle1'
                            component='p'
                        >
                            {props.data.descripcionLarga}
                        </Typography>
                    </div>

                    <img alt='img' src={props.data.foto}></img>
                </div>
            </article>

            <article>
                <ProgressDetail
                    data={{
                        progressSystem: props.data.progressSystem,
                        progressUser: props.data.progressUser,
                        calificacion: props.data.calificacion,
                    }}
                />
            </article>
        </section>
    );
}
