
export const bucketLocation = process.env.REACT_APP_BUCKET || '';

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}');

export const appUrl = process.env.REACT_APP_URL || 'http://localhost:3000/';

export const apiUrl = process.env.REACT_APP_API_URL || '';

export const apolloServerUrl = process.env.REACT_APP_APOLLO_SERVER_URL || 'http://127.0.0.1:5001/ocogroup-centro-capacitacion/us-central1/graphql';
