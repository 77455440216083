import React, { useState } from 'react';
import {
    InfoComponetMultimedia,
    InfoCardMultimedia,
    DataControlModalParent,
} from '../../../interfaces';
import {
    Card,
    CardActionArea,
    CardContent,
    Typography,
    CircularProgress,
    Menu,
    MenuItem,
    IconButton,
    Box,
} from '@material-ui/core';
import { useStyles } from '../courses-users.mat-ui';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import {
    MULTIMEDIA_TYPE_DOCUMENT,
    MULTIMEDIA_TYPE_VIDEO,
    MULTIMEDIA_TYPE_TEST,
} from '../../../constants';
import DoneIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SummaryTest from '../summary-test-user/summary-test-user.compt';

export default function MultimediaCard(props: InfoComponetMultimedia) {
    const classes = useStyles();
    const info: InfoCardMultimedia = props.data;
    const [test, setTest] = useState<DataControlModalParent>({ open: false });
    const [opcionSelect, setOpcionSelect] = React.useState<null | HTMLElement>(
        null,
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpcionSelect(event.currentTarget);
    };

    const closeModalTest = (value: boolean) => {
        setTest({ open: false, closeParent: closeModalTest });
    };

    const handleClose = () => {
        setOpcionSelect(null);
    };

    const handleMenuItem = (item) => {
        handleClose();
        setTest({ open: true, closeParent: closeModalTest, test: [item] });
    };

    return (
        <Card className={classes.multimediaCard}>
            <div className={classes.titleMultimediaCard}>
                <Typography variant='subtitle2' component='div'>
                    {info.title}
                </Typography>

                <IconButton
                    aria-label='menu intentos'
                    size='small'
                    onClick={handleClick}
                    className={
                        info.type !== MULTIMEDIA_TYPE_TEST || !info.banTest
                            ? classes.displayNone
                            : ''
                    }
                >
                    <MoreVertIcon />
                </IconButton>

                <Menu
                    id='simple-menu'
                    anchorEl={opcionSelect}
                    keepMounted
                    open={Boolean(opcionSelect)}
                    onClose={handleClose}
                >
                    {info.intentosExamen.map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleMenuItem(item)}
                        >{`Intento ${index + 1}`}</MenuItem>
                    ))}
                </Menu>
            </div>
            <CardActionArea>
                <CardContent
                    onClick={() => props.cardMultimediaEvent(props.data)}
                    className={classes.cardContentMultimedia}
                >
                    <div className={classes.containerIconMultimediaCard}>
                        {info.type === MULTIMEDIA_TYPE_VIDEO ? (
                            <PlayCircleFilledIcon
                                className={classes.iconMultimediaCard}
                            />
                        ) : info.type === MULTIMEDIA_TYPE_DOCUMENT ? (
                            <PictureAsPdfIcon
                                className={classes.iconMultimediaCard}
                            />
                        ) : (
                            <PlaylistAddCheckIcon
                                className={classes.iconMultimediaCard}
                            />
                        )}
                    </div>

                    <div
                        className={
                            info.type !== MULTIMEDIA_TYPE_VIDEO
                                ? classes.displayNone
                                : classes.circularProgressContainer
                        }
                    >
                        <Box position='relative' display='inline-flex'>
                            <CircularProgress
                                size={30}
                                className={classes.circularProgressDonut}
                                variant='determinate'
                                value={
                                    info.porcentaje && info.porcentaje !== null
                                        ? info.porcentaje
                                        : 0
                                }
                            />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position='absolute'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <Typography
                                    variant='caption'
                                    component='div'
                                    className={classes.textDoneDonutVideoCard}
                                >
                                    {`${info.porcentaje}%`}
                                </Typography>
                            </Box>
                        </Box>
                    </div>

                    <DoneIcon
                        className={
                            info.type === MULTIMEDIA_TYPE_DOCUMENT &&
                            info.download
                                ? classes.doneProgressDonut
                                : info.type === MULTIMEDIA_TYPE_TEST &&
                                    info.banTest
                                  ? classes.doneProgressDonut
                                  : classes.displayNone
                        }
                    />
                </CardContent>
            </CardActionArea>

            <SummaryTest options={test} />
        </Card>
    );
}
