import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useGeneralStyles } from '../../styles-matui';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';

export default function Regresar(props) {
    const navigate = useNavigate();
    const classes = useGeneralStyles();

    const actionBack = () => {
        if (props.initializerStateNuevoCurso) {
            props.initializerStateNuevoCurso();
        }

        navigate(props.route);
    };

    return (
        <div>
            <Button className={classes.btnRegresar} onClick={actionBack}>
                <ArrowBackIcon className={classes.iconRegresar} />
                Regresar
            </Button>
        </div>
    );
}
