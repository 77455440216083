import { gql } from '@apollo/client';

export const GET_ALL_COURSES_REPORTS = gql`
    query {
        getCursos {
            idCurso
            fechaAlta
            nombre
            banInactivo
            cantAdministradores
            modulos {
                idModulo
                nombre
                fechaAlta
                sesiones {
                    idSesion
                    fechaAlta
                    nombre
                }
            }
        }
    }
`;

export const GET_ALL_COURSES = gql`
    query {
        getCursos {
            idCurso
            fechaAlta
            idAdministrador
            foto
            nombre
            descripcionCorta
            tipo
            descripcionLarga
            banInactivo
            cantUsuarios
            duracion
            cantAdministradores
            modulos {
                idModulo
            }
        }
    }
`;

export const NUEVO_CURSO = gql`
    mutation newCurso($idCurso: String!, $curso: NewCurso!) {
        createCurso(idCurso: $idCurso, curso: $curso)
    }
`;

export const DELETE_CURSO_GQL = gql`
    mutation newDeleteCurso($idCurso: String!) {
        deleteCurso(idCurso: $idCurso)
    }
`;

export const INHAB_CURSO_GQL = gql`
    mutation inhabilitarCurso($idCurso: String!) {
        inhabCurso(idCurso: $idCurso)
    }
`;

export const HAB_CURSO_GQL = gql`
    mutation habilitarCurso($idCurso: String!) {
        habCurso(idCurso: $idCurso)
    }
`;

export const SET_MODULES_COURSE = gql`
    mutation setModulosSesiones($idCurso: String!, $data: [NewModulo]!) {
        asignarModulos(idCurso: $idCurso, data: $data) {
            idModulo
            nombre
            foto
            sesiones {
                idSesion
                nombre
                foto
                documento
            }
        }
    }
`;

export const SET_SESION_COURSE = gql`
    mutation setSesion(
        $idCurso: String!
        $idModulo: String!
        $data: NewSesion!
    ) {
        asignarSesion(idCurso: $idCurso, idModulo: $idModulo, data: $data) {
            idSesion
            nombre
            foto
            documento
            fechaAlta
            videos {
                idVideo
                nombre
                duracionVideo
                valorVideo
            }
        }
    }
`;

export const DELETE_VIDEO = gql`
    mutation deleteVideoSesion(
        $idCurso: String!
        $idModulo: String!
        $idSesion: String!
        $idVideo: String!
    ) {
        deleteVideo(
            idCurso: $idCurso
            idModulo: $idModulo
            idSesion: $idSesion
            idVideo: $idVideo
        )
    }
`;

export const DELETE_SESION = gql`
    mutation deleteSesionModule(
        $idCurso: String!
        $idModulo: String!
        $idSesion: String!
    ) {
        deleteSesion(
            idCurso: $idCurso
            idModulo: $idModulo
            idSesion: $idSesion
        )
    }
`;

export const DELETE_MODULE = gql`
    mutation deleteModuleCourse($idCurso: String!, $idModulo: String!) {
        deleteModule(idCurso: $idCurso, idModulo: $idModulo)
    }
`;

export const GET_COURSE = gql`
    query obtenerCurso($idCurso: String!) {
        getCurso(idCurso: $idCurso) {
            idCurso
            fechaAlta
            idAdministrador
            foto
            nombre
            descripcionCorta
            descripcionLarga
            tipo
            banInactivo
            cantUsuarios
            duracion
            cantAdministradores
            modulos {
                idModulo
                fechaAlta
                foto
                nombre
                descripcionCorta
                sesiones {
                    idSesion
                    fechaAlta
                    foto
                    nombre
                    nombreDocumento
                    nombreExamen
                    nombreFoto
                    examen {
                        pregunta
                        respuestas {
                            respuesta
                            correcta
                        }
                    }
                    descripcionCorta
                    descripcionLarga
                    documento
                    videos {
                        idVideo
                        nombre
                        duracionVideo
                        valorVideo
                    }
                }
            }
        }
    }
`;
