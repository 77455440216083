import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useGeneralStyles } from '../../../styles-matui';
import { Button, OutlinedInput, Avatar } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { VACIO, STATUS } from '../../../constants';
import { UPDATE_ADMIN } from '../administradores.graphql';
import { useMutation } from '@apollo/client';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkIcon from '@material-ui/icons/Work';
import { EditAdministrador } from '../administradores.interfaces';
import Spinner from '../../spinner/spinner.compt';
import { DataControlModalParent } from '../../../interfaces';
import Snackbars from '../../snackbar/snackbar.compt';
import { useStyles } from '../administradores.styles-matui';
import './editar-admin.sass';

export default function ModalEditarAdministrador(props) {
    const classes = useGeneralStyles();
    const classCourse = useStyles();
    const [formData, setFormData] = useState<EditAdministrador>(
        props.dataAdmin,
    );
    const [updateAdminMutation] = useMutation(UPDATE_ADMIN);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const dataEditInit: EditAdministrador = {
        idAdministrador: VACIO,
        nombres: VACIO,
        apellidos: VACIO,
        correo: VACIO,
        celular: VACIO,
        puesto: VACIO,
        banPerfil: false,
    };

    useEffect(() => {
        if (formData.idAdministrador === VACIO && props.open) {
            setFormData(props.dataAdmin);
        }
    });

    const submitEditAdmin = () => {
        setOpenSpinner(true);

        const foto =
            'https://firebasestorage.googleapis.com/v0/b/mvac-dev.appspot.com/o/perfiles%2F[id]%2F[nombre]?alt=media';
        let adminEditData = {
            nombres: formData.nombres,
            apellidos: formData.apellidos,
            correo: formData.correo,
            puesto: formData.puesto,
            celular: formData.celular,
            idAdministrador: formData.idAdministrador,
        };

        if (formData.fileFoto) {
            adminEditData = Object.assign(adminEditData, {
                foto: foto
                    .replace('[id]', formData.idAdministrador)
                    .replace(
                        '[nombre]',
                        formData.nombreFoto ? formData.nombreFoto : '',
                    ),
            });
        }

        updateAdminMutation({ variables: { user: adminEditData } })
            .then((resp) => {
                const data = {
                    ...formData,
                    foto: formData.fileFoto
                        ? foto
                              .replace('[id]', formData.idAdministrador)
                              .replace(
                                  '[nombre]',
                                  formData.nombreFoto
                                      ? formData.nombreFoto
                                      : '',
                              )
                        : formData.foto,
                };

                props.closeParent({
                    ...adminEditData,
                    ...data,
                });
                setFormData(dataEditInit);
                props.refreshUserAdmins('Se actualizo al usuario con éxito');
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al actualizar usuario`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const onChangeForm =
        (prop: keyof EditAdministrador) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormData({ ...formData, [prop]: event.target.value });
        };

    const onClickDialogOpenClose = () => {
        setFormData(dataEditInit);
        props.closeParent();
    };

    const uploadImg = (event) => {
        const file = event.target.files[0];
        const fileReader: FileReader = new FileReader();
        const name = event.target.files[0].name;

        fileReader.onloadend = () => {
            setFormData({
                ...formData,
                foto: fileReader.result,
                fileFoto: file,
                nombreFoto: name,
            });

            event.target.value = null;
        };

        fileReader.readAsDataURL(file);
    };

    return (
        <div>
            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />

            <Dialog
                maxWidth='md'
                scroll='body'
                open={props.open}
                className={classes.modal}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
            >
                <div className='containerModal'>
                    <DialogTitle
                        id='form-dialog-title'
                        className={classes.titleForm}
                    >
                        {formData.banPerfil
                            ? 'Información personal'.toUpperCase()
                            : 'Editar administrador'.toUpperCase()}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div
                                className={
                                    formData.banPerfil
                                        ? classCourse.containerFotoPerfil
                                        : classCourse.displayNone
                                }
                            >
                                <input
                                    accept='image/*'
                                    className={classes.fotoInput}
                                    id='fotoPerfil'
                                    onChange={uploadImg}
                                    type='file'
                                />

                                <label htmlFor='fotoPerfil'>
                                    <Button color='primary' component='span'>
                                        Seleccionar foto de perfil
                                    </Button>
                                </label>

                                <Avatar
                                    className={classCourse.avatarPerfil}
                                    alt={formData.nombres}
                                    src={formData.foto ? formData.foto : VACIO}
                                />
                            </div>

                            <FormControl
                                variant='outlined'
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor='nombres'>
                                    Nombre(s)
                                </InputLabel>
                                <OutlinedInput
                                    id='nombres'
                                    type='text'
                                    value={formData.nombres}
                                    onChange={onChangeForm('nombres')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <PersonIcon color='primary' />
                                        </InputAdornment>
                                    }
                                    labelWidth={83}
                                />
                            </FormControl>

                            <FormControl
                                variant='outlined'
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor='apellidos'>
                                    Apellidos
                                </InputLabel>
                                <OutlinedInput
                                    id='apellidos'
                                    type='text'
                                    value={formData.apellidos}
                                    onChange={onChangeForm('apellidos')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <PersonIcon color='primary' />
                                        </InputAdornment>
                                    }
                                    labelWidth={68}
                                />
                            </FormControl>

                            <FormControl
                                variant='outlined'
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor='correo'>
                                    Correo electronico
                                </InputLabel>
                                <OutlinedInput
                                    id='correo'
                                    type='email'
                                    value={formData.correo}
                                    onChange={onChangeForm('correo')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <MailIcon color='primary' />
                                        </InputAdornment>
                                    }
                                    labelWidth={135}
                                />
                            </FormControl>

                            <FormControl
                                variant='outlined'
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor='celular'>
                                    Celular
                                </InputLabel>
                                <OutlinedInput
                                    id='celular'
                                    type='tel'
                                    value={formData.celular}
                                    onChange={onChangeForm('celular')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <PhoneIcon color='primary' />
                                        </InputAdornment>
                                    }
                                    labelWidth={51}
                                />
                            </FormControl>

                            <FormControl
                                variant='outlined'
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor='puesto'>Puesto</InputLabel>
                                <OutlinedInput
                                    id='puesto'
                                    type='text'
                                    value={formData.puesto}
                                    onChange={onChangeForm('puesto')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <WorkIcon color='primary' />
                                        </InputAdornment>
                                    }
                                    labelWidth={51}
                                />
                            </FormControl>
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.dialogActions}>
                        <div className='containerActions'>
                            <Button
                                onClick={submitEditAdmin}
                                color='primary'
                                variant='contained'
                            >
                                GUARDAR
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbars options={snackbar} />
        </div>
    );
}
