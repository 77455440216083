import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useGeneralStyles } from '../../../styles-matui';
import { themeButtons } from '../../../theme.styles';
import {
    Button,
    FormControlLabel,
    OutlinedInput,
    FormGroup,
} from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import {
    ROLES_USUARIOS,
    PERMISOS_ADMINISTRADOR,
    STATUS,
} from '../../../constants';
import { ADD_ADMIN } from '../administradores.graphql';
import { useMutation } from '@apollo/client';
import FormLabel from '@material-ui/core/FormLabel';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkIcon from '@material-ui/icons/Work';
import { ThemeProvider } from '@material-ui/styles';
import { Administrador } from '../administradores.interfaces';
import Spinner from '../../spinner/spinner.compt';
import { useSelector } from 'react-redux';
import Utilerias from '../../../utilClass';
import './nuevo-admin.sass';
import Snackbars from '../../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../../interfaces';
import { Reducers } from '../../../store';

export default function ModalNuevoAdministrador(props) {
    const classes = useGeneralStyles();
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as Administrador;
    const utilerias: Utilerias = new Utilerias();
    const initForm = {
        idAdministrador: '',
        fechaAlta: '',
        superior: '',
        nombres: '',
        apellidos: '',
        correo: '',
        puesto: '',
        tipo: '',
        permisos: [],
        nombreCompleto: '',
        password: '',
        celular: '',
        cursos: [],
    };

    const [formData, setFormData] = useState<Administrador>(initForm);
    const [openLogin, setOpenLogin] = useState(false);
    const [addAdmin] = useMutation(ADD_ADMIN);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    const submitAdmin = () => {
        setOpenSpinner(true);
        const adminData = {
            superior: user.idAdministrador,
            nombres: formData.nombres,
            apellidos: formData.apellidos,
            correo: formData.correo,
            puesto: formData.puesto,
            tipo: ROLES_USUARIOS.ADMN,
            permisos: formData.permisos,
            password: formData.password,
            celular: formData.celular,
        };

        addAdmin({ variables: { admin: adminData } })
            .then(() => {
                setOpenSpinner(false);
                setOpenLogin(false);
                setFormData(initForm);
                props.refreshUserAdmins('Administrador creado correctamente');
            })
            .catch(() => {
                setOpenSpinner(false);
                openSnackBar(`Error al crear administrador`, STATUS.error);
            });
    };

    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            formData.permisos.push(event.target.id);
        } else {
            setFormData({
                ...formData,
                permisos: formData.permisos.filter(
                    (per) => per !== event.target.id,
                ),
            });
        }
    };

    const onChangeForm =
        (prop: keyof Administrador) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (prop === 'showPassword') {
                setFormData({ ...formData, [prop]: !formData[prop] });
            } else {
                setFormData({ ...formData, [prop]: event.target.value });
            }
        };

    const onClickShowPassword = () => {
        setFormData({ ...formData, showPassword: !formData.showPassword });
    };

    const onClickDialogOpenClose = () => {
        setOpenLogin(!openLogin);
        setFormData(initForm);
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };

    const handleGeneratePassword = () => {
        setFormData({ ...formData, password: utilerias.generarPassword() });
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    return (
        <div>
            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />

            <Fab
                color='primary'
                aria-label='addAdmin'
                className={classes.fab}
                onClick={onClickDialogOpenClose}
            >
                <AddIcon />
            </Fab>

            <Dialog
                maxWidth='md'
                scroll='body'
                open={openLogin}
                className={classes.modal}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
            >
                <div>
                    <DialogTitle
                        id='form-dialog-title'
                        className={classes.titleForm}
                    >
                        NUEVO ADMINISTRADOR
                    </DialogTitle>
                    <DialogContent>
                        <div className='containerModalAdmin'>
                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='nombres'>
                                        Nombre(s)
                                    </InputLabel>
                                    <OutlinedInput
                                        id='nombres'
                                        type='text'
                                        value={formData.nombres}
                                        onChange={onChangeForm('nombres')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={83}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='apellidos'>
                                        Apellidos
                                    </InputLabel>
                                    <OutlinedInput
                                        id='apellidos'
                                        type='text'
                                        value={formData.apellidos}
                                        onChange={onChangeForm('apellidos')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={68}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='correo'>
                                        Correo electronico
                                    </InputLabel>
                                    <OutlinedInput
                                        id='correo'
                                        type='email'
                                        value={formData.correo}
                                        onChange={onChangeForm('correo')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <MailIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={135}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='celular'>
                                        Celular
                                    </InputLabel>
                                    <OutlinedInput
                                        id='celular'
                                        type='tel'
                                        value={formData.celular}
                                        onChange={onChangeForm('celular')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={51}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='puesto'>
                                        Puesto
                                    </InputLabel>
                                    <OutlinedInput
                                        id='puesto'
                                        type='text'
                                        value={formData.puesto}
                                        onChange={onChangeForm('puesto')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <WorkIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={51}
                                    />
                                </FormControl>
                            </div>

                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='password'>
                                        Contraseña
                                    </InputLabel>
                                    <OutlinedInput
                                        id='password'
                                        type={
                                            formData.showPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={formData.password}
                                        onChange={onChangeForm('password')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='password'
                                                    color='primary'
                                                    onClick={
                                                        onClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge='end'
                                                >
                                                    {formData.showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={85}
                                    />
                                </FormControl>
                                <div className='containerGenContra'>
                                    <ThemeProvider theme={themeButtons}>
                                        <Button
                                            onClick={handleGeneratePassword}
                                            color='secondary'
                                            variant='contained'
                                        >
                                            GENERAR
                                        </Button>
                                    </ThemeProvider>
                                </div>

                                <FormControl
                                    component='fieldset'
                                    className={classes.formControl}
                                >
                                    <FormLabel component='legend'>
                                        Permisos:
                                    </FormLabel>
                                    <FormGroup>
                                        {Object.keys(
                                            PERMISOS_ADMINISTRADOR,
                                        ).map((val) => (
                                            <FormControlLabel
                                                className={classes.checkbox}
                                                key={val}
                                                control={
                                                    <Checkbox
                                                        id={val}
                                                        color='secondary'
                                                        onChange={
                                                            onChangeCheckbox
                                                        }
                                                    />
                                                }
                                                label={
                                                    PERMISOS_ADMINISTRADOR[val]
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.dialogActions}>
                        <div className='containerActions'>
                            <Button
                                onClick={submitAdmin}
                                color='primary'
                                variant='contained'
                            >
                                REGISTRAR
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbars options={snackbar} />
        </div>
    );
}
