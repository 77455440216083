export const COURSE_SELECT = 'courseSelect';
export const MODULE_SELECT = 'moduleSelect';
export const SESION_SELECT = 'sesionSelect';

export const courseSelect = {
    type: COURSE_SELECT,
    course: null,
};

export const moduleSelect = {
    type: MODULE_SELECT,
    module: null,
};

export const sesionSelect = {
    type: SESION_SELECT,
    sesion: null,
};
