import HeaderCCAP from '../header-ccap/header-ccap.compt';
import { Routes, Route, Navigate } from 'react-router-dom';
import Usuarios from '../usuarios/usuarios.compt';
import Administradores from '../administradores/administradores.compt';
import MenuCCAP from '../menu-ccap/menu-ccap.compt';
import Reportes from '../reportes/reportes.compt';
import Cursos from '../cursos/cursos.compt';
import NuevoCurso from '../cursos/nuevo-curso/nuevo-curso.compt';
import { useCookies } from 'react-cookie';
import { getDataLogin, deleteDataLogin } from '../login/login.service';
import { NAME_COOKIE_ADMINS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLoginAction } from '../login/login.actions';
import './home-ccap.sass';
import { Reducers } from '../../store';
import { Administrador } from '../administradores/administradores.interfaces';
import NotFoundComponent from '../notfound/notfound.compt';

export default function HomeCCAP() {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['admin']);
    const data: any = getDataLogin(NAME_COOKIE_ADMINS);
    const user = useSelector<Reducers>(
        (store) => store.loginReducer,
    ) as Administrador;

    if (cookies[NAME_COOKIE_ADMINS] === undefined) {
        if (getDataLogin(NAME_COOKIE_ADMINS) !== null) {
            deleteDataLogin(NAME_COOKIE_ADMINS);
        }

        localStorage.clear();
        return <Navigate to='/' replace />;
    } else if (!user.nombres) {
        dispatch({ type: setUserLoginAction.type, data: JSON.parse(data) });
    }

    return (
        <>
            <HeaderCCAP />
            <div className='containerHome'>
                <Routes>
                    <Route path='/menu' element={<MenuCCAP />} />
                    <Route path='/usuarios' element={<Usuarios />} />
                    <Route path='/cursos/lista' element={<Cursos />} />
                    <Route path='/nuevo-curso' element={<NuevoCurso />} />
                    <Route path='/reportes' element={<Reportes />} />
                    <Route
                        path='/administradores'
                        element={<Administradores />}
                    />
                    <Route path='*' element={<NotFoundComponent />} />
                </Routes>
            </div>
        </>
    );
}
