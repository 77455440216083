import { bucketLocation } from './config';

export const VACIO = '';

export const REPLACE_IDCURSO = '[idCurso]';
export const REPLACE_IDMODULO = '[idModulo]';
export const REPLACE_IDSESION = '[idSesion]';
export const REPLACE_NOMBREFOTO = '[nombreFoto]';
export const REPLACE_NOMBREDOCUMENTO = '[nombreDocumento]';
export const REPLACE_NOMBREVIDEO = '[nombreVideo]';

export const PHOTO_CURSO = `${bucketLocation}/cursos%2F${REPLACE_IDCURSO}%2F${REPLACE_NOMBREFOTO}?alt=media`;
export const PHOTO_MODULO_URL = `${bucketLocation}/cursos%2F${REPLACE_IDCURSO}%2F${REPLACE_IDMODULO}%2F${REPLACE_NOMBREFOTO}?alt=media`;
export const PHOTO_SESION_URL = `${bucketLocation}/cursos%2F${REPLACE_IDCURSO}%2F${REPLACE_IDMODULO}%2F${REPLACE_IDSESION}%2F${REPLACE_NOMBREFOTO}?alt=media`;
export const DOCUMENTO_SESION_URL = `${bucketLocation}/cursos%2F${REPLACE_IDCURSO}%2F${REPLACE_IDMODULO}%2F${REPLACE_IDSESION}%2F${REPLACE_NOMBREDOCUMENTO}?alt=media`;
export const VIDEO_SESION_URL = `${bucketLocation}/cursos%2F${REPLACE_IDCURSO}%2F${REPLACE_IDMODULO}%2F${REPLACE_IDSESION}%2F${REPLACE_NOMBREVIDEO}?alt=media`;

export const ROLES_USUARIOS = {
    ADMN: 'ADMN',
    USRO: 'USRO',
    SADM: 'SADM',
};

export const STATUS = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
};

export const PERMISOS_ADMINISTRADOR = {
    PERADM01: 'Crear y editar usuarios',
    PERADM02: 'Crear y editar administradores',
    PERADM03: 'Crear cursos',
    PERADM04: 'Inhabilitar y eliminar usuarios',
    PERADM05: 'Inhabilitar y eliminar administradores',
    PERADM06: 'Restablecer contraseña usuario',
    PERADM07: 'Restablecer contraseña administrador',
};

export const PERMISOS_ADMINISTRADOR_CURSO = {
    PERADMCUR01: 'Editar contenido',
    PERADMCUR02: 'Eliminar curso',
    PERADMCUR03: 'Inhabilitar curso',
    PERADMCUR04: 'Administrar usuarios del curso',
    PERADMCUR05: 'Habilitar certificado de curso',
    PERADMCUR06: 'Administrar permisos administradores',
};

export const NAME_COOKIE_USERS = 'userccap';
export const NAME_COOKIE_ADMINS = 'adminccap';

export const MULTIMEDIA_TYPE_VIDEO = 'video';
export const MULTIMEDIA_TYPE_DOCUMENT = 'document';
export const MULTIMEDIA_TYPE_TEST = 'test';

export const PERFIL = 'perfil';
export const PASSWORD = 'password';
export const LOGOUT = 'logout';
export const CURSOS = 'cursos';
export const CURSOS_ADMIN = 'cursos-admin';
export const REPORTES = 'reportes';
export const ADMINISTRADORES = 'administradores';
export const USUARIOS = 'usuarios';
export const HOME = 'home';
export const PERFIL_ADMIN = 'perfil-admin';

export const DATA_USER_INIT = {
    nombres: VACIO,
    apellidos: VACIO,
    correo: VACIO,
    celular: VACIO,
    puesto: VACIO,
    dependencia: VACIO,
    carreraProfesion: VACIO,
    nivelAcademico: VACIO,
    idUsuario: VACIO,
    banPerfil: false,
    edad: VACIO,
    gradoEstudios: VACIO,
    escuela: VACIO,
    carrera: VACIO,
    titulo: VACIO,
    curso1: VACIO,
    curso2: VACIO,
    estadoCivil: VACIO,
    personasDependientes: VACIO,
    empleoActual: VACIO,
    empleo: VACIO,
    responsabilidades: VACIO,
    duracion: VACIO,
    salarioActual: VACIO,
    buscaEmpleo: VACIO,
    salarioDeseado: VACIO,
    ciudad: VACIO,
    cp: VACIO,
    colonia: VACIO,
    domicilio: VACIO,
    transporte: VACIO,
    foto: VACIO,
};

export const MESES = [
    'Ninguno',
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

export const COLUMNAS_TABLA = {
    styles: { fillColor: '#000', textColor: '#fff' },
};
