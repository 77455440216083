import {
    ADD_USUARIO,
    ADD_ALL_USERS,
    UPDATE_USER,
    DELETE_USER_ACTION,
    INH_USER_ACTION,
    HAB_USER_ACTION,
    DELETE_CURSO_USER_ACTION,
    INH_CURSO_USER_ACTION,
    HAB_CURSO_USER_ACTION,
    HAB_CERTIFICADO,
} from './usuarios-actions';
import { ActionReducer, InitialStateUsers } from '../../interfaces';
import { Usuario } from './usuarios.interfaces';

const initialState: InitialStateUsers = {
    inicial: true,
    usuarios: [],
};

const userReducer = (state = initialState, actions: ActionReducer) => {
    switch (actions.type) {
        case DELETE_CURSO_USER_ACTION:
            return {
                ...state,
                usuarios: state.usuarios.map((usr: any) =>
                    usr.idUsuario === actions.idUsuario
                        ? {
                              ...usr,
                              cursos: usr.cursos.filter(
                                  (cur) => cur.idCurso !== actions.idCurso,
                              ),
                          }
                        : usr,
                ),
            };

        case INH_CURSO_USER_ACTION:
            return {
                ...state,
                usuarios: state.usuarios.map((usr: any) =>
                    usr.idUsuario === actions.idUsuario
                        ? {
                              ...usr,
                              cursos: usr.cursos.map((cur) =>
                                  cur.idCurso === actions.idCurso
                                      ? {
                                            ...cur,
                                            banInactivo: true,
                                        }
                                      : cur,
                              ),
                          }
                        : usr,
                ),
            };

        case HAB_CURSO_USER_ACTION:
            return {
                ...state,
                usuarios: state.usuarios.map((usr: any) =>
                    usr.idUsuario === actions.idUsuario
                        ? {
                              ...usr,
                              cursos: usr.cursos.map((cur) =>
                                  cur.idCurso === actions.idCurso
                                      ? {
                                            ...cur,
                                            banInactivo: false,
                                        }
                                      : cur,
                              ),
                          }
                        : usr,
                ),
            };

        case HAB_CERTIFICADO:
            return {
                ...state,
                usuarios: state.usuarios.map((usr: any) =>
                    usr.idUsuario === actions.idUsuario
                        ? {
                              ...usr,
                              cursos: usr.cursos.map((cur) =>
                                  cur.idCurso === actions.idCurso
                                      ? {
                                            ...cur,
                                            banDiploma: true,
                                        }
                                      : cur,
                              ),
                          }
                        : usr,
                ),
            };

        case ADD_USUARIO:
            return {
                ...state,
                usuarios: [...state.usuarios, actions.payload],
            };

        case ADD_ALL_USERS:
            return actions.payload;

        case DELETE_USER_ACTION:
            return {
                ...state,
                usuarios: state.usuarios.filter(
                    (user: Usuario) => user.idUsuario !== actions.idUsuario,
                ),
            };

        case INH_USER_ACTION:
            return {
                ...state,
                usuarios: state.usuarios.map((user: Usuario) =>
                    user.idUsuario === actions.idUsuario
                        ? { ...user, banInactivo: true }
                        : user,
                ),
            };

        case HAB_USER_ACTION:
            return {
                ...state,
                usuarios: state.usuarios.map((user: Usuario) =>
                    user.idUsuario === actions.idUsuario
                        ? { ...user, banInactivo: false }
                        : user,
                ),
            };

        case UPDATE_USER:
            return {
                ...state,
                usuarios: state.usuarios.map((user: Usuario) =>
                    user.idUsuario === actions.payload.idUsuario
                        ? {
                              ...user,
                              nombres: actions.payload.nombres,
                              apellidos: actions.payload.apellidos,
                              correo: actions.payload.correo,
                              puesto: actions.payload.puesto,
                              celular: actions.payload.celular,
                              dependencia: actions.payload.dependencia,
                              nivelAcademico: actions.payload.nivelAcademico,
                              carreraProfesion:
                                  actions.payload.carreraProfesion,
                              nombreCompleto: `${actions.payload.nombres} ${actions.payload.apellidos}`,
                          }
                        : user,
                ),
            };

        default:
            return state;
    }
};

export default userReducer;
