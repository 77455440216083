import HeaderCCAP from '../header-ccap/header-ccap.compt';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { getDataLogin, deleteDataLogin } from '../login/login.service';
import { NAME_COOKIE_USERS } from '../../constants';
import UsersCourse from '../courses-users/courses-users.compt';
import { useDispatch } from 'react-redux';
import { setUserLoginAction } from '../login/login.actions';
import ModuleCourse from '../courses-users/module-course/module-course.compt';
import SesionsCourse from '../courses-users/sesion-course/sesion-course.compt';
import SesionDetail from '../courses-users/sesion-detail/sesion-detail.compt';
import { deleteDataCourse } from '../courses-users/courses-users.services';
import {
    COURSE_SELECT,
    MODULE_SELECT,
    SESION_SELECT,
} from '../courses-users/courses-actions';
import './home-users.sass';
import NotFoundComponent from '../notfound/notfound.compt';

export default function HomeUsersCCAP() {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['admin']);
    const data: any = getDataLogin(NAME_COOKIE_USERS);

    if (cookies[NAME_COOKIE_USERS] === undefined) {
        if (data !== null) {
            deleteDataLogin(NAME_COOKIE_USERS);
        }

        deleteDataCourse(COURSE_SELECT);
        deleteDataCourse(MODULE_SELECT);
        deleteDataCourse(SESION_SELECT);

        return <Navigate to='/' replace />;
    } else {
        dispatch({ type: setUserLoginAction.type, data: JSON.parse(data) });
    }

    return (
        <>
            <HeaderCCAP />
            <div className='containerHome'>
                <Routes>
                    <Route path='/cursos' element={<UsersCourse />} />
                    <Route path='/modulo' element={<ModuleCourse />} />
                    <Route path='/sesions' element={<SesionsCourse />} />
                    <Route path='/sesion' element={<SesionDetail />} />
                    <Route path='*' element={<NotFoundComponent />} />
                </Routes>
            </div>
        </>
    );
}
