import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useGeneralStyles } from '../../styles-matui';
import {
    Button,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    ThemeProvider,
    IconButton,
} from '@material-ui/core';
import { VACIO, STATUS } from '../../constants';
import { UPDATE_PASSWORD } from '../../graphql';
import { useMutation } from '@apollo/client';
import Spinner from '../spinner/spinner.compt';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { themeButtons } from '../../theme.styles';
import Utilerias from '../../utilClass';
import Snackbars from '../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../interfaces';
import './cambiar-password.sass';

export default function ModalCambiarPassword(props) {
    const utilerias: Utilerias = new Utilerias();
    const classes = useGeneralStyles();
    const initilPass = {
        password: VACIO,
        showPassword: false,
    };

    const [changePassword] = useMutation(UPDATE_PASSWORD);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [formPassword, setPassword] = useState(initilPass);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    const submitPermisosAdmin = () => {
        setOpenSpinner(true);

        changePassword({
            variables: {
                dataPassword: {
                    uid: props.options.uid,
                    password: formPassword.password,
                },
            },
        })
            .then(() => {
                openSnackBar(
                    `Se restablecio la contraseña correctamente`,
                    STATUS.success,
                );
                props.options.closeParent(true);
                setPassword(initilPass);
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(
                    `Error al restablecer la contraseña`,
                    STATUS.error,
                );
                setOpenSpinner(false);
            });
    };

    const onClickDialogOpenClose = () => {
        props.options.closeParent(true);
        setPassword(initilPass);
    };

    const onClickShowPassword = () => {
        setPassword({
            ...formPassword,
            showPassword: !formPassword.showPassword,
        });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };

    const onChangePassword =
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword({ ...formPassword, password: event.target.value });
        };

    const handleGeneratePassword = () => {
        setPassword({ ...formPassword, password: utilerias.generarPassword() });
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    return (
        <div className='containerCambiarPass'>
            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />

            <Dialog
                maxWidth='md'
                scroll='body'
                open={props.options.open}
                className={classes.modal}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
            >
                <div>
                    <DialogTitle
                        id='form-dialog-title'
                        className={classes.titleForm}
                    >
                        RESTABLECER CONTRASEÑA
                    </DialogTitle>
                    <DialogContent>
                        <div className='containerModal'>
                            <FormControl
                                variant='outlined'
                                className={classes.formControl}
                            >
                                <InputLabel htmlFor='password'>
                                    Contraseña
                                </InputLabel>
                                <OutlinedInput
                                    id='password'
                                    type={
                                        formPassword.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    value={formPassword.password}
                                    onChange={onChangePassword()}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='password'
                                                color='primary'
                                                onClick={onClickShowPassword}
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge='end'
                                            >
                                                {formPassword.showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={85}
                                />
                            </FormControl>
                            <div className='containerGenContraRecuperar'>
                                <ThemeProvider theme={themeButtons}>
                                    <Button
                                        onClick={handleGeneratePassword}
                                        color='secondary'
                                        variant='contained'
                                    >
                                        GENERAR
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.dialogActions}>
                        <div className='containerActions'>
                            <Button
                                onClick={submitPermisosAdmin}
                                color='primary'
                                variant='contained'
                            >
                                RESTABLECER
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbars options={snackbar} />
        </div>
    );
}
