import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useGeneralStyles } from '../../../styles-matui';
import { Button, FormControlLabel, FormGroup } from '@material-ui/core';
import { PERMISOS_ADMINISTRADOR_CURSO, STATUS } from '../../../constants';
import { ACTUALIZAR_PERMISOS_ADMIN_CURSO } from '../administradores.graphql';
import { useMutation } from '@apollo/client';
import FormLabel from '@material-ui/core/FormLabel';
import Spinner from '../../spinner/spinner.compt';
import Snackbars from '../../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../../interfaces';
import './permisos-admin-curso.sass';

export default function ModalEditarPermisosAdminCurso(props) {
    const classes = useGeneralStyles();
    const [updatePermisosAdminCursoMutation] = useMutation(
        ACTUALIZAR_PERMISOS_ADMIN_CURSO,
    );
    const [openSpinner, setOpenSpinner] = useState(false);
    const [initial, setInitial] = useState(true);
    const [permisos, setPermisos] = useState<string[]>([]);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    useEffect(() => {
        if (initial) {
            setPermisos(props.data.dataPermisos.permisos);
        }
    });

    const submitPermisosAdmin = () => {
        setOpenSpinner(true);

        const dataPermisos = {
            idAdministrador: props.data.dataPermisos.idAdministrador,
            idCurso: props.data.dataPermisos.idCurso,
            permisos,
        };

        updatePermisosAdminCursoMutation({
            variables: {
                data: {
                    idCurso: dataPermisos.idCurso,
                    idUsuario: dataPermisos.idAdministrador,
                },
                permisos: dataPermisos.permisos,
            },
        })
            .then(() => {
                setOpenSpinner(false);
                setInitial(true);
                props.data.closeParent(true);
            })
            .catch(() => {
                setOpenSpinner(false);
                openSnackBar(`Error al actualizar permisos`, STATUS.error);
            });
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitial(false);

        if (
            event.target.checked &&
            !permisos.some((permiso) => permiso === event.target.id)
        ) {
            setPermisos([...permisos, event.target.id]);
        } else if (
            !event.target.checked &&
            permisos.some((permiso) => permiso === event.target.id)
        ) {
            setPermisos(permisos.filter((per) => per !== event.target.id));
        }
    };

    const onClickDialogOpenClose = () => {
        props.data.closeParent(true);
        setInitial(true);
    };

    return (
        <div>
            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />

            <Dialog
                maxWidth='md'
                scroll='body'
                open={props.data.open}
                className={classes.modal}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
            >
                <div>
                    <DialogTitle
                        id='form-dialog-title'
                        className={classes.titleForm}
                    >
                        EDITAR PERMISOS DEL CURSO
                    </DialogTitle>
                    <DialogContent>
                        <div className='containerModal'>
                            <FormControl
                                component='fieldset'
                                className={classes.formControl}
                            >
                                <FormLabel component='legend'>
                                    Administrador:{' '}
                                    <b className='btnName'>
                                        {props.data.dataPermisos.nombreCompleto}
                                    </b>
                                </FormLabel>
                                <br />
                                <FormLabel component='legend'>
                                    Permisos:
                                </FormLabel>
                                <FormGroup>
                                    {Object.keys(
                                        PERMISOS_ADMINISTRADOR_CURSO,
                                    ).map((val) => (
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            key={val}
                                            control={
                                                <Checkbox
                                                    checked={permisos.some(
                                                        (permiso) =>
                                                            permiso === val,
                                                    )}
                                                    id={val}
                                                    color='secondary'
                                                    onChange={onChangeCheckbox}
                                                />
                                            }
                                            label={
                                                PERMISOS_ADMINISTRADOR_CURSO[
                                                    val
                                                ]
                                            }
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.dialogActions}>
                        <div className='containerActions'>
                            <Button
                                onClick={submitPermisosAdmin}
                                color='primary'
                                variant='contained'
                            >
                                GUARDAR
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbars options={snackbar} />
        </div>
    );
}
