import { COURSE_SELECT, MODULE_SELECT, SESION_SELECT } from './courses-actions';
import { getDataCourse } from './courses-users.services';

const curso: any = getDataCourse(COURSE_SELECT);
const modulo: any = getDataCourse(MODULE_SELECT);
const sesion: any = getDataCourse(SESION_SELECT);

const initialState = {
    course: curso !== null ? JSON.parse(curso) : curso,
    module: modulo !== null ? JSON.parse(modulo) : modulo,
    sesion: sesion !== null ? JSON.parse(sesion) : sesion,
};

const coursesUserReducer = (state = initialState, actions: any) => {
    switch (actions.type) {
        case COURSE_SELECT:
            return {
                ...state,
                course: actions.course,
            };

        case MODULE_SELECT:
            return {
                ...state,
                module: actions.module,
            };

        case SESION_SELECT:
            return {
                ...state,
                sesion: actions.sesion,
            };

        default:
            return state;
    }
};

export default coursesUserReducer;
