import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.up('xs')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
        },
        rootCard: {
            maxWidth: 320,
            width: 320,
            margin: '16px',
            height: '550px',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '100%',
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 545,
                width: 545,
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: 320,
                width: 320,
            },
        },
        cardContent: {
            height: 115,
        },
        cardContentSesion: {
            height: 180,
        },
        media: {
            height: 340,
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        cardActions2: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cardDownloadIcon: {
            color: green[500],
        },
        displayNone: {
            display: 'none',
        },
    }),
);
