import ModalLogin from '../login/login.compt';
import './home.sass';

function Home() {
    return (
        <div className='home'>
            <ModalLogin />
        </div>
    );
}

export default Home;
