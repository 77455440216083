import {
    ApolloClient,
    InMemoryCache,
    DefaultOptions,
    createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { NAME_COOKIE_ADMINS, NAME_COOKIE_USERS } from './constants';
import { getDataLogin } from './components/login/login.service';
import { apolloServerUrl } from './config';

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const httpLink = createHttpLink({
    uri: apolloServerUrl,
});

const authLink = setContext((_, { headers }) => {
    const token = getDataLogin(NAME_COOKIE_ADMINS)
        ? getDataLogin(NAME_COOKIE_ADMINS)
        : getDataLogin(NAME_COOKIE_USERS);

    if (token) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${JSON.parse(token).idUsuario}`,
            },
        };
    }

    return headers;
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions,
});
