import {
    createStyles,
    makeStyles,
    Theme,
    lighten,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: '56px',
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        actionButtons: {
            padding: '8px',
        },
        formControl: {
            marginBottom: '16px',
            maxWidth: 400,
            width: '100%',
        },
        menuItem: {
            padding: '12px',
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        containerFotoPerfil: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px',
            alignItems: 'center',
            flexDirection: 'column-reverse',
        },
        avatarPerfil: {
            width: '150px',
            height: '150px',
        },
        displayNone: {
            display: 'none',
        },
        titleNoAdmins: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
        },
    }),
);

export const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(
                          theme.palette.secondary.light,
                          0.85,
                      ),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 100%',
        },
        todosButtonGroup: {
            marginRight: '10px',
        },
        todosActionButtons: {
            padding: '8px',
        },
        todosActionButtonsOcult: {
            display: 'none',
        },
    }),
);
