import React from 'react';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import { Paper, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { useSnackBarStyles } from './snacknar.styles-matui';
import { STATUS } from '../../constants';
import { red, blue, amber, green } from '@material-ui/core/colors';

export default function Snackbars(props) {
    const classes = useSnackBarStyles();
    const colorDone = { color: green[500] };
    const colorError = { color: red[500] };
    const colorInfo = { color: blue[500] };
    const colorWarning = { color: amber[500] };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        props.options.closeParent();
    };

    return (
        <div className={classes.root}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                className={classes.snackbar}
                open={props.options.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Paper
                    className={clsx(
                        classes.paper,
                        classes[props.options.status],
                    )}
                    elevation={3}
                >
                    <DoneIcon
                        style={colorDone}
                        className={
                            props.options.status !== STATUS.success
                                ? classes.displayNone
                                : ''
                        }
                    />
                    <ErrorIcon
                        style={colorError}
                        className={
                            props.options.status !== STATUS.error
                                ? classes.displayNone
                                : ''
                        }
                    />
                    <WarningIcon
                        style={colorWarning}
                        className={
                            props.options.status !== STATUS.warning
                                ? classes.displayNone
                                : ''
                        }
                    />
                    <InfoIcon
                        style={colorInfo}
                        className={
                            props.options.status !== STATUS.info
                                ? classes.displayNone
                                : ''
                        }
                    />
                    <div className={classes.divTex}>
                        <Typography className={classes.text}>
                            {props.options.descripcion}
                        </Typography>
                    </div>
                    <IconButton
                        aria-label='delete'
                        onClick={handleClose}
                        style={{
                            color:
                                props.options.status === STATUS.success
                                    ? green[500]
                                    : props.options.status === STATUS.error
                                      ? red[500]
                                      : props.options.status === STATUS.warning
                                        ? amber[500]
                                        : props.options.status === STATUS.info
                                          ? blue[500]
                                          : '',
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </Paper>
            </Snackbar>
        </div>
    );
}
