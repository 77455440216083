import { InitialStateCursos } from '../../interfaces';
import { initAdministradoresCursos } from './init-data';
import {
    CHANGE_PERMISOS,
    CONFIG_SAVED,
    NEW_ADMINS_DATA,
    UPDATE_USERS_SELECT,
    ADD_ALL_COURSES,
    INIT_STATE_CURSOS,
    ADD_CURSO,
    EDIT_CANT_ADMINS,
    EDIT_CANT_USERS,
    DELETE_CURSO_ACTION,
    INH_CURSO_ACTION,
    HAB_CURSO_ACTION,
    CURSO_SELECT,
    CURSO_SELECT_INIT,
} from './cursos-actions';
import { VACIO } from '../../constants';

const initialState: InitialStateCursos = {
    inicial: true,
    cursos: [],
    idCursoSelect: VACIO,
    nuevo: {
        administradores: initAdministradoresCursos,
        asignacionUsuarios: [],
    },
};

const cursosReducer = (state = initialState, actions: any) => {
    switch (actions.type) {
        case CURSO_SELECT:
            return {
                ...state,
                idCursoSelect: actions.idCurso,
            };

        case CURSO_SELECT_INIT:
            return {
                ...state,
                idCursoSelect: VACIO,
            };

        case DELETE_CURSO_ACTION:
            return {
                ...state,
                cursos: state.cursos.filter(
                    (curso) => curso.idCurso !== actions.idCurso,
                ),
            };

        case HAB_CURSO_ACTION:
            return {
                ...state,
                cursos: state.cursos.map((curso) =>
                    curso.idCurso === actions.idCurso
                        ? {
                              ...curso,
                              banInactivo: false,
                          }
                        : curso,
                ),
            };

        case INH_CURSO_ACTION:
            return {
                ...state,
                cursos: state.cursos.map((curso) =>
                    curso.idCurso === actions.idCurso
                        ? {
                              ...curso,
                              banInactivo: true,
                          }
                        : curso,
                ),
            };

        case EDIT_CANT_ADMINS:
            return {
                ...state,
                cursos: state.cursos.map((curso) =>
                    curso.idCurso === actions.idCurso
                        ? {
                              ...curso,
                              cantAdministradores: actions.cantAdministradores,
                          }
                        : curso,
                ),
            };

        case EDIT_CANT_USERS:
            return {
                ...state,
                cursos: state.cursos.map((curso) =>
                    curso.idCurso === actions.idCurso
                        ? {
                              ...curso,
                              cantUsuarios: actions.cantUsuarios,
                          }
                        : curso,
                ),
            };

        case ADD_CURSO:
            return {
                ...state,
                cursos:
                    state.cursos.filter(
                        (curso) => curso.idCurso === actions.curso.idCurso,
                    ).length > 0
                        ? state.cursos.map((curso) =>
                              curso.idCurso === actions.curso.idCurso
                                  ? actions.curso
                                  : curso,
                          )
                        : [...state.cursos, actions.curso],
            };
        case INIT_STATE_CURSOS:
            return {
                ...state,
                nuevo: initialState.nuevo,
            };

        case ADD_ALL_COURSES:
            return {
                ...state,
                cursos: actions.payload.cursos,
                inicial: actions.payload.inicial,
            };

        case UPDATE_USERS_SELECT:
            return {
                ...state,
                nuevo: {
                    ...state.nuevo,
                    asignacionUsuarios: [...actions.usuarios],
                },
            };

        case CHANGE_PERMISOS:
            return {
                ...state,
                nuevo: {
                    ...state.nuevo,
                    administradores: {
                        administradores:
                            state.nuevo.administradores.administradores.map(
                                (adm) =>
                                    adm.idAdministrador ===
                                    actions.idAdministrador
                                        ? { ...adm, edit: actions.edit }
                                        : adm,
                            ),
                        adminSelect: {
                            ...state.nuevo.administradores.adminSelect,
                            permisos: actions.permisos,
                        },
                    },
                },
            };

        case NEW_ADMINS_DATA:
            return {
                ...state,
                nuevo: {
                    ...state.nuevo,
                    administradores: {
                        ...state.nuevo.administradores,
                        administradores: [...actions.data],
                    },
                },
            };

        case CONFIG_SAVED:
            return {
                ...state,
                nuevo: {
                    ...state.nuevo,
                    administradores: {
                        administradores:
                            state.nuevo.administradores.administradores.map(
                                (adm) => {
                                    return { ...adm, edit: false };
                                },
                            ),
                        adminSelect: {
                            nombreCompleto: actions.nombreAdmin,
                            idAdministrador: actions.idAdministrador,
                            permisos: actions.permisos,
                            edit: actions.edit,
                        },
                    },
                },
            };

        default:
            return state;
    }
};

export default cursosReducer;
