import { VACIO } from '../../constants';
import {
    ModulosParentCursos,
    ModulosCursos,
    UsuariosNewCursos,
    CursosControlAcordion,
    AdministradoresCursosData,
    GeneralDataCursos,
    SesionesCursos,
} from './cursos.interfaces';

export const initGeneralData: GeneralDataCursos = {
    disabled: false,
    expanded: true,
    complete: false,
    nombreCurso: VACIO,
    descripcionCorta: VACIO,
    descripcionLarga: VACIO,
    tipo: VACIO,
    nombreFoto: VACIO,
    valorFoto: VACIO,
    blobFoto: VACIO,
    idCurso: VACIO,
    cantAdministradores: 0,
    cantUsuarios: 0,
    fechaAlta: VACIO,
    duracion: 0,
};

export const initNewSesion: SesionesCursos = {
    idSesion: VACIO,
    titulo: VACIO,
    descripcionCorta: VACIO,
    descripcionLarga: VACIO,
    videos: [],
    nombreDocumento: VACIO,
    valorDocumento: VACIO,
    nombreExamen: VACIO,
    valorExamen: [],
    nombreFoto: VACIO,
    valorFoto: VACIO,
    save: false,
    saved: false,
    visible: false,
    blobFoto: VACIO,
};

export const initNewModulo: ModulosCursos = {
    idModulo: VACIO,
    nombreFoto: VACIO,
    valorFoto: VACIO,
    blobFoto: VACIO,
    nombreModulo: VACIO,
    saved: false,
    descripcionCorta: VACIO,
    sesiones: [initNewSesion],
};

export const initModulosContenidoData: ModulosParentCursos = {
    complete: false,
    disabled: true,
    expanded: false,
    modulos: [initNewModulo],
};

export const initCursosControlAcordion: CursosControlAcordion = {
    complete: false,
    disabled: true,
    expanded: false,
};

export const initUsuariosCursos: UsuariosNewCursos = {
    complete: false,
    disabled: true,
    expanded: false,
    usuarios: [],
};

export const initAdministradoresCursos: AdministradoresCursosData = {
    adminSelect: {
        idAdministrador: VACIO,
        nombreCompleto: VACIO,
        permisos: [],
        edit: false,
    },
    administradores: [],
};
