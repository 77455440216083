import React, { useState } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {
    HeadCellAdmin,
    Administrador,
    EnhancedTableProps,
    Order,
    PropsEnhancedTableToolbar,
    EditarPermisos,
} from '../administradores.interfaces';
import { useStyles, useToolbarStyles } from '../administradores.styles-matui';
import { ButtonGroup, Button, Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import {
    PERMISOS_ADMINISTRADOR_CURSO,
    STATUS,
    VACIO,
} from '../../../constants';
import Snackbars from '../../snackbar/snackbar.compt';
import ModalConfirmaciones from '../../modal-confirmaciones/modal-confirmaciones.compt';
import { DataControlModalParent } from '../../../interfaces';
import Spinner from '../../spinner/spinner.compt';
import { useMutation } from '@apollo/react-hooks';
import { ELIMINAR_ADMINISTRADOR_CURSO } from '../administradores.graphql';
import ModalEditarPermisosAdminCurso from '../permisos-admin-curso/permisos-admin-curso.compt';

const HeadCellAdmins: HeadCellAdmin[] = [
    {
        id: 'nombreCompleto',
        numeric: false,
        disablePadding: true,
        label: 'Nombre completo',
    },
    {
        id: 'permisos',
        numeric: false,
        disablePadding: false,
        label: 'Permisos',
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(
    array: Administrador[],
    comparator: (a: any, b: any) => number,
) {
    const stabilizedThis = array.map(
        (el, index) => [el, index] as [any, number],
    );
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Administrador) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>

                <TableCell align='left'>Foto</TableCell>

                {HeadCellAdmins.map((HeadCellAdmin) => (
                    <TableCell
                        key={HeadCellAdmin.id}
                        align='left'
                        sortDirection={
                            orderBy === HeadCellAdmin.id ? order : false
                        }
                    >
                        <TableSortLabel
                            active={orderBy === HeadCellAdmin.id}
                            direction={
                                orderBy === HeadCellAdmin.id ? order : 'asc'
                            }
                            onClick={createSortHandler(HeadCellAdmin.id)}
                        >
                            {HeadCellAdmin.label}
                            {orderBy === HeadCellAdmin.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell align='center'>Acciones</TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: PropsEnhancedTableToolbar) => {
    const classes = useToolbarStyles();
    const [deleteAdminCourse] = useMutation(ELIMINAR_ADMINISTRADOR_CURSO);

    const deleteUser = (
        idAdministrador: string,
        course: string,
    ): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            deleteAdminCourse({
                variables: {
                    data: {
                        idCurso: course,
                        idUsuario: idAdministrador,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteAny = () => {
        const usersAllowed = props.usersSelected.filter(
            (user) =>
                !props.usuarios.find((item) => item.idAdministrador === user)
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay administradores disponibles para eliminar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) =>
                deleteUser(user, props.course),
            );

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.refreshUserAdmins(
                        'Exito al eliminar los administradores',
                    );
                    props.setSelected([]);
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al eliminar los administradores`,
                        STATUS.error,
                    );
                });
        }
    };

    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
            >
                Lista de administradores por curso
            </Typography>

            <ButtonGroup
                className={classes.todosButtonGroup}
                color='primary'
                aria-label='outlined primary button group'
            >
                <Button
                    onClick={deleteAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <DeleteIcon />
                </Button>
            </ButtonGroup>
        </Toolbar>
    );
};

export default function AdministradorCurso(props) {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] =
        useState<keyof Administrador>('nombreCompleto');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [confirmaciones, setConfirmaciones] =
        useState<DataControlModalParent>({ open: false, descripcion: '' });
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [deleteAdminCourse] = useMutation(ELIMINAR_ADMINISTRADOR_CURSO);
    const editarPermisosInit: EditarPermisos = {
        idAdministrador: VACIO,
        nombreCompleto: VACIO,
        permisos: [],
        idCurso: VACIO,
    };
    const [editarPermisos, setEditarPermisos] =
        useState<DataControlModalParent>({
            open: false,
            dataPermisos: editarPermisosInit,
        });

    const adminsByCourse = props.administradores
        .filter((adm) => !adm.banInactivo)
        .filter(
            (adm) =>
                adm.cursos &&
                adm.cursos !== null &&
                adm.cursos.find((cur) => cur.idCurso === props.course),
        )
        .map((adm) => {
            return {
                ...adm,
                cursos: adm.cursos.find((cur) => cur.idCurso === props.course),
            };
        }) as Administrador[];

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Administrador,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = adminsByCourse.map((n) => n.idAdministrador);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (
        event: React.MouseEvent<unknown>,
        idAdministrador: string,
    ) => {
        const selectedIndex = selected.indexOf(idAdministrador);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idAdministrador);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const closeModalConfirmaciones = (
        value: boolean,
        accion: string,
        data: any,
    ) => {
        setConfirmaciones({ open: false, descripcion: '' });

        if (value) {
            setOpenSpinner(true);
        }

        if (value && accion === 'eliminar') {
            deleteAdminCourse({
                variables: {
                    data: {
                        idCurso: data.course,
                        idUsuario: data.uid,
                    },
                },
            })
                .then(() => {
                    setOpenSpinner(false);
                    props.refreshUserAdmins(
                        'Exito al eliminar al usuario del curso',
                    );
                })
                .catch(() => {
                    setOpenSpinner(false);
                    openSnackBar(
                        `Error al eliminar al usuario del curso`,
                        STATUS.error,
                    );
                });
        }
    };

    const handleModalConfirmacionesEliminar = (administrador: any) => {
        const adminSelect = adminsByCourse.find(
            (admin) => admin.idAdministrador === administrador.idAdministrador,
        ) as Administrador;
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de eliminar a ${adminSelect.nombreCompleto} del curso?`,
            closeParent: closeModalConfirmaciones,
            accion: 'eliminar',
            uid: administrador.idAdministrador,
            course: administrador.cursos.idCurso,
        });
    };

    const closeModalPermisos = (value: boolean) => {
        setEditarPermisos({
            open: false,
            closeParent: closeModalPermisos,
            dataPermisos: editarPermisosInit,
        });

        if (value) {
            props.refreshUserAdmins('Permisos actualizados correctamente');
        }
    };

    const handleModalPermisos = (adminSelect: any) => {
        setEditarPermisos({
            open: true,
            dataPermisos: {
                idCurso: adminSelect.cursos.idCurso,
                idAdministrador: adminSelect.idAdministrador,
                permisos:
                    adminSelect.cursos.permisos &&
                    adminSelect.cursos.permisos !== null
                        ? adminSelect.cursos.permisos
                        : [],
                nombreCompleto: adminSelect.nombreCompleto,
            },
            closeParent: closeModalPermisos,
        });
    };

    const isSelected = (idAdministrador: string) =>
        selected.indexOf(idAdministrador) !== -1;

    return (
        <div className={classes.root}>
            <div
                className={
                    adminsByCourse.length > 0
                        ? classes.displayNone
                        : classes.titleNoAdmins
                }
            >
                <Typography component='h4'>
                    <b>No hay administradores para gestionar aún</b>
                </Typography>
            </div>
            <Paper
                className={
                    adminsByCourse.length === 0
                        ? classes.displayNone
                        : classes.paper
                }
            >
                <EnhancedTableToolbar
                    course={props.course}
                    setSelected={setSelected}
                    usuarios={adminsByCourse}
                    usersSelected={selected}
                    openSnackBar={openSnackBar}
                    setOpenSpinner={setOpenSpinner}
                    refreshUserAdmins={props.refreshUserAdmins}
                />

                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size='medium'
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={adminsByCourse.length}
                        />

                        <TableBody>
                            {stableSort(
                                adminsByCourse,
                                getComparator(order, orderBy),
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(
                                        row.idAdministrador,
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.idAdministrador}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idAdministrador,
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align='center'>
                                                <Avatar
                                                    alt={row.nombreCompleto}
                                                    src={row.foto}
                                                />
                                            </TableCell>

                                            <TableCell
                                                component='th'
                                                id={labelId}
                                                scope='row'
                                                padding='none'
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idAdministrador,
                                                    )
                                                }
                                            >
                                                {row.nombreCompleto}
                                            </TableCell>

                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idAdministrador,
                                                    )
                                                }
                                                align='left'
                                            >
                                                {row.cursos.permisos &&
                                                row.cursos.permisos !== null &&
                                                row.cursos.permisos.length > 0
                                                    ? row.cursos.permisos
                                                          .map((per, index) =>
                                                              index === 0
                                                                  ? PERMISOS_ADMINISTRADOR_CURSO[
                                                                        per
                                                                    ]
                                                                  : ` ${PERMISOS_ADMINISTRADOR_CURSO[per]}`,
                                                          )
                                                          .toString()
                                                    : 'No tiene permisos configurados'}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <ButtonGroup
                                                    color='primary'
                                                    aria-label='outlined primary button group'
                                                >
                                                    <Tooltip
                                                        title='Eliminar administrador del curso'
                                                        onClick={() =>
                                                            handleModalConfirmacionesEliminar(
                                                                row,
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            className={
                                                                classes.actionButtons
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Tooltip>

                                                    <Tooltip title='Configurar permisos del curso'>
                                                        <Button
                                                            className={
                                                                classes.actionButtons
                                                            }
                                                            onClick={() =>
                                                                handleModalPermisos(
                                                                    row,
                                                                )
                                                            }
                                                        >
                                                            <SettingsIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage='Resultados por página'
                    count={adminsByCourse.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <Snackbars options={snackbar} />
            <ModalConfirmaciones options={confirmaciones} />
            <ModalEditarPermisosAdminCurso data={editarPermisos} />
        </div>
    );
}
