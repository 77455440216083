import React, { useEffect } from 'react';
import { useStyles } from '../cursos.styles-matui';
import { FormControl } from '@material-ui/core';
import { GET_USERS_CURSOS } from '../../usuarios/usuarios.graphql';
import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SUsuarioCurso } from '../../usuarios/usuarios.interfaces';

export default function AsignarUsuarios(props) {
    const classes = useStyles();
    const queryUsersCourses = useQuery(GET_USERS_CURSOS);

    useEffect(() => {
        if (queryUsersCourses.data) {
            const usersCurso: any[] = [];
            queryUsersCourses.data.getUsers.forEach((user: any) => {
                if (user.cursos !== null && user.cursos !== undefined) {
                    const curso = user.cursos.find(
                        (curso) => curso.idCurso === props.idCurso,
                    );

                    if (curso !== null && curso !== undefined) {
                        usersCurso.push({
                            idUsuario: user.idUsuario,
                            nombreCompleto: user.nombreCompleto,
                            checked: true,
                        });
                    } else {
                        usersCurso.push({
                            idUsuario: user.idUsuario,
                            nombreCompleto: user.nombreCompleto,
                            checked: false,
                        });
                    }
                } else {
                    usersCurso.push({
                        idUsuario: user.idUsuario,
                        nombreCompleto: user.nombreCompleto,
                        checked: false,
                    });
                }
            });

            props.setUsersCourse(usersCurso);
        }
    }, [queryUsersCourses.data]);

    if (queryUsersCourses.loading) {
        return (
            <div>
                <CircularProgress color='secondary' />
            </div>
        );
    } else if (queryUsersCourses.error) {
        return (
            <div>
                <Typography>
                    <b>Error al cargar usuarios</b>
                </Typography>
            </div>
        );
    }

    const handleChange = (
        idUsuario: string,
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        props.setUsersCourse((prev) =>
            prev.map((usr) =>
                usr.idUsuario === idUsuario
                    ? {
                          ...usr,
                          checked: event.target.checked,
                      }
                    : usr,
            ),
        );
    };

    return (
        <div className='AccordionDetailsParent'>
            <FormControl component='fieldset'>
                <FormGroup>
                    <div className='containerUsers'>
                        {props.usersCourse.map((user: SUsuarioCurso) => (
                            <FormControlLabel
                                className={classes.checkbox}
                                key={user.idUsuario}
                                control={
                                    <Checkbox
                                        checked={user.checked}
                                        onChange={(e) =>
                                            handleChange(user.idUsuario, e)
                                        }
                                        name={user.nombreCompleto}
                                    />
                                }
                                label={user.nombreCompleto}
                            />
                        ))}
                    </div>
                </FormGroup>
            </FormControl>
        </div>
    );
}
