import Typography from '@material-ui/core/Typography';
import { theme } from '../../theme.styles';
import { useStyles } from './header-ccap.styles-matui';
import { ThemeProvider, AppBar, Toolbar, Avatar } from '@material-ui/core';
import { getDataLogin } from '../login/login.service';
import { NAME_COOKIE_ADMINS, NAME_COOKIE_USERS } from '../../constants';
import MenuHeader from './menu-left/menu-left.compt';
import { useSelector } from 'react-redux';
import logo from '../../assets/img/mvac-logo.png';
import './header-ccap.sass';
import { Reducers } from '../../store';
import { User } from '../../interfaces';

export default function HeaderCCAP() {
    const classes = useStyles();
    const dataUser = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as User;
    const dataLogin: string | null =
        getDataLogin(NAME_COOKIE_ADMINS) !== null
            ? getDataLogin(NAME_COOKIE_ADMINS)
            : getDataLogin(NAME_COOKIE_USERS);
    const user: any = dataUser.foto
        ? dataUser
        : dataLogin === null
          ? null
          : JSON.parse(dataLogin);

    return (
        <ThemeProvider theme={theme}>
            <AppBar position='fixed' color='primary' className='appBar'>
                <Toolbar className={classes.toolbar}>
                    <MenuHeader />
                    <img
                        alt='img'
                        src={logo}
                        className='logo'
                        width={'150px'}
                    />
                    <Typography
                        variant='h6'
                        className={
                            user !== null ? classes.title : classes.displayNone
                        }
                    ></Typography>
                    <div
                        className={
                            user !== null
                                ? 'containerUserHeader'
                                : classes.displayNone
                        }
                    >
                        <Avatar
                            alt='Remy Sharp'
                            src={user.foto}
                            className={classes.imgperfil}
                        />
                        <div className='infoUserHeader'>
                            <Typography color='primary'>
                                {user.nombreCompleto}
                            </Typography>
                            <Typography color='primary'>
                                <b>{user.puesto.toUpperCase()}</b>
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}
