import { VACIO } from './constants';

export default class Utilerias {
    public generarPassword(): string {
        let password = VACIO;
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < 14; i++) {
            password += characters.charAt(
                Math.floor(Math.random() * charactersLength),
            );
        }

        return password;
    }
}
