import { VACIO } from '../../constants';

export const GET_ADMINISTRADORES = 'get_admins';
export const ADD_ADMINISTRADOR = 'add_admin';
export const ADD_ALL_ADMINS = 'add_all_admins';
export const UPDATE_ADMIN = 'update_admin';
export const DELETE_ADMIN_ACTION = 'delete_admin';
export const INH_ADMIN_ACTION = 'inh_admin';
export const HAB_ADMIN_ACTION = 'hab_admin';
export const EDITAR_PERMISOS_ACTION = 'edit_permisos_admin';
export const DELETE_ADMIN_COURSE_ACTION = 'delete_curso_admin';
export const UPDATE_PERMISOS_ADMIN_CURSO = 'update_permisos_admin_curso';

export const updatePermisosAdminCurso = {
    type: UPDATE_PERMISOS_ADMIN_CURSO,
    idCurso: VACIO,
    idAdministrador: VACIO,
    permisosAdminCurso: [],
};

export const delAdminCourse = {
    type: DELETE_ADMIN_COURSE_ACTION,
    idCurso: VACIO,
    idAdministrador: VACIO,
};

export const getAllAdmin = {
    type: GET_ADMINISTRADORES,
};

export const addAdministrador = {
    type: ADD_ADMINISTRADOR,
};

export const addAllAdmins = {
    type: ADD_ALL_ADMINS,
    payload: [],
};

export const updateAdmin = {
    type: UPDATE_ADMIN,
    payload: {},
};

export const delAdminAction = {
    type: DELETE_ADMIN_ACTION,
    idAdministrador: '',
};

export const inhAdminAction = {
    type: INH_ADMIN_ACTION,
    idAdministrador: '',
};

export const habAdminAction = {
    type: HAB_ADMIN_ACTION,
    idAdministrador: '',
};

export const editPermisosAdmin = {
    type: EDITAR_PERMISOS_ACTION,
    payload: {},
};
