import { makeStyles, Theme } from '@material-ui/core/styles';
import { red, blue, amber, green } from '@material-ui/core/colors';

export const useSnackBarStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    paper: {
        width: '100%',
        padding: '5px 0px 5px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    snackbar: {
        top: '76px',
        width: 'calc(100% - 16px)',
    },
    displayNone: {
        display: 'none',
    },
    divTex: {
        width: '100%',
        padding: '0px 16px',
    },
    error: {
        backgroundColor: red[100],
    },
    warning: {
        backgroundColor: amber[100],
    },
    info: {
        backgroundColor: blue[100],
    },
    success: {
        backgroundColor: green[100],
    },
    text: {},
}));
