import { CircularProgressData } from '../../../interfaces';
import { Typography, CircularProgress, Box } from '@material-ui/core';
import { useStyles } from '../courses-users.mat-ui';

export default function CircularProgressUser(props: CircularProgressData) {
    const classes = useStyles();

    return (
        <div>
            <Box position='relative' display='inline-flex'>
                <CircularProgress
                    className={classes.circularProgressDonut}
                    variant='determinate'
                    value={
                        props.data.porcentaje && props.data.porcentaje !== null
                            ? props.data.porcentaje
                            : 0
                    }
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position='absolute'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    <Typography
                        variant='caption'
                        component='div'
                        className={classes.textDoneDonut}
                    >
                        {`${props.data.porcentaje}%`}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
}
