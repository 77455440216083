import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DataControlModalParent, DataUser } from '../../interfaces';
import { useQuery } from '@apollo/react-hooks';
import { LinearProgress, Typography } from '@material-ui/core';
import CardNavInfo from '../card-nav/card-nav.compt';
import { GET_COURSE_FOR_USER } from './courses-users.grapqhl';
import { useStyles } from './courses-users.mat-ui';
import Snackbars from '../snackbar/snackbar.compt';
import { STATUS } from '../../constants';
import { Reducers } from '../../store';

export default function UsersCourse() {
    const classes = useStyles();
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as DataUser;
    const { loading, error, data } = useQuery(GET_COURSE_FOR_USER);

    if (loading) {
        return <LinearProgress color='secondary' />;
    }

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const setPercent = (cur) => {
        try {
            const curso = user.cursos.find(
                (itm) => itm.idCurso === cur.idCurso,
            );
            return {
                ...cur,
                porcentaje:
                    curso && curso.porcentaje && curso.porcentaje !== null
                        ? curso.porcentaje
                        : 0,
                banDiploma:
                    curso && curso.banDiploma ? curso.banDiploma : false,
                fechaAlta:
                    curso && curso.fechaAlta ? Number(curso.fechaAlta) : 0,
            };
        } catch (error) {
            console.error('setPercent error:', error);
        }
    };

    if (error) {
        openSnackBar(
            'Error al cargar los cursos, por favor intenta de nuevo',
            STATUS.error,
        );

        return (
            <div>
                <Typography
                    component='h4'
                    className={classes.messageNotCourses}
                >
                    <b>No tienes cursos asignados aún</b>
                </Typography>
                <Snackbars options={snackbar} />
            </div>
        );
    }

    if (!user.cursos || user.cursos === null) {
        return (
            <Typography component='h4' className={classes.messageNotCourses}>
                <b>No tienes cursos asignados aún</b>
            </Typography>
        );
    }

    return (
        <CardNavInfo
            data={data.getCursos
                .filter(
                    (cur) =>
                        user.cursos.find(
                            (itm) => itm.idCurso === cur.idCurso,
                        ) !== undefined,
                )
                .map((cur) => setPercent(cur))}
            route='/ccap/users/modulo'
        />
    );
}
