import { gql } from '@apollo/client';

export const SET_TEST_USER = gql`
    mutation setNewTestUser($newSetTest: NewSetTest!) {
        setNewTest(data: $newSetTest) {
            idUsuario
            nombres
            apellidos
            celular
            correo
            dependencia
            nivelAcademico
            carreraProfesion
            puesto
            idAdministrador
            fechaAlta
            foto
            curriculum
            tipo
            banInactivo
            nombreCompleto
            cursos {
                idCurso
                fechaAlta
                porcentaje
                banDiploma
                modulos {
                    idModulo
                    fechaAlta
                    fechaTermino
                    porcentaje
                    banDiploma
                    sesiones {
                        idSesion
                        fechaAlta
                        fechaTermino
                        porcentaje
                        banDescargaDocumento
                        videos {
                            idVideo
                            currentTime
                            porcentaje
                        }
                        examen {
                            intento
                            calificacion
                            preguntas {
                                pregunta
                                respuesta
                                correcta
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SET_CURRENT_DOCUMENT = gql`
    mutation setCurrentDocument(
        $newSetCurrentDocument: NewSetCurrentDocument!
    ) {
        setCurrentDocument(data: $newSetCurrentDocument) {
            idUsuario
            nombres
            apellidos
            celular
            correo
            dependencia
            nivelAcademico
            carreraProfesion
            puesto
            idAdministrador
            fechaAlta
            foto
            curriculum
            tipo
            banInactivo
            nombreCompleto
            cursos {
                idCurso
                fechaAlta
                porcentaje
                banDiploma
                modulos {
                    idModulo
                    fechaAlta
                    fechaTermino
                    porcentaje
                    banDiploma
                    sesiones {
                        idSesion
                        fechaAlta
                        fechaTermino
                        porcentaje
                        banDescargaDocumento
                        videos {
                            idVideo
                            currentTime
                            porcentaje
                        }
                        examen {
                            intento
                            calificacion
                            preguntas {
                                pregunta
                                respuesta
                                correcta
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SET_CURRENT_TIME = gql`
    mutation setNewDataVideo($newSetTimeVideo: NewSetTimeVideo!) {
        setCurrentTimeVideo(data: $newSetTimeVideo) {
            idUsuario
            nombres
            apellidos
            celular
            correo
            dependencia
            nivelAcademico
            carreraProfesion
            puesto
            idAdministrador
            fechaAlta
            foto
            curriculum
            tipo
            banInactivo
            nombreCompleto
            cursos {
                idCurso
                fechaAlta
                porcentaje
                banDiploma
                modulos {
                    idModulo
                    fechaAlta
                    fechaTermino
                    porcentaje
                    banDiploma
                    sesiones {
                        idSesion
                        fechaAlta
                        fechaTermino
                        porcentaje
                        banDescargaDocumento
                        videos {
                            idVideo
                            currentTime
                            porcentaje
                        }
                        examen {
                            intento
                            calificacion
                            preguntas {
                                pregunta
                                respuesta
                                correcta
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_COURSE_FOR_USER = gql`
    query {
        getCursos {
            idCurso
            fechaAlta
            idAdministrador
            foto
            nombre
            descripcionCorta
            descripcionLarga
            tipo
            banInactivo
            cantUsuarios
            duracion
            cantAdministradores
            modulos {
                idModulo
                fechaAlta
                foto
                nombre
                descripcionCorta
                sesiones {
                    idSesion
                    fechaAlta
                    foto
                    nombre
                    nombreDocumento
                    nombreExamen
                    nombreFoto
                    examen {
                        pregunta
                        respuestas {
                            respuesta
                        }
                    }
                    descripcionCorta
                    descripcionLarga
                    documento
                    videos {
                        idVideo
                        nombre
                        duracionVideo
                        valorVideo
                    }
                }
            }
        }
    }
`;

export const GET_COURSE_USER = gql`
    query obtenerCurso($idCurso: String!) {
        getCurso(idCurso: $idCurso) {
            idCurso
            fechaAlta
            idAdministrador
            foto
            nombre
            descripcionCorta
            descripcionLarga
            tipo
            banInactivo
            cantUsuarios
            duracion
            cantAdministradores
            modulos {
                idModulo
                fechaAlta
                foto
                nombre
                descripcionCorta
                sesiones {
                    idSesion
                    fechaAlta
                    foto
                    nombre
                    nombreDocumento
                    nombreExamen
                    nombreFoto
                    examen {
                        pregunta
                        respuestas {
                            respuesta
                        }
                    }
                    descripcionCorta
                    descripcionLarga
                    documento
                    videos {
                        idVideo
                        nombre
                        duracionVideo
                        valorVideo
                    }
                }
            }
        }
    }
`;
