import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { yellow, green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        avatar: {
            backgroundColor: yellow[600],
            margin: '8px 0px',
        },
        avatarSesion: {
            marginTop: '4px',
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
            flexDirection: 'column',
        },
        detailsModulos: {
            borderTop: 'solid 1px rgba(0, 0, 0, 0.17)',
        },
        iconAddMod: {
            marginRight: '8px',
        },
        btnAddMod: {
            marginLeft: '8px',
        },
        column: {
            paddingLeft: '8px',
            flexBasis: '33.33%',
            display: 'flex',
            alignItems: 'center',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        title: {
            flex: '1 1 100%',
            marginBottom: '8px',
        },
        largePhoto: {
            marginBottom: '4px',
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
        textArea: {
            maxWidth: '400px',
            width: '100%',
            marginBottom: '16px',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '100%',
            },
        },
        displayNone: {
            display: 'none!important',
        },
        display: {
            visibility: 'visible',
        },
        formControlLeft: {
            marginRight: '4px',
            [theme.breakpoints.up('xs')]: {
                marginRight: '0px',
            },
        },
        formControlRigth: {
            marginLeft: '4px',
            [theme.breakpoints.up('xs')]: {
                marginLeft: '0px',
            },
        },
        fabVideo: {
            marginRight: '8px',
            marginBottom: '8px',
            width: '100%',
        },
        typografySuccess: {
            marginLeft: '8px',
        },
        divSuccess: {
            color: green[500],
            display: 'flex',
            alignItems: 'center',
        },
        chipVideo: {
            margin: '4px',
        },
        checkbox: {
            width: 'auto',
        },
        btnAddConfigAdminPermisos: {
            marginTop: '16px',
        },
        actionButtons: {
            padding: '8px',
        },
        paper: {
            width: '100%',
            marginBottom: '56px',
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        titleNoCourses: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
        },
    }),
);
