import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './components/home/home.compt';
import HomeCCAP from './components/home-centro-capacitacion/home-ccap.compt';
import HomeUsersCCAP from './components/home-users/home-users.compt';
import NotFoundComponent from './components/notfound/notfound.compt';

function AppRputing() {
    return (
        <Router>
            <Routes>
                <Route path='/ccap/admin/*' element={<HomeCCAP />} />
                <Route path='/ccap/users/*' element={<HomeUsersCCAP />} />
                <Route path='/' element={<Home />} />
                <Route path='*' element={<NotFoundComponent />} />
            </Routes>
        </Router>
    );
}

export default AppRputing;
