import { gql } from '@apollo/client';

export const GET_ALL_USERS_REPORTS = gql`
    query {
        getUsers {
            idUsuario
            nombreCompleto
            nombres
            apellidos
            correo
            celular
            dependencia
            nivelAcademico
            carreraProfesion
            puesto
            idAdministrador
            banInactivo
            foto
            cursos {
                idCurso
                fechaAlta
                porcentaje
                banDiploma
                banInactivo
                modulos {
                    idModulo
                    fechaAlta
                    fechaTermino
                    porcentaje
                    banDiploma
                    sesiones {
                        idSesion
                        fechaAlta
                        fechaTermino
                        porcentaje
                        banDescargaDocumento
                        videos {
                            idVideo
                            currentTime
                            porcentaje
                        }
                        examen {
                            intento
                            calificacion
                            preguntas {
                                pregunta
                                respuesta
                                correcta
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ALL_USERS = gql`
    query {
        getUsers {
            idUsuario
            nombres
            apellidos
            celular
            correo
            dependencia
            nivelAcademico
            carreraProfesion
            puesto
            idAdministrador
            fechaAlta
            foto
            curriculum
            tipo
            banInactivo
            nombreCompleto
            edad
            gradoEstudios
            escuela
            carrera
            titulo
            curso1
            curso2
            estadoCivil
            personasDependientes
            empleoActual
            empleo
            responsabilidades
            duracion
            salarioActual
            buscaEmpleo
            salarioDeseado
            ciudad
            cp
            colonia
            domicilio
            transporte
            cursos {
                idCurso
                fechaAlta
                porcentaje
                banDiploma
                banInactivo
            }
        }
    }
`;

export const GET_USERS_CURSOS = gql`
    query {
        getUsers {
            idUsuario
            nombreCompleto
            cursos {
                idCurso
                fechaAlta
            }
        }
    }
`;

export const ADD_USER = gql`
    mutation newUser($user: newUsuario!) {
        createUser(user: $user)
    }
`;

export const EDIT_USER = gql`
    mutation editarUsuario($user: newUsuario!) {
        editarUser(user: $user)
    }
`;

export const ADD_CONFIG_USUARIO_CURSO = gql`
    mutation setUsuariosCursos($data: CursosUsuarioInput!) {
        asignarUsuarioCurso(data: $data)
    }
`;
