import { useState } from 'react';
import {
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    CardContent,
    CardActions,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { useStyles } from './card-nav.style-mat-ui';
import { useNavigate } from 'react-router-dom';
import {
    CardNav,
    CardNavData,
    DataControlModalParent,
    User,
} from '../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
    moduleSelect,
    sesionSelect,
    courseSelect,
} from '../courses-users/courses-actions';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgressUser from '../courses-users/circular-progress-user/circular-progress-user.compt';
import { setDataCourse } from '../courses-users/courses-users.services';
import { STATUS } from '../../constants';
import Snackbars from '../snackbar/snackbar.compt';
import jsPDF from 'jspdf';
import certificado from '../../../src/assets/certificados/general.jpg';
import certificadoMod from '../../../src/assets/certificados/modulo.jpg';
import '../../../src/assets/fuentes/code';
import '../../../src/assets/fuentes/charlinda';
import { Reducers } from '../../store';

export default function CardNavInfo(props: CardNav) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as User;
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    const goToNewComponent = (data: CardNavData) => {
        if (data.route) {
            navigate(data.route);
        } else if (data.idCurso) {
            dispatch({ type: courseSelect.type, course: data });

            if (
                data.modulos &&
                data.modulos !== null &&
                data.modulos.length > 0
            ) {
                setDataCourse(courseSelect.type, data);
                navigate(props.route);
            } else {
                openSnackBar(
                    `El curso no tiene modulos configurados aún.`,
                    STATUS.info,
                );
            }
        } else if (data.idModulo) {
            dispatch({ type: moduleSelect.type, module: data });

            if (
                data.sesiones &&
                data.sesiones !== null &&
                data.sesiones.length > 0
            ) {
                setDataCourse(moduleSelect.type, data);
                navigate(props.route);
            } else {
                openSnackBar(
                    `El modulo no tiene sesiones configuradas aún.`,
                    STATUS.info,
                );
            }
        } else if (data.idSesion) {
            dispatch({ type: sesionSelect.type, sesion: data });
            setDataCourse(sesionSelect.type, data);
            navigate(props.route);
        }
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const downloadDiploma = (value: any) => {
        const name = `${user.nombres} ${user.apellidos}`;
        const doc = new jsPDF('l', 'mm', 'a4');
        if (value.idCurso) {
            const words = name.split(' ').length;
            const mult =
                words >= 5
                    ? words + 0.4
                    : words === 4
                      ? words + 1.8
                      : words === 3
                        ? words + 1.4
                        : words + 4;
            doc.addImage(certificado, 'JPG', -1, 0, 299, 210);
            doc.setFont('charlinda', 'normal');
            doc.setFontSize(35);
            doc.text(name, 205 - 13 * mult, 108);
            doc.setFont('SourceCodePro-ExtraLight', 'normal');
            doc.setFontSize(20);
            doc.text(value.nombre, 70, 50);
            doc.text(new Date().toLocaleDateString(), 122, 188);
            doc.output('blob');
            doc.save(`${value.idCurso}.pdf`);
        } else if (value.idModulo) {
            doc.addImage(certificadoMod, 'JPG', -1, 0, 299, 210);
            const ind = 298 / 2 - (6 * name.length) / 2;
            const ind2 = 300 / 2 - (6.5 * value.nombre.length) / 2;
            doc.setTextColor('#fff');
            doc.setFont('charlinda', 'normal');
            doc.setFontSize(35);
            doc.text(name, ind, 108);
            doc.setFontSize(25);
            doc.setFont('SourceCodePro-ExtraLight', 'normal');
            doc.setTextColor('#000');
            doc.text(value.nombre, ind2, 135);
            doc.setTextColor('#fff');
            doc.text(new Date().toLocaleDateString(), 40, 172);
            doc.setFontSize(20);
            doc.output('blob');
            doc.save(`${value.idModulo}.pdf`);
        }
    };

    return (
        <Grid container spacing={3} className={classes.root}>
            {props.data.map((value: any, index) => (
                <Card key={index} className={classes.rootCard}>
                    <CardActionArea onClick={() => goToNewComponent(value)}>
                        <CardMedia
                            className={classes.media}
                            image={value.foto}
                            title={value.nombre}
                        />
                        <CardContent
                            className={
                                value.porcentaje === 0
                                    ? classes.cardContentSesion
                                    : classes.cardContent
                            }
                        >
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='h2'
                            >
                                {value.nombre}
                            </Typography>
                            <Typography
                                variant='body2'
                                color='textSecondary'
                                component='p'
                            >
                                {value.descripcionCorta}
                            </Typography>
                        </CardContent>
                    </CardActionArea>

                    <CardActions
                        className={
                            value.idSesion && value.idSesion !== undefined
                                ? classes.cardActions2
                                : value.banDiploma && value.banDiploma === true
                                  ? classes.cardActions
                                  : classes.cardActions2
                        }
                    >
                        <div
                            className={
                                value.idSesion && value.idSesion !== undefined
                                    ? classes.displayNone
                                    : value.banDiploma &&
                                        value.banDiploma === true
                                      ? ''
                                      : classes.displayNone
                            }
                        >
                            <Tooltip
                                title={
                                    value.idCurso
                                        ? 'Descargar cerificado'
                                        : 'Descargar diploma'
                                }
                            >
                                <IconButton
                                    className={classes.cardDownloadIcon}
                                    aria-label='dowload'
                                    onClick={() => downloadDiploma(value)}
                                >
                                    <ArrowDownwardIcon fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <CircularProgressUser
                            data={{
                                porcentaje: value.porcentaje,
                            }}
                        />
                    </CardActions>
                </Card>
            ))}

            <Snackbars options={snackbar} />
        </Grid>
    );
}
