import { CardNavData } from '../../interfaces';
import CardNavInfo from '../card-nav/card-nav.compt';
import './menu-ccap.sass';

export default function MenuCCAP() {
    const options = [
        {
            nombre: 'Usuarios',
            imagen: 'https://firebasestorage.googleapis.com/v0/b/mvac-dev.appspot.com/o/administradores%2Fusuarios.jpeg?alt=media&token=f7d178a1-88dc-4ebf-a346-e40f13310d89',
            route: '/ccap/admin/usuarios',
            descripcion: 'Esta es la descipción del modulo de usuarios.',
        },
        {
            nombre: 'Cursos',
            imagen: 'https://firebasestorage.googleapis.com/v0/b/mvac-dev.appspot.com/o/administradores%2Fcursos.jpeg?alt=media&token=054c0cf7-acb9-455d-92ba-954859659708',
            route: '/ccap/admin/cursos/lista',
            descripcion: 'Esta es la descipción del modulo de cursos.',
        },
        {
            nombre: 'Reportes',
            imagen: 'https://firebasestorage.googleapis.com/v0/b/mvac-dev.appspot.com/o/administradores%2Freportes.jpeg?alt=media&token=ac42d51b-01b8-4e9d-9b69-7da1b9554a47',
            route: '/ccap/admin/reportes',
            descripcion: 'Esta es la descipción del modulo de reportes.',
        },
        {
            nombre: 'Administradores',
            imagen: 'https://firebasestorage.googleapis.com/v0/b/mvac-dev.appspot.com/o/administradores%2Fadministradores.jpeg?alt=media&token=30590d14-3463-4351-a921-545ce6edd880',
            route: '/ccap/admin/administradores',
            descripcion: 'Esta es la descipción del modulo de administradores.',
        },
    ];

    return (
        <CardNavInfo
            data={options.map(
                (item) =>
                    new CardNavData(
                        item.imagen,
                        item.nombre,
                        item.descripcion,
                        0,
                        false,
                        item.route,
                    ),
            )}
            route='/ccap/admin/menu'
        />
    );
}
