import React, { useState } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {
    HeadCellAdmin,
    Administrador,
    EnhancedTableProps,
    Order,
    PropsEnhancedTableToolbar,
    EditAdministrador,
    EditarPermisos,
} from '../administradores.interfaces';
import { useStyles, useToolbarStyles } from '../administradores.styles-matui';
import { ButtonGroup, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import ModalNuevoAdministrador from '../nuevo-admin/nuevo-admin.compt';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import ModalConfirmaciones from '../../modal-confirmaciones/modal-confirmaciones.compt';
import { DataControlModalParent } from '../../../interfaces';
import ModalEditarAdministrador from '../editar-admin/editar-admin.compt';
import { VACIO, ROLES_USUARIOS, STATUS } from '../../../constants';
import { useSelector } from 'react-redux';
import {
    DELETE_USER,
    INHABILITAR_USER,
    HABILITAR_USER,
} from '../../../graphql';
import { useMutation } from '@apollo/client';
import Spinner from '../../spinner/spinner.compt';
import ModalEditarPermisos from '../editar-permisos/editar-permisos.compt';
import ModalCambiarPassword from '../../cambiar-password/cambiar-password.compt';
import { useGeneralStyles } from '../../../styles-matui';
import Snackbars from '../../snackbar/snackbar.compt';
import Avatar from '@material-ui/core/Avatar';
import { Reducers } from '../../../store';

const HeadCellAdmins: HeadCellAdmin[] = [
    {
        id: 'nombreCompleto',
        numeric: false,
        disablePadding: true,
        label: 'Nombre completo',
    },
    { id: 'correo', numeric: false, disablePadding: false, label: 'Correo' },
    {
        id: 'banInactivo',
        numeric: false,
        disablePadding: false,
        label: 'Estatus',
    },
    { id: 'cursos', numeric: false, disablePadding: false, label: 'Cursos' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<Administrador>(
    array: Administrador[],
    comparator: (a: any, b: any) => number,
) {
    const stabilizedThis = array.map(
        (el, index) => [el, index] as [any, number],
    );
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Administrador) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>

                <TableCell align='left'>Foto</TableCell>

                {HeadCellAdmins.map((HeadCellAdmin) => (
                    <TableCell
                        key={HeadCellAdmin.id}
                        align='left'
                        sortDirection={
                            orderBy === HeadCellAdmin.id ? order : false
                        }
                    >
                        <TableSortLabel
                            active={orderBy === HeadCellAdmin.id}
                            direction={
                                orderBy === HeadCellAdmin.id ? order : 'asc'
                            }
                            onClick={createSortHandler(HeadCellAdmin.id)}
                        >
                            {HeadCellAdmin.label}
                            {orderBy === HeadCellAdmin.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell align='center'>Acciones</TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: PropsEnhancedTableToolbar) => {
    const classes = useToolbarStyles();
    const [deleteAdmin] = useMutation(DELETE_USER);
    const [inhAdmin] = useMutation(INHABILITAR_USER);

    const inhabUser = (idAdministrador: string) => {
        return new Promise((resolve, reject) => {
            inhAdmin({
                variables: {
                    user: {
                        uid: idAdministrador,
                        tipo: ROLES_USUARIOS.ADMN,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteUser = (idAdministrador: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            deleteAdmin({
                variables: {
                    user: {
                        uid: idAdministrador,
                        tipo: ROLES_USUARIOS.ADMN,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteAny = () => {
        const usersAllowed = props.usersSelected.filter(
            (user) =>
                !props.usuarios.find((item) => item.idAdministrador === user)
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay administradores disponibles para eliminar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) => deleteUser(user));

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.setSelected([]);
                    props.refreshUserAdmins(
                        'Exito al eliminar los administradores',
                    );
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al eliminar los administradores`,
                        STATUS.error,
                    );
                });
        }
    };

    const inhabAny = () => {
        const usersAllowed = props.usersSelected.filter(
            (user) =>
                !props.usuarios.find((item) => item.idAdministrador === user)
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay administradores disponibles para inhabilitar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) => inhabUser(user));

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.setSelected([]);
                    props.refreshUserAdmins(
                        'Exito al inhabilitar los administradores',
                    );
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        'Error al inhabilitar los administradores',
                        STATUS.error,
                    );
                });
        }
    };

    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
            >
                Lista de administradores
            </Typography>

            <ButtonGroup
                className={classes.todosButtonGroup}
                color='primary'
                aria-label='outlined primary button group'
            >
                <Button
                    onClick={inhabAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <BlockIcon />
                </Button>
                <Button
                    onClick={deleteAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <DeleteIcon />
                </Button>
            </ButtonGroup>
        </Toolbar>
    );
};

export default function AdministradorGeneral(props) {
    const classes = useStyles();
    const generalClasses = useGeneralStyles();
    const user = useSelector<Reducers>(
        (reducers: Reducers) => reducers.loginReducer,
    ) as Administrador;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] =
        useState<keyof Administrador>('nombreCompleto');
    const [selected, setSelected] = useState<string[]>([]);
    const [deleteAdmin] = useMutation(DELETE_USER);
    const [inhAdmin] = useMutation(INHABILITAR_USER);
    const [habAdmin] = useMutation(HABILITAR_USER);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [chgPass, setChgPass] = useState<DataControlModalParent>({
        open: false,
    });
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [confirmaciones, setConfirmaciones] =
        useState<DataControlModalParent>({ open: false, descripcion: '' });
    const dataEditInit: EditAdministrador = {
        idAdministrador: VACIO,
        nombres: VACIO,
        apellidos: VACIO,
        correo: VACIO,
        celular: VACIO,
        puesto: VACIO,
        banPerfil: false,
    };
    const [editarData, setEditarData] = useState<DataControlModalParent>({
        open: false,
        dataEditAdmin: dataEditInit,
    });

    const editarPermisosInit: EditarPermisos = {
        idAdministrador: '',
        nombreCompleto: '',
        permisos: [],
    };
    const [editarPermisos, setEditarPermisos] =
        useState<DataControlModalParent>({
            open: false,
            dataPermisos: editarPermisosInit,
        });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const administradores: any[] = props.administradores;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Administrador,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = administradores.map((n) => n.idAdministrador);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (
        event: React.MouseEvent<unknown>,
        idAdministrador: string,
    ) => {
        const selectedIndex = selected.indexOf(idAdministrador);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idAdministrador);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const closeModalConfirmaciones = (
        value: boolean,
        accion: string,
        idAdministrador: string,
    ) => {
        setConfirmaciones({ open: false, descripcion: '' });
        const adminSelect: Administrador = administradores.find(
            (admin) => admin.idAdministrador === idAdministrador,
        );

        if (value) {
            setOpenSpinner(true);
        }

        if (value && accion === 'inhabilitar') {
            inhAdmin({
                variables: {
                    user: {
                        uid: idAdministrador,
                        tipo: ROLES_USUARIOS.ADMN,
                    },
                },
            })
                .then(() => {
                    props.refreshUserAdmins(
                        `Exito al inhabilitar al usuario ${adminSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al inhabilitar al usuario ${adminSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'habilitar') {
            habAdmin({
                variables: {
                    user: {
                        uid: idAdministrador,
                        tipo: ROLES_USUARIOS.ADMN,
                    },
                },
            })
                .then(() => {
                    props.refreshUserAdmins(
                        `Exito al habilitar al usuario ${adminSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al habilitar al usuario ${adminSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'eliminar') {
            deleteAdmin({
                variables: {
                    user: {
                        uid: idAdministrador,
                        tipo: ROLES_USUARIOS.ADMN,
                    },
                },
            })
                .then(() => {
                    props.refreshUserAdmins(
                        `Exito al eliminar al usuario ${adminSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al eliminar al usuario ${adminSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        }
    };

    const closeModalEdicion = (value?: any) => {
        setEditarData({
            open: false,
            closeParent: closeModalEdicion,
            dataEditAdmin: dataEditInit,
        });
    };

    const handleModalConfirmacionesInhabilitar = (idAdministrador: string) => {
        const adminSelect: Administrador = administradores.find(
            (admin) => admin.idAdministrador === idAdministrador,
        );
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de ${
                adminSelect.banInactivo ? 'habilitar' : 'inhabilitar'
            } a ${adminSelect.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion: adminSelect.banInactivo ? 'habilitar' : 'inhabilitar',
            uid: idAdministrador,
        });
    };

    const handleModalConfirmacionesEliminar = (idAdministrador: string) => {
        const adminSelect: Administrador = administradores.find(
            (admin) => admin.idAdministrador === idAdministrador,
        );
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de eliminar a ${adminSelect.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion: 'eliminar',
            uid: idAdministrador,
        });
    };

    const handleModalEditar = (idAdministrador: string) => {
        const adminSelect: Administrador = administradores.find(
            (admin) => admin.idAdministrador === idAdministrador,
        );
        setEditarData({
            open: true,
            closeParent: closeModalEdicion,
            dataEditAdmin: {
                idAdministrador: adminSelect.idAdministrador,
                nombres: adminSelect.nombres,
                apellidos: adminSelect.apellidos,
                correo: adminSelect.correo,
                celular: adminSelect.celular,
                puesto: adminSelect.puesto,
                banPerfil: false,
                foto: adminSelect.foto,
            },
        });
    };

    const closeModalRecuperarPassword = (value: boolean) => {
        setChgPass({ open: false, closeParent: closeModalRecuperarPassword });
    };

    const handleModalRecuperarPassword = (idAdministrador: string) => {
        const adminSelect: Administrador = administradores.find(
            (admin) => admin.idAdministrador === idAdministrador,
        );
        setChgPass({
            open: true,
            closeParent: closeModalRecuperarPassword,
            uid: adminSelect.idAdministrador,
        });
    };

    const closeModalPermisos = (value: boolean) => {
        setEditarPermisos({
            open: false,
            closeParent: closeModalPermisos,
            dataPermisos: editarPermisosInit,
        });
    };

    const handleModalPermisos = (idAdministrador: string) => {
        const adminSelect: Administrador = administradores.find(
            (admin) => admin.idAdministrador === idAdministrador,
        );
        setEditarPermisos({
            open: true,
            dataPermisos: {
                idAdministrador: adminSelect.idAdministrador,
                permisos: adminSelect.permisos,
                nombreCompleto: adminSelect.nombreCompleto,
            },
            closeParent: closeModalPermisos,
        });
    };

    const isSelected = (idAdministrador: string) =>
        selected.indexOf(idAdministrador) !== -1;

    return (
        <div className={classes.root}>
            <div
                className={
                    administradores.length > 0
                        ? classes.displayNone
                        : classes.titleNoAdmins
                }
            >
                <Typography component='h4'>
                    <b>No tienes administradores para gestionar aún</b>
                </Typography>
            </div>
            <Paper
                className={
                    administradores.length === 0
                        ? classes.displayNone
                        : classes.paper
                }
            >
                <EnhancedTableToolbar
                    course={VACIO}
                    setSelected={setSelected}
                    usuarios={administradores}
                    usersSelected={selected}
                    openSnackBar={openSnackBar}
                    setOpenSpinner={setOpenSpinner}
                    refreshUserAdmins={props.refreshUserAdmins}
                />

                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size='medium'
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={administradores.length}
                        />

                        <TableBody>
                            {stableSort(
                                administradores,
                                getComparator(order, orderBy),
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(
                                        row.idAdministrador,
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            className={
                                                row.banInactivo
                                                    ? generalClasses.tableRowDisable
                                                    : ''
                                            }
                                            hover
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.idAdministrador}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idAdministrador,
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align='center'>
                                                <Avatar
                                                    alt={row.nombreCompleto}
                                                    src={row.foto}
                                                />
                                            </TableCell>

                                            <TableCell
                                                component='th'
                                                id={labelId}
                                                scope='row'
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idAdministrador,
                                                    )
                                                }
                                            >
                                                {row.nombreCompleto}
                                            </TableCell>

                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idAdministrador,
                                                    )
                                                }
                                                align='left'
                                            >
                                                {row.correo}
                                            </TableCell>
                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idAdministrador,
                                                    )
                                                }
                                                align='left'
                                            >
                                                <b>
                                                    {row.banInactivo
                                                        ? 'Inactivo'
                                                        : 'Activo'}
                                                </b>
                                            </TableCell>
                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idAdministrador,
                                                    )
                                                }
                                                align='left'
                                            >
                                                <Tooltip title='Ver información'>
                                                    <label>
                                                        {row.cursos &&
                                                        row.cursos !== null
                                                            ? row.cursos.length
                                                            : 0}
                                                    </label>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <ButtonGroup
                                                    color='primary'
                                                    aria-label='outlined primary button group'
                                                >
                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM02',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnEditar'
                                                        onClick={() =>
                                                            handleModalEditar(
                                                                row.idAdministrador,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Ver información'>
                                                            <VisibilityIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM05',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnInhabilitar'
                                                        onClick={() =>
                                                            handleModalConfirmacionesInhabilitar(
                                                                row.idAdministrador,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip
                                                            title={
                                                                row.banInactivo
                                                                    ? 'Habilitar'
                                                                    : 'Inhabilitar'
                                                            }
                                                        >
                                                            <BlockIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM05',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnEliminar'
                                                        onClick={() =>
                                                            handleModalConfirmacionesEliminar(
                                                                row.idAdministrador,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Eliminar'>
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM02',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnPermisos'
                                                        onClick={() =>
                                                            handleModalPermisos(
                                                                row.idAdministrador,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Editar permisos'>
                                                            <EditIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM07',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnPassword'
                                                        onClick={() =>
                                                            handleModalRecuperarPassword(
                                                                row.idAdministrador,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Recuperar contraseña'>
                                                            <SettingsIcon />
                                                        </Tooltip>
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage='Resultados por página'
                    count={administradores.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <div
                className={
                    user.permisos &&
                    user.permisos !== null &&
                    user.permisos.some((per) => per === 'PERADM02')
                        ? ''
                        : classes.displayNone
                }
            >
                <ModalNuevoAdministrador
                    refreshUserAdmins={props.refreshUserAdmins}
                />
            </div>
            <ModalEditarAdministrador
                open={editarData.open}
                closeParent={editarData.closeParent}
                dataAdmin={editarData.dataEditAdmin}
                refreshUserAdmins={props.refreshUserAdmins}
            />
            <ModalConfirmaciones options={confirmaciones} />
            <ModalEditarPermisos
                data={editarPermisos}
                refreshUserAdmins={props.refreshUserAdmins}
            />
            <ModalCambiarPassword options={chgPass} />
            <Snackbars options={snackbar} />
        </div>
    );
}
