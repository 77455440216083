import { Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from '../courses-users.mat-ui';
import { DataProgressUser } from '../../../interfaces';

export default function ProgressDetail(props: DataProgressUser) {
    const classes = useStyles();

    return (
        <Card className={classes.cardProgress}>
            <CardContent>
                <Typography gutterBottom variant='subtitle1' component='div'>
                    <b>Avance:</b>
                </Typography>
                <div>
                    <div
                        id='progressUser1'
                        className={classes.progressUser}
                    ></div>
                    <div
                        id='progressUser2'
                        style={{ width: `${props.data.progressSystem}%` }}
                        className={classes.progressUser2}
                    ></div>
                    <div
                        id='progressUser3'
                        style={{ width: `${props.data.progressUser}%` }}
                        className={classes.progressUser3}
                    >
                        <Typography
                            gutterBottom
                            variant='subtitle2'
                            component='div'
                        >
                            <b>{`${props.data.progressUser}%`}</b>
                        </Typography>
                    </div>
                </div>

                <div
                    className={
                        props.data.calificacion === 0 ? classes.displayNone : ''
                    }
                >
                    <Typography
                        gutterBottom
                        variant='subtitle1'
                        component='div'
                    >
                        <b>Calificación:</b>
                    </Typography>
                    <div>
                        <div
                            id='progressUser1'
                            className={classes.progressUser}
                        ></div>
                        <div
                            id='progressUser2'
                            style={{ width: '100%' }}
                            className={
                                props.data.calificacion >= 70
                                    ? classes.progressTestBar1
                                    : classes.progressTestBarError1
                            }
                        ></div>
                        <div
                            id='progressUser3'
                            style={{ width: `${props.data.calificacion}%` }}
                            className={
                                props.data.calificacion >= 70
                                    ? classes.progressTestBar2
                                    : classes.progressTestBarError2
                            }
                        >
                            <Typography
                                gutterBottom
                                variant='subtitle2'
                                component='div'
                            >
                                <b>{props.data.calificacion}</b>
                            </Typography>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
