import { VACIO } from '../../constants';

export const CHANGE_PERMISOS = 'handleChanguepermisos';
export const CONFIG_SAVED = 'handleConfigSaved';
export const NEW_ADMINS_DATA = 'newAdminsData';
export const UPDATE_USERS_SELECT = 'updateUserSelect';
export const ADD_ALL_COURSES = 'addAllCourses';
export const DELETE_CURSO_ACTION = 'delCurso';
export const INH_CURSO_ACTION = 'inhaCurso';
export const HAB_CURSO_ACTION = 'habCurso';
export const INIT_STATE_CURSOS = 'initStateCursos';
export const ADD_CURSO = 'addNewCurso';
export const EDIT_CANT_ADMINS = 'editCantAdmins';
export const EDIT_CANT_USERS = 'editCantUsers';
export const CURSO_SELECT = 'cursoSelectAction';
export const CURSO_SELECT_INIT = 'cursoSelectInitAction';

export const cursoSelectAction = {
    type: CURSO_SELECT,
    idCurso: VACIO,
};

export const cursoSelectInitAction = {
    type: CURSO_SELECT_INIT,
};

export const editCantAdmins = {
    type: EDIT_CANT_ADMINS,
    idCurso: VACIO,
    cantAdministradores: 0,
};

export const editCantUsers = {
    type: EDIT_CANT_USERS,
    idCurso: VACIO,
    cantUsuarios: 0,
};

export const addNewCurso = {
    type: ADD_CURSO,
    curso: null,
};

export const initStateCursos = {
    type: INIT_STATE_CURSOS,
};

export const delCurso = {
    type: DELETE_CURSO_ACTION,
    idCurso: VACIO,
};

export const inhaCurso = {
    type: INH_CURSO_ACTION,
    idCurso: VACIO,
};

export const habCurso = {
    type: HAB_CURSO_ACTION,
    idCurso: VACIO,
};

export const addAllCourses = {
    type: ADD_ALL_COURSES,
};

export const updateUserSelectA = {
    type: UPDATE_USERS_SELECT,
    usuarios: [],
};

export const handleChangeAdministradorA = {
    type: CONFIG_SAVED,
    nombreAdmin: VACIO,
    idAdministrador: VACIO,
    permisos: [],
    edit: false,
};

export const handleChanguepermisosA = {
    type: CHANGE_PERMISOS,
    permisos: [],
    edit: false,
    idAdministrador: VACIO,
};

export const handleAddPermisosAdministradorA = {
    type: NEW_ADMINS_DATA,
    data: [],
};

export const handleDeleteConfiguracionAdminPermisosA = {
    type: NEW_ADMINS_DATA,
    data: [],
};

export const handleConfigSavedA = {
    type: CONFIG_SAVED,
    nombreAdmin: VACIO,
    idAdministrador: VACIO,
    permisos: [],
    edit: false,
};
