import { useGeneralStyles } from '../../styles-matui';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function LinearProgressComp(props) {
    const classes = useGeneralStyles();

    return (
        <div>
            <LinearProgress
                color='secondary'
                variant='determinate'
                value={props.progress}
                className={classes.linearProgress}
            />
        </div>
    );
}
