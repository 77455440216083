import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, grey, yellow, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        rootCard: {
            maxWidth: 345,
            width: 345,
            margin: '16px',
            height: '550px',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '100%',
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 345,
                width: 345,
            },
        },
        cardContent: {
            height: 210,
        },
        media: {
            height: 340,
        },
        descriptionModule: {
            padding: '0 8px',
            [theme.breakpoints.up('xs')]: {
                textAlign: 'left',
            },
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center',
            },
        },
        contentTitle: {
            marginTop: '16px',
            [theme.breakpoints.up('sm')]: {
                marginTop: '16px',
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '60px',
            },
        },
        multimediaCard: {
            display: 'flex',
            flexDirection: 'column',
            width: '200px',
            height: '120px',
            margin: '8px',
            [theme.breakpoints.up('xs')]: {
                width: 'calc(50% - 16px)',
            },
            [theme.breakpoints.up('sm')]: {
                width: 'calc(33% - 16px)',
            },
            [theme.breakpoints.up('md')]: {
                width: '200px',
            },
        },
        containerMultimediaCards: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        iconMultimediaCard: {
            fontSize: '70px',
            color: grey[800],
        },
        circularProgressContainer: {
            position: 'absolute',
            bottom: 2,
            right: 4,
        },
        circularProgressText: {
            position: 'absolute',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            color: green[500],
        },
        circularProgressDonut: {
            color: green[500],
        },
        doneProgressDonut: {
            color: green[500],
            position: 'absolute',
            bottom: 4,
            right: 4,
            fontSize: '30px',
        },
        titleMultimediaCard: {
            width: 'calc(100% - 16px)',
            display: 'flex',
            height: '20px',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px',
        },
        containerIconMultimediaCard: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        displayNone: {
            display: 'none',
        },
        videoContainer: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up('xs')]: {
                flexDirection: 'column-reverse',
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'column-reverse',
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        cardProgress: {
            width: '100%',
            height: 'max-content',
        },
        containerVideo: {
            width: '42%',
            marginLeft: '32px',
            [theme.breakpoints.up('xs')]: {
                marginLeft: 0,
                width: '100%',
                marginBottom: '32px',
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: 0,
                width: '100%',
                marginBottom: '32px',
            },
            [theme.breakpoints.up('md')]: {
                width: '42%',
                marginLeft: '32px',
            },
        },
        iframeDoc: {
            width: '100%',
            margin: '0px',
            padding: '0px',
        },
        iframe: {
            width: '99.5%',
            height: '700px',
        },
        card: {
            padding: '0px',
        },
        progressUser: {
            backgroundColor: grey[100],
            height: '20px',
            width: '100%',
        },
        progressUser2: {
            backgroundColor: yellow[100],
            height: '20px',
            width: '80%',
            zIndex: 100,
            marginTop: '-20px',
        },
        progressUser3: {
            backgroundColor: yellow[600],
            height: '20px',
            width: '40%',
            zIndex: 200,
            marginTop: '-20px',
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
        },
        progressTestBar1: {
            backgroundColor: green[100],
            height: '20px',
            width: '80%',
            zIndex: 100,
            marginTop: '-20px',
        },
        progressTestBar2: {
            backgroundColor: green[600],
            height: '20px',
            width: '40%',
            zIndex: 200,
            marginTop: '-20px',
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
        },
        progressTestBarError1: {
            backgroundColor: red[100],
            height: '20px',
            width: '80%',
            zIndex: 100,
            marginTop: '-20px',
        },
        progressTestBarError2: {
            backgroundColor: red[600],
            height: '20px',
            width: '40%',
            zIndex: 200,
            marginTop: '-20px',
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
        },
        linearProgressUser: {
            height: '20px',
        },
        cardContentMultimedia: {
            padding: '0px!important',
            height: '84px',
        },
        doneProgressDonutSummary: {
            color: green[500],
            fontSize: '80px',
        },
        errorProgressDonutSummary: {
            color: red[500],
            fontSize: '80px',
        },
        note: {
            marginTop: '24px',
        },
        textDoneDonutSummary: {
            fontSize: '40px',
            color: green[500],
        },
        textErrorDonutSummary: {
            fontSize: '40px',
            color: red[500],
        },
        accordionSummary: {
            width: '100%',
            marginBottom: '16px',
        },
        accordionDetails: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: 'solid 1px rgba(0,0,0,.2)',
        },
        typographyQuestion: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
        },
        doneIcon: {
            color: green[500],
            marginRight: '8px',
        },
        errorIcon: {
            color: red[500],
            marginRight: '8px',
        },
        typographyResponse1: {
            fontSize: '14px',
        },
        typographyResponse2: {
            fontSize: '13px',
            fontWeight: 100,
        },
        buttonLeft: {
            marginBottom: '8px',
            [theme.breakpoints.up('xs')]: {
                marginRight: '0px',
            },
            [theme.breakpoints.up(576)]: {
                marginRight: '8px',
            },
        },
        button: {
            marginBottom: '8px',
        },
        textDoneDonut: {
            color: green[500],
        },
        textDoneDonutVideoCard: {
            color: green[500],
            fontSize: '10px',
        },
        messageNotCourses: {
            textAlign: 'center',
        },
    }),
);
