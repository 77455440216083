import Regresar from '../../regresar/regresar.compt';
import { useSelector } from 'react-redux';
import { Curso } from '../../cursos/cursos.interfaces';
import CardNavInfo from '../../card-nav/card-nav.compt';
import DetailTitle from '../detail-title/detail-title.compt';
import { DetailTitleData, DataUser } from '../../../interfaces';
import './module-course.sass';
import { Reducers } from '../../../store';

export default function ModuleCourse() {
    const curso = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.course,
    ) as Curso;
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as DataUser;

    /**
     * Get the progress of system, this number will calculate
     * with the number of days transcurred since the register date.
     */
    const getProgressSystem = (): number => {
        const fechaInicio = new Date(curso.fechaAlta);
        const today = new Date();
        const diffDays = Math.round(
            (today.getTime() - fechaInicio.getTime()) / 24 / 1000 / 60 / 60,
        );
        const progress = (diffDays * 100) / 70;

        return Math.round(progress);
    };

    const detail: DetailTitleData = {
        nombre: curso.nombre,
        descripcionLarga: curso.descripcionLarga,
        foto: curso.foto,
        progressSystem: getProgressSystem(),
        progressUser:
            user.cursos?.find((cur) => cur.idCurso === curso.idCurso)
                ?.porcentaje || curso.porcentaje,
        calificacion: 0,
    };

    /**
     * Set percent and flag to know if the download of the certificate.
     *
     * @param modulo
     */
    const setPercent = (modulo) => {
        const data = user.cursos.find((itm) => itm.idCurso === curso.idCurso);
        const mod =
            data && data.modulos
                ? data.modulos.find((val) => val.idModulo === modulo.idModulo)
                : undefined;

        return {
            ...modulo,
            porcentaje:
                mod && mod.porcentaje && mod.porcentaje !== null
                    ? mod.porcentaje
                    : 0,
            banDiploma: mod && mod.porcentaje ? mod.porcentaje >= 85 : false,
        };
    };

    return (
        <div>
            <Regresar route='/ccap/users/cursos' />
            <DetailTitle data={detail} />
            <CardNavInfo
                data={curso.modulos.map((modulo) => setPercent(modulo))}
                route='/ccap/users/sesions'
            />
        </div>
    );
}
