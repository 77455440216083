import { useState, useEffect } from 'react';
import { Times, InfoCardMultimedia } from '../../../interfaces';
import {
    VACIO,
    MULTIMEDIA_TYPE_VIDEO,
    MULTIMEDIA_TYPE_DOCUMENT,
    NAME_COOKIE_USERS,
} from '../../../constants';
import {
    LinearProgress,
    Card,
    CardHeader,
    CardContent,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../courses-users.mat-ui';
import { useMutation } from '@apollo/react-hooks';
import {
    SET_CURRENT_TIME,
    SET_CURRENT_DOCUMENT,
} from '../courses-users.grapqhl';
import { setUserLoginAction } from '../../login/login.actions';
import { useDispatch } from 'react-redux';
import { persistDataLogin } from '../../login/login.service';
import { useSelector } from 'react-redux';
import { Curso } from '../../cursos/cursos.interfaces';
import { sesionSelect } from '../courses-actions';
import { Reducers } from '../../../store';

export default function Video(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [setCurrentTime] = useMutation(SET_CURRENT_TIME);
    const [setCurrentDocument] = useMutation(SET_CURRENT_DOCUMENT);
    const videoInitial: InfoCardMultimedia = {
        url: VACIO,
        type: VACIO,
        title: VACIO,
        name: VACIO,
        banTest: false,
        download: false,
        porcentaje: 0,
        idModulo: VACIO,
        idCurso: VACIO,
        idSesion: VACIO,
        idVideo: VACIO,
        currentTime: 0,
        intentosExamen: [],
    };

    const [video, setVideo] = useState<InfoCardMultimedia>(videoInitial);
    const curso = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.course,
    ) as Curso;
    const modulo = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.module,
    ) as any;
    const sesion = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.sesion,
    ) as any;

    useEffect(() => {
        setVideo(videoInitial);
        setTimeout(() => {
            setVideo(props.multimedia);

            if (
                props.multimedia &&
                props.multimedia.type !== VACIO &&
                props.multimedia.type === MULTIMEDIA_TYPE_VIDEO &&
                props.multimedia.url &&
                props.multimedia.url !== '' &&
                props.multimedia.currentTime > 0
            ) {
                setTimeout(() => {
                    const myVideo: any = document.getElementById(
                        props.multimedia.idVideo,
                    );

                    myVideo.currentTime = props.multimedia.currentTime;
                    myVideo.play();
                }, 5);
            }

            if (
                props.multimedia.type !== VACIO &&
                props.multimedia.type === MULTIMEDIA_TYPE_DOCUMENT &&
                !props.multimedia.download
            ) {
                const data = {
                    idCurso: video.idCurso,
                    idSesion: video.idSesion,
                    idModulo: video.idModulo,
                };

                setCurrentDocument({
                    variables: { newSetCurrentDocument: data },
                })
                    .then((response: any) => {
                        persistDataLogin(
                            response.data.setCurrentDocument,
                            NAME_COOKIE_USERS,
                        );
                        dispatch({
                            type: setUserLoginAction.type,
                            data: response.data.setCurrentDocument,
                        });
                        updateData(response.data.setCurrentDocument);
                    })
                    .catch((error) => console.error(error));
            }
        }, 2);
    }, [props.multimedia]);

    const updateData = (user: any) => {
        const dataC = user.cursos.find((itm) => itm.idCurso === curso.idCurso);
        const dataM =
            dataC && dataC.modulos
                ? dataC.modulos.find((val) => val.idModulo === modulo.idModulo)
                : undefined;
        const dataS =
            dataM && dataM.sesiones
                ? dataM.sesiones.find((val) => val.idSesion === sesion.idSesion)
                : undefined;

        const data = {
            ...sesion,
            intentosExamen:
                dataS && dataS.examen && dataS.examen !== null
                    ? dataS.examen
                    : [],
            banDescargaDocumento:
                dataS && dataS.banDescargaDocumento
                    ? dataS.banDescargaDocumento
                    : false,
            videos:
                dataS && dataS.videos && dataS.videos !== null
                    ? sesion.videos.map((vid) => {
                          const vidUsr: any = dataS.videos;
                          const dataVid = vidUsr.find(
                              (video) => video.idVideo === vid.idVideo,
                          );

                          return {
                              ...vid,
                              ...dataVid,
                          };
                      })
                    : sesion.videos,
            porcentaje:
                dataS && dataS.porcentaje && dataS.porcentaje !== null
                    ? dataS.porcentaje
                    : 0,
        };

        dispatch({ type: sesionSelect.type, sesion: data });
    };

    const timesObject: Times = {
        currentTime: 0,
        duration: 0,
        percentage: 0,
    };

    const [timesVideo, setTimesVideo] = useState(timesObject);

    const pause = () => {
        const myVideo: any = document.getElementById(video.idVideo);
        myVideo.pause();

        const currentTime = myVideo.currentTime;
        const percentage = Math.round(
            (myVideo.currentTime / myVideo.duration) * 100,
        );

        const data = {
            idVideo: video.idVideo,
            idCurso: video.idCurso,
            idSesion: video.idSesion,
            idModulo: video.idModulo,
            porcentaje:
                video.porcentaje > percentage ? video.porcentaje : percentage,
            currentTime:
                video.currentTime > currentTime
                    ? video.currentTime
                    : currentTime,
        };

        setCurrentTime({ variables: { newSetTimeVideo: data } })
            .then((response: any) => {
                persistDataLogin(
                    response.data.setCurrentTimeVideo,
                    NAME_COOKIE_USERS,
                );
                dispatch({
                    type: setUserLoginAction.type,
                    data: response.data.setCurrentTimeVideo,
                });
                updateData(response.data.setCurrentTimeVideo);
            })
            .catch((error) => console.error(error));

        setTimesVideo({
            ...timesVideo,
            currentTime: data.currentTime,
            percentage: data.porcentaje,
        });
    };

    const play = () => {
        const myVideo: any = document.getElementById(video.idVideo);
        if (props.multimedia.currentTime === 0) {
            myVideo.play();
        }

        if (timesVideo.duration === 0) {
            setTimesVideo({
                ...timesVideo,
                duration: myVideo.duration,
            });
        }
    };

    const closeCard = () => {
        props.resetDataVideo();
        setVideo(videoInitial);
    };

    return (
        <div className={classes.iframeDoc}>
            {video.url === VACIO ? (
                <LinearProgress color='secondary' />
            ) : (
                <Card className={classes.card}>
                    <CardHeader
                        action={
                            <IconButton onClick={closeCard} aria-label='close'>
                                <CloseIcon color='secondary' />
                            </IconButton>
                        }
                        title={`${props.titleSesion} - ${video.title}`}
                    />
                    <CardContent
                        className={
                            video.type === MULTIMEDIA_TYPE_VIDEO
                                ? classes.iframeDoc
                                : classes.displayNone
                        }
                    >
                        <video
                            id={video.idVideo}
                            width='100%'
                            controls
                            controlsList='nodownload'
                            onPause={pause}
                            onCanPlay={play}
                        >
                            <source src={video.url} type='video/mp4' />
                            Su navegador no soporta contenido multimedia.
                        </video>
                    </CardContent>

                    <CardContent
                        className={
                            video.type === MULTIMEDIA_TYPE_DOCUMENT
                                ? classes.iframeDoc
                                : classes.displayNone
                        }
                    >
                        <iframe
                            title='content'
                            className={classes.iframe}
                            src={video.url}
                        ></iframe>
                    </CardContent>
                </Card>
            )}
        </div>
    );
}
