import { useState } from 'react';
import Regresar from '../../regresar/regresar.compt';
import DetailTitle from '../detail-title/detail-title.compt';
import { useSelector } from 'react-redux';
import {
    DetailTitleData,
    InfoCardMultimedia,
    DataControlModalParent,
} from '../../../interfaces';
import { Typography } from '@material-ui/core';
import { useStyles } from '../courses-users.mat-ui';
import MultimediaCard from '../multimedia-card/multimedia-card.compt';
import {
    MULTIMEDIA_TYPE_VIDEO,
    MULTIMEDIA_TYPE_DOCUMENT,
    MULTIMEDIA_TYPE_TEST,
    VACIO,
    STATUS,
} from '../../../constants';
import Video from '../video-card/video-card.compt';
import ProgressDetail from '../progress-user/progress-user.compt';
import SesionTest from '../sesion-test/sesion-test.compt';
import { Curso } from '../../cursos/cursos.interfaces';
import Snackbars from '../../snackbar/snackbar.compt';
import { Reducers } from '../../../store';

export default function SesionDetail() {
    const classes = useStyles();
    const curso = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.course,
    ) as Curso;
    const modulo = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.module,
    ) as any;
    const sesion = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.sesion,
    ) as any;
    const [test, setTest] = useState<DataControlModalParent>({ open: false });
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    const multimediaInitial: InfoCardMultimedia = {
        url: VACIO,
        type: VACIO,
        title: VACIO,
        name: VACIO,
        banTest: false,
        download: false,
        porcentaje: 0,
        idModulo: modulo.idModulo,
        idCurso: curso.idCurso,
        idSesion: sesion.idSesion,
        idVideo: VACIO,
        currentTime: 0,
        intentosExamen: [],
    };

    const [cardMultimedia, setCardMultimedia] =
        useState<InfoCardMultimedia>(multimediaInitial);

    /**
     * Get the progress of system, this number will calculate
     * with the number of days transcurred since the register date.
     */
    const getProgressSystem = (): number => {
        const today = new Date();
        const countSessions = modulo.sesiones.length;
        const indexMod = curso.modulos.findIndex(
            (mod) => mod.idModulo === modulo.idModulo,
        );
        const indexSes = modulo.sesiones.findIndex(
            (ses) => ses.idSesion === sesion.idSesion,
        );
        const daysForModules = Math.round(70 / curso.modulos.length);
        const daysForSesions = Math.round(daysForModules / countSessions);
        const initDays = indexMod * daysForModules + indexSes * daysForSesions;
        const dateInit = new Date(
            curso.fechaAlta + Math.round(initDays * 24) * (1000 * 60 * 60),
        );
        const endDays =
            indexMod * daysForModules + (indexSes + 1) * daysForSesions;
        const dateEnd = new Date(
            curso.fechaAlta + Math.round(endDays * 24) * (1000 * 60 * 60),
        );

        if (today > dateEnd) {
            return 100;
        }

        const diffDays = Math.round(
            (today.getTime() - dateInit.getTime()) / 24 / 1000 / 60 / 60,
        );
        const progress = (diffDays * 100) / daysForSesions;
        return Math.round(progress);
    };

    const porcentajesUsuario = {
        progressSystem: getProgressSystem(),
        progressUser:
            sesion.porcentaje && sesion.porcentaje !== null
                ? sesion.porcentaje
                : 0,
        calificacion:
            sesion.intentosExamen &&
            sesion.intentosExamen !== null &&
            sesion.intentosExamen.length > 0
                ? sesion.intentosExamen.sort(
                      (a: any, b: any) => b.calificacion - a.calificacion,
                  )[0].calificacion
                : 0,
    };

    const detail: DetailTitleData = {
        ...porcentajesUsuario,
        nombre: sesion.nombre,
        descripcionLarga: sesion.descripcionLarga,
        foto: sesion.foto,
    };

    const dataMultimediaInit: InfoCardMultimedia[] = [
        {
            ...multimediaInitial,
            url: sesion.documento,
            type: MULTIMEDIA_TYPE_DOCUMENT,
            title: 'Documento',
            name: sesion.nombreDocumento,
            download:
                sesion.banDescargaDocumento &&
                sesion.banDescargaDocumento !== null
                    ? sesion.banDescargaDocumento
                    : false,
            porcentaje: 0,
            banTest: false,
            idVideo: VACIO,
        },
        {
            ...multimediaInitial,
            url: VACIO,
            type: MULTIMEDIA_TYPE_TEST,
            title: 'Examen',
            name: sesion.nombreExamen,
            banTest:
                sesion.intentosExamen && sesion.intentosExamen !== null
                    ? sesion.intentosExamen.length > 0
                    : false,
            porcentaje: 0,
            download: false,
            idVideo: VACIO,
            intentosExamen:
                sesion.intentosExamen && sesion.intentosExamen !== null
                    ? sesion.intentosExamen
                    : [],
        },
    ];

    const closeModalTest = (value: boolean) => {
        setTest({ open: false, closeParent: closeModalTest });
    };

    const cardMultimediaSelect = (data: InfoCardMultimedia) => {
        if (data.type !== MULTIMEDIA_TYPE_TEST) {
            setCardMultimedia(data);
        } else if (data.intentosExamen.length < 2) {
            setTest({
                open: true,
                closeParent: closeModalTest,
                course: {
                    idModulo: data.idModulo,
                    idCurso: data.idCurso,
                    idSesion: data.idSesion,
                },
                test: sesion.examen.map((test) =>
                    Object.assign(test, { seleccionada: VACIO }),
                ),
            });
        } else if (data.intentosExamen.length === 2) {
            openSnackBar(
                'Ya hiciste dos intentos, no es posible realizar otro más',
                STATUS.info,
            );
        }
    };

    const resetDataVideo = () => {
        setCardMultimedia(multimediaInitial);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    return (
        <div>
            <Regresar route='/ccap/users/sesions' />

            <div
                className={
                    cardMultimedia.url !== VACIO
                        ? classes.displayNone
                        : classes.videoContainer
                }
            >
                <DetailTitle data={detail} />
            </div>

            <div
                className={
                    cardMultimedia.url === VACIO
                        ? classes.displayNone
                        : classes.videoContainer
                }
            >
                <Video
                    multimedia={cardMultimedia}
                    resetDataVideo={resetDataVideo}
                    titleSesion={detail.nombre}
                />
                <div className={classes.containerVideo}>
                    <ProgressDetail data={porcentajesUsuario} />
                </div>
            </div>

            <Typography
                className={classes.contentTitle}
                gutterBottom
                variant='h6'
                component='h2'
            >
                Contenido:
            </Typography>

            <div className={classes.containerMultimediaCards}>
                {sesion.videos && sesion.videos !== null
                    ? sesion.videos
                          .reduce(
                              (init, current, pos) => [
                                  {
                                      ...multimediaInitial,
                                      url: current.valorVideo,
                                      title: `Video ${pos + 1}`,
                                      name: current.nombre,
                                      type: MULTIMEDIA_TYPE_VIDEO,
                                      porcentaje:
                                          current.porcentaje &&
                                          current.porcentaje !== null
                                              ? current.porcentaje
                                              : 0,
                                      banTest: false,
                                      download: false,
                                      idVideo: current.idVideo,
                                      currentTime:
                                          current.currentTime &&
                                          current.currentTime !== null
                                              ? Number(current.currentTime)
                                              : 0,
                                  },
                                  ...init,
                              ],
                              dataMultimediaInit,
                          )
                          .map((item: InfoCardMultimedia, index) => (
                              <MultimediaCard
                                  key={index}
                                  data={item}
                                  cardMultimediaEvent={cardMultimediaSelect}
                              />
                          ))
                    : ''}
            </div>

            <SesionTest options={test} />
            <Snackbars options={snackbar} />
        </div>
    );
}
