import { SET_USER_LOGIN } from './login.actions';
import { User } from '../../interfaces';

const initialState: User = {};

const loginReducer = (state = initialState, actions: any) => {
    switch (actions.type) {
        case SET_USER_LOGIN:
            return {
                ...actions.data,
            };

        default:
            return state;
    }
};

export default loginReducer;
