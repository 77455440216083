import { Typography } from '@material-ui/core';
import { useStyles } from '../courses-users.mat-ui';
import { DataProgressBar } from '../../../interfaces';

export default function ProgressBarUser(props: DataProgressBar) {
    const classes = useStyles();

    return (
        <div>
            <div id='progressUser1' className={classes.progressUser}></div>
            <div
                id='progressUser2'
                style={{ width: `${props.data.progressSystem}%` }}
                className={classes.progressUser2}
            ></div>
            <div
                id='progressUser3'
                style={{ width: `${props.data.progressUser}%` }}
                className={classes.progressUser3}
            >
                <Typography gutterBottom variant='subtitle2' component='div'>
                    <b>
                        {props.data.progressUser !== 0
                            ? `${props.data.progressUser}%`
                            : ''}
                    </b>
                </Typography>
            </div>
        </div>
    );
}
