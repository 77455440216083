import React, { useCallback, useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './usuarios.styles-matui';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ALL_COURSES } from '../cursos/cursos.graphql';
import UsuarioGeneral from './usuario-general/usuario-general.compt';
import { GET_ALL_USERS } from './usuarios.graphql';
import { useSelector } from 'react-redux';
import UsuarioCurso from './usuario-curso/usuario-curso.compt';
import Snackbars from '../snackbar/snackbar.compt';
import { DataControlModalParent, User } from '../../interfaces';
import { STATUS, ROLES_USUARIOS, VACIO } from '../../constants';
import './usuarios.sass';
import { LinearProgress } from '@material-ui/core';
import Regresar from '../regresar/regresar.compt';
import { Administrador } from '../administradores/administradores.interfaces';
import { Curso } from '../cursos/cursos.interfaces';
import { Reducers } from '../../store';

export default function Usuarios() {
    const classes = useStyles();
    const [buscador, setBuscador] = useState('general');
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const loguedUser = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as Administrador;
    const [filtro, setFiltro] = useState(VACIO);
    const queryCourses = useQuery(GET_ALL_COURSES);
    const [fetchItem, updateQueryUsers] = useLazyQuery(GET_ALL_USERS);
    const [users, setUsers] = useState<Array<User>>([]);

    const getUsers = useCallback(async () => {
        await fetchItem().then((response) => setUsers(response.data.getUsers));
    }, []);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleChangeBuscador = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const nombreCurso = event.target.value as string;
        if (nombreCurso === 'general') {
            setFiltro(VACIO);
        } else {
            const cursoSelect: Curso | undefined =
                queryCourses.data.getCursos.find(
                    (cur) => cur.nombre === nombreCurso,
                );
            const idSelect = cursoSelect ? cursoSelect.idCurso : VACIO;
            setFiltro(idSelect);
        }

        setBuscador(event.target.value as string);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    if (queryCourses.loading || updateQueryUsers.loading) {
        return <LinearProgress color='secondary' />;
    }

    if (queryCourses.error || updateQueryUsers.error) {
        openSnackBar('No se ha podido cargar el contenido', STATUS.error);
    }

    const refreshUsers = async (message: string) => {
        await fetchItem()
            .then((response) => {
                setUsers(response.data.getUsers);
                openSnackBar(message, STATUS.success);
            })
            .catch(() => {
                openSnackBar(
                    'No se pudo actualizar al usuario, intenta nuevamente',
                    STATUS.success,
                );
            });
    };

    return (
        <div className={classes.root}>
            <Regresar route='/ccap/admin/menu' />
            <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                    Buscar
                </InputLabel>
                <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={buscador}
                    onChange={handleChangeBuscador}
                    label='Opción'
                >
                    <MenuItem
                        key='general'
                        className={classes.menuItem}
                        value='general'
                    >
                        General
                    </MenuItem>
                    {queryCourses &&
                    queryCourses.data &&
                    queryCourses.data.getCursos
                        ? queryCourses.data.getCursos
                              .filter(
                                  (cur) =>
                                      loguedUser.tipo === ROLES_USUARIOS.SADM ||
                                      (loguedUser.cursos &&
                                          loguedUser.cursos !== null &&
                                          { ...loguedUser }.cursos.find(
                                              (cru) =>
                                                  cru.idCurso === cur.idCurso,
                                          ) !== undefined),
                              )
                              .map((curso, index) => (
                                  <MenuItem
                                      key={index}
                                      className={classes.menuItem}
                                      value={curso.nombre}
                                  >
                                      {curso.nombre}
                                  </MenuItem>
                              ))
                        : null}
                </Select>
            </FormControl>

            <div
                className={
                    buscador === 'general' ? 'tableDisplay' : 'tableDisplayNone'
                }
            >
                <UsuarioGeneral usuarios={users} refreshUsers={refreshUsers} />
            </div>

            <div
                className={
                    buscador !== 'general' ? 'tableDisplay' : 'tableDisplayNone'
                }
            >
                <UsuarioCurso
                    usuarios={users}
                    course={filtro}
                    refreshUsers={refreshUsers}
                />
            </div>

            <Snackbars options={snackbar} />
        </div>
    );
}
