import React, { useState, useEffect, useCallback } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from './administradores.styles-matui';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ALL_COURSES } from '../cursos/cursos.graphql';
import AdministradorGeneral from './admin-general/admin-general.compt';
import { GET_ALL_ADMINS } from './administradores.graphql';
import { useSelector } from 'react-redux';
import AdministradorCurso from './admin-curso/admin-curso.compt';
import Snackbars from '../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../interfaces';
import { STATUS, ROLES_USUARIOS, VACIO } from '../../constants';
import Regresar from '../regresar/regresar.compt';
import { Administrador } from './administradores.interfaces';
import './administradores.sass';
import { LinearProgress } from '@material-ui/core';
import { Curso } from '../cursos/cursos.interfaces';
import { Reducers } from '../../store';

export default function Administradores() {
    const classes = useStyles();
    const [buscador, setBuscador] = useState('general');
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [filtro, setFiltro] = useState<string>(VACIO);
    const loguedUser = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as Administrador;
    const queryCourses = useQuery(GET_ALL_COURSES);
    const [fetchAdmins, queryAdmins] = useLazyQuery(GET_ALL_ADMINS);
    const [userAdmins, setUserAdmins] = useState<Array<Administrador>>([]);

    const getUserAdmins = useCallback(async () => {
        await fetchAdmins().then((response) =>
            setUserAdmins(response.data.getAdmins),
        );
    }, []);

    useEffect(() => {
        getUserAdmins();
    }, [getUserAdmins]);

    const handleChangeBuscador = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const buscador = event.target.value as string;
        if (buscador !== 'general') {
            const cursoSel: Curso | undefined =
                queryCourses.data.getCursos.find(
                    (cur) => cur.nombre === buscador,
                );
            const idCurso = cursoSel ? cursoSel.idCurso : VACIO;
            setFiltro(idCurso);
        }

        setBuscador(buscador);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    if (queryAdmins.loading || queryCourses.loading) {
        return <LinearProgress color='secondary' />;
    }

    if (queryAdmins.error || queryCourses.error) {
        openSnackBar('No se ha podido cargar el contenido', STATUS.error);
    }

    const refreshUserAdmins = async (message: string) => {
        await fetchAdmins()
            .then((response) => {
                setUserAdmins(response.data.getAdmins);
                openSnackBar(message, STATUS.success);
            })
            .catch(() => {
                openSnackBar(
                    'No se pudo actualizar al usuario, intenta nuevamente',
                    STATUS.success,
                );
            });
    };

    return (
        <div className={classes.root}>
            <Regresar route='/ccap/admin/menu' />
            <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                    Buscar
                </InputLabel>
                <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={buscador}
                    onChange={handleChangeBuscador}
                    label='Opción'
                >
                    <MenuItem
                        key='general'
                        className={classes.menuItem}
                        value='general'
                    >
                        General
                    </MenuItem>
                    {queryCourses &&
                    queryCourses.data &&
                    queryCourses.data.getCursos
                        ? queryCourses.data.getCursos
                              .filter(
                                  (cur) =>
                                      loguedUser.tipo === ROLES_USUARIOS.SADM ||
                                      (loguedUser.cursos &&
                                          loguedUser.cursos !== null &&
                                          loguedUser.cursos.find(
                                              (cru) =>
                                                  cru.idCurso === cur.idCurso,
                                          ) !== undefined),
                              )
                              .map((curso, index) => (
                                  <MenuItem
                                      key={index}
                                      className={classes.menuItem}
                                      value={curso.nombre}
                                  >
                                      {curso.nombre}
                                  </MenuItem>
                              ))
                        : null}
                </Select>
            </FormControl>

            <div
                className={
                    buscador === 'general' ? 'tableDisplay' : 'tableDisplayNone'
                }
            >
                <AdministradorGeneral
                    administradores={userAdmins}
                    refreshUserAdmins={refreshUserAdmins}
                />
            </div>

            <div
                className={
                    buscador !== 'general' ? 'tableDisplay' : 'tableDisplayNone'
                }
            >
                <AdministradorCurso
                    administradores={userAdmins}
                    course={filtro}
                    refreshUserAdmins={refreshUserAdmins}
                />
            </div>

            <Snackbars options={snackbar} />
        </div>
    );
}
