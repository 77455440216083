import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Button,
    CircularProgress,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { VACIO } from '../../../constants';
import { useStyles } from '../courses-users.mat-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useGeneralStyles } from '../../../styles-matui';
import './summary-test-user.sass';

export default function SummaryTest(props) {
    const classes = useStyles();
    const generalClasses = useGeneralStyles();
    const [infoTest, setInfoTest] = useState({
        calificacion: 0,
        intento: null,
        preguntas: [
            { pregunta: VACIO, respuesta: VACIO, correcta: null },
            { pregunta: VACIO, respuesta: VACIO, correcta: null },
            { pregunta: VACIO, respuesta: VACIO, correcta: null },
        ],
    });
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) =>
        (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    useEffect(() => {
        if (props.options.test) {
            setInfoTest(props.options.test[0]);
        }
    }, [props.options.test]);

    const onClickDialogOpenClose = () => {
        setExpanded(false);
        props.options.closeParent();
    };

    return (
        <div>
            <Dialog
                maxWidth='md'
                scroll='body'
                open={props.options.open}
                className={generalClasses.modal}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
            >
                <div className='containerModalSummary'>
                    <DialogTitle
                        className={generalClasses.titleForm}
                        id='form-dialog-title'
                    >
                        TU PUNTUACIÓN
                    </DialogTitle>
                    <DialogContent>
                        <div className='containerSummary'>
                            <div className='summaryDonut'>
                                <Box position='relative' display='inline-flex'>
                                    <CircularProgress
                                        className={
                                            infoTest.calificacion &&
                                            infoTest.calificacion !== null &&
                                            infoTest.calificacion > 70
                                                ? classes.doneProgressDonutSummary
                                                : classes.errorProgressDonutSummary
                                        }
                                        variant='determinate'
                                        value={100}
                                        size={150}
                                    />
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position='absolute'
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <Typography
                                            variant='caption'
                                            component='div'
                                            className={
                                                infoTest.calificacion &&
                                                infoTest.calificacion !==
                                                    null &&
                                                infoTest.calificacion > 70
                                                    ? classes.textDoneDonutSummary
                                                    : classes.textErrorDonutSummary
                                            }
                                        >
                                            {infoTest.calificacion}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography
                                    variant='caption'
                                    component='div'
                                    color='textSecondary'
                                    className={
                                        infoTest.calificacion &&
                                        infoTest.calificacion !== null &&
                                        infoTest.calificacion > 70
                                            ? classes.displayNone
                                            : classes.note
                                    }
                                >
                                    * Necesitas mínimo el 70 para pasar al
                                    siguiente módulo.
                                </Typography>
                            </div>

                            <div className='summaryResponses'>
                                {infoTest.preguntas.map((pregunta, pos) => (
                                    <Accordion
                                        key={pos}
                                        expanded={expanded === `panel${pos}`}
                                        onChange={handleChange(`panel${pos}`)}
                                        className={classes.accordionSummary}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-header${pos}`}
                                            id={`panel-header${pos}`}
                                        >
                                            <Typography
                                                variant='h6'
                                                component='h6'
                                                className={
                                                    classes.typographyQuestion
                                                }
                                            >
                                                {pregunta.correcta ? (
                                                    <CheckCircleIcon
                                                        className={
                                                            classes.doneIcon
                                                        }
                                                    />
                                                ) : (
                                                    <CancelIcon
                                                        className={
                                                            classes.errorIcon
                                                        }
                                                    />
                                                )}
                                                {pregunta.pregunta}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            className={classes.accordionDetails}
                                        >
                                            <Typography
                                                className={
                                                    classes.typographyResponse1
                                                }
                                            >
                                                - Respuesta seleccionada:
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.typographyResponse2
                                                }
                                            >
                                                {pregunta.respuesta}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className={generalClasses.dialogActions}>
                        <div className='containerActionsSummary'>
                            <Button
                                onClick={onClickDialogOpenClose}
                                variant='contained'
                                color='primary'
                            >
                                CERRAR
                            </Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
