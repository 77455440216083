import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useStyles } from '../cursos.styles-matui';
import { useGeneralStyles } from '../../../styles-matui';
import Regresar from '../../regresar/regresar.compt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgressComp from '../../linear-progress/linear-progress.compt';
import './nuevo-curso.sass';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import PublishIcon from '@material-ui/icons/Publish';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    OutlinedInput,
    InputAdornment,
    Tooltip,
} from '@material-ui/core';
import {
    GeneralDataCursos,
    ModulosParentCursos,
    SesionesCursos,
    CursosControlAcordion,
    NewCurso,
    NewModulo,
    ModulosCursos,
    Curso,
    NewSesion,
    NewVideo,
    AdministradoresPermisosCursos,
} from '../cursos.interfaces';
import { green } from '@material-ui/core/colors';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import {
    initGeneralData,
    initModulosContenidoData,
    initNewSesion,
    initNewModulo,
    initCursosControlAcordion,
} from '../init-data';
import AsignarUsuarios from '../asignar-usuarios/asignar-usuarios.compt';
import AsignarAdministrador from '../asignar-administrador/asignar-administrador.compt';
import { useFirebaseApp } from 'reactfire';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import {
    NUEVO_CURSO,
    SET_MODULES_COURSE,
    GET_COURSE,
    SET_SESION_COURSE,
    DELETE_SESION,
    DELETE_MODULE,
    DELETE_VIDEO,
} from '../cursos.graphql';
import Spinner from '../../spinner/spinner.compt';
import Snackbars from '../../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../../interfaces';
import {
    STATUS,
    PHOTO_MODULO_URL,
    PHOTO_SESION_URL,
    REPLACE_NOMBREFOTO,
    NAME_COOKIE_ADMINS,
    REPLACE_IDCURSO,
    VACIO,
    PHOTO_CURSO,
    DOCUMENTO_SESION_URL,
    REPLACE_NOMBREDOCUMENTO,
    VIDEO_SESION_URL,
    REPLACE_NOMBREVIDEO,
    ROLES_USUARIOS,
} from '../../../constants';
import { SET_CURSO_ADMIN } from '../../administradores/administradores.graphql';
import {
    SetUsuarioCurso,
    SUsuarioCurso,
} from '../../usuarios/usuarios.interfaces';
import { ADD_CONFIG_USUARIO_CURSO } from '../../usuarios/usuarios.graphql';
import * as XLSX from 'xlsx';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {
    Administrador,
    CursosAdmin,
} from '../../administradores/administradores.interfaces';
import { getDataLogin, persistDataLogin } from '../../login/login.service';
import { useSearchParams } from 'react-router-dom';

export default function NuevoCurso() {
    const firebase = useFirebaseApp(); // Access the initialized Firebase app
    const storage = getStorage(firebase); // Get the Storage instance
    const classes = useStyles();
    const generalClassses = useGeneralStyles();
    const adminData: any = getDataLogin(NAME_COOKIE_ADMINS);
    const user = JSON.parse(adminData) as Administrador;
    const sessionGenData: any = sessionStorage.getItem('generalData');
    const sessionModulosData: any = sessionStorage.getItem('modulosData');
    const sessionUsrControlData: any =
        sessionStorage.getItem('usuariosControl');
    const sessionAdminControlData: any = sessionStorage.getItem(
        'administradoresControl',
    );
    const [generalData, setGeneralData] = useState<GeneralDataCursos>(
        sessionGenData === null ? initGeneralData : JSON.parse(sessionGenData),
    );
    const [modulosData, setModulosData] = useState<ModulosParentCursos>(
        sessionGenData === null
            ? initModulosContenidoData
            : JSON.parse(sessionModulosData),
    );
    const [usuariosControl, setUsuariosControl] =
        useState<CursosControlAcordion>(
            sessionGenData === null
                ? initCursosControlAcordion
                : JSON.parse(sessionUsrControlData),
        );
    const [administradoresControl, setAdministradoresControl] =
        useState<CursosControlAcordion>(
            sessionGenData === null
                ? initCursosControlAcordion
                : JSON.parse(sessionAdminControlData),
        );
    const [progress, setProgress] = React.useState(0);
    const [posModuloImg, setPosModuloImg] = useState(0);
    const [addCurso] = useMutation(NUEVO_CURSO);
    const [setConfigAdmin] = useMutation(SET_CURSO_ADMIN);
    const [setConfigUser] = useMutation(ADD_CONFIG_USUARIO_CURSO);
    const [setModulosCurso] = useMutation(SET_MODULES_COURSE);
    const [setSesionModule] = useMutation(SET_SESION_COURSE);
    const [deleteSesion] = useMutation(DELETE_SESION);
    const [deleteModule] = useMutation(DELETE_MODULE);
    const [deleteVideo] = useMutation(DELETE_VIDEO);
    const colorDone = { color: green[500] };
    const [openSpinner, setOpenSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [searchParams] = useSearchParams();
    const [fetchCourse, queryCourses] = useLazyQuery(GET_COURSE, {
        variables: { idCurso: searchParams.get('idCursoSelected') },
    });
    const [message, setMessage] = useState<string>(
        'Creación de nuevo curso de capacitación',
    );
    const [adminsCourse, setAdminsCourse] = useState<
        Array<AdministradoresPermisosCursos>
    >([]);
    const [usersCourse, setUsersCourse] = useState<Array<SUsuarioCurso>>([]);

    const getCourse = useCallback(async () => {
        return await fetchCourse()
            .then((response) => {
                const course = response.data?.getCurso;
                return course as Curso;
            })
            .catch(() => null);
    }, []);

    useEffect(() => {
        const setCourseData = async () => {
            const curso = await getCourse();
            if (!!curso) {
                let cantProg = 0;

                setMessage(`Edición de curso de capacitación: ${curso.nombre}`);
                setGeneralData({
                    ...generalData,
                    complete: true,
                    valorFoto: curso.foto,
                    nombreCurso: curso.nombre,
                    descripcionCorta: curso.descripcionCorta,
                    descripcionLarga: curso.descripcionLarga,
                    tipo: curso.tipo,
                    idCurso: curso.idCurso,
                    fechaAlta: curso.fechaAlta,
                    duracion: curso.duracion,
                    cantUsuarios: curso.cantUsuarios,
                    cantAdministradores: curso.cantAdministradores,
                });

                if (curso.cantAdministradores > 0) {
                    cantProg += 25;
                    setAdministradoresControl({
                        ...usuariosControl,
                        disabled: false,
                        complete: true,
                    });
                } else {
                    setAdministradoresControl({
                        ...usuariosControl,
                        disabled: false,
                    });
                }

                if (curso.cantUsuarios > 0) {
                    cantProg += 25;
                    setUsuariosControl({
                        ...usuariosControl,
                        disabled: false,
                        complete: true,
                    });
                } else {
                    setUsuariosControl({ ...usuariosControl, disabled: false });
                }

                cantProg += 25;

                if (curso.modulos && curso.modulos !== null) {
                    cantProg += 25;

                    setModulosData({
                        ...modulosData,
                        complete: true,
                        disabled: false,
                        modulos: curso.modulos.map((modulo: any) => {
                            const newModulo: ModulosCursos = {
                                ...initNewModulo,
                                idModulo: modulo.idModulo,
                                valorFoto: modulo.foto,
                                nombreModulo: modulo.nombre,
                                descripcionCorta: modulo.descripcionCorta,
                                saved: true,
                                sesiones:
                                    modulo.sesiones !== null
                                        ? modulo.sesiones.map(
                                              (sesion: NewSesion) => {
                                                  const newSesion: SesionesCursos =
                                                      {
                                                          ...initNewSesion,
                                                          fechaAlta:
                                                              sesion.fechaAlta,
                                                          idSesion:
                                                              sesion.idSesion,
                                                          valorFoto:
                                                              sesion.foto,
                                                          titulo: sesion.nombre,
                                                          descripcionCorta:
                                                              sesion.descripcionCorta,
                                                          descripcionLarga:
                                                              sesion.descripcionLarga,
                                                          valorExamen:
                                                              sesion.examen,
                                                          valorDocumento:
                                                              sesion.documento,
                                                          nombreDocumento:
                                                              sesion.nombreDocumento,
                                                          nombreExamen:
                                                              sesion.nombreExamen,
                                                          nombreFoto:
                                                              sesion.nombreFoto,
                                                          videos:
                                                              sesion.videos !==
                                                              null
                                                                  ? sesion.videos.map(
                                                                        (
                                                                            video,
                                                                        ) => {
                                                                            return {
                                                                                idVideo:
                                                                                    video.idVideo,
                                                                                nombreLargoVideo:
                                                                                    video.nombre,
                                                                                nombreCortoVideo: `${video.nombre.substring(
                                                                                    0,
                                                                                    8,
                                                                                )}...`,
                                                                                duracionVideo:
                                                                                    video.duracionVideo,
                                                                                valorVideo:
                                                                                    video.valorVideo,
                                                                            };
                                                                        },
                                                                    )
                                                                  : [],
                                                      };

                                                  return newSesion;
                                              },
                                          )
                                        : [initNewSesion],
                            };

                            return newModulo;
                        }),
                    });
                } else {
                    setModulosData({ ...modulosData, disabled: false });
                }

                setProgress(progress + cantProg);
            }
        };
        if (searchParams.get('idCursoSelected')) {
            setCourseData();
        }
    }, [getCourse]);

    const handleChangeBuscador = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        setGeneralData({
            ...generalData,
            tipo: event.target.value as string,
        });
    };

    const saveGenerals = () => {
        const urlFoto =
            generalData.blobFoto !== VACIO &&
            generalData.valorFoto.includes('data:image/')
                ? PHOTO_CURSO.replace(
                      REPLACE_NOMBREFOTO,
                      `foto.${generalData.nombreFoto.split('.')[1]}`,
                  )
                : generalData.valorFoto;

        const data: NewCurso = {
            idAdministrador: user.idAdministrador,
            foto: urlFoto,
            nombre: generalData.nombreCurso,
            descripcionCorta: generalData.descripcionCorta,
            tipo: generalData.tipo,
            descripcionLarga: generalData.descripcionLarga,
            duracion: generalData.duracion,
            cantAdministradores: generalData.cantAdministradores,
            cantUsuarios: generalData.cantUsuarios,
            fechaAlta: generalData.fechaAlta,
        };

        setOpenSpinner(true);
        addCurso({ variables: { idCurso: generalData.idCurso, curso: data } })
            .then((response) => response.data.createCurso)
            .then((idCurso) => {
                const fileRef = ref(
                    storage,
                    `/cursos/${idCurso}/foto.${
                        generalData.nombreFoto.split('.')[1]
                    }`,
                );
                return Promise.all([
                    idCurso,
                    generalData.blobFoto !== VACIO &&
                    generalData.valorFoto.includes('data:image/')
                        ? uploadBytes(fileRef, generalData.blobFoto)
                        : null,
                ]);
            })
            .then((result: any[]) => {
                setGeneralData({
                    ...generalData,
                    idCurso: result[0],
                    expanded: false,
                    complete: true,
                    valorFoto: urlFoto.replace(REPLACE_IDCURSO, result[0]),
                });
                setModulosData({
                    ...modulosData,
                    disabled: false,
                    expanded: true,
                });
                setUsuariosControl({ ...usuariosControl, disabled: false });
                setAdministradoresControl({
                    ...administradoresControl,
                    disabled: false,
                });

                sessionStorage.setItem(
                    'generalData',
                    JSON.stringify({
                        ...generalData,
                        idCurso: result[0],
                        valorFoto: urlFoto.replace(REPLACE_IDCURSO, result[0]),
                    }),
                );
                sessionStorage.setItem(
                    'modulosData',
                    JSON.stringify(modulosData),
                );
                sessionStorage.setItem(
                    'usuariosControl',
                    JSON.stringify(usuariosControl),
                );
                sessionStorage.setItem(
                    'administradoresControl',
                    JSON.stringify(administradoresControl),
                );

                if (!generalData.complete) {
                    setProgress(progress + 25);
                }

                const cursoAdmin: CursosAdmin = {
                    idCurso: result[0],
                    permisos: [
                        'PERADMCUR01',
                        'PERADMCUR02',
                        'PERADMCUR03',
                        'PERADMCUR04',
                        'PERADMCUR05',
                        'PERADMCUR06',
                    ],
                };

                const admin: Administrador = {
                    ...user,
                    cursos:
                        user.cursos && user.cursos !== null
                            ? [...user.cursos, cursoAdmin]
                            : [cursoAdmin],
                };

                persistDataLogin(admin, NAME_COOKIE_ADMINS);
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al guardar curso`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const saveModule = () => {
        const dataCurso: NewModulo[] = modulosData.modulos.map((modulo) => {
            const urlFoto = PHOTO_MODULO_URL.replace(
                REPLACE_IDCURSO,
                generalData.idCurso,
            ).replace(REPLACE_NOMBREFOTO, modulo.nombreFoto);

            return {
                idModulo: modulo.idModulo,
                foto: modulo.blobFoto !== VACIO ? urlFoto : modulo.valorFoto,
                nombre: modulo.nombreModulo,
                descripcionCorta: modulo.descripcionCorta,
                sesiones: modulo.sesiones
                    .map((sesion) =>
                        generateDataRequestSesionForModules(sesion),
                    )
                    .filter((ses) => ses.idSesion !== VACIO),
            };
        });

        let asignarModulosData;

        setOpenSpinner(true);
        setModulosCurso({
            variables: { idCurso: generalData.idCurso, data: dataCurso },
        })
            .then((response) => {
                const storagePromise: any[] = [];

                response.data.asignarModulos.forEach((modulos: any) => {
                    const idModulo = modulos.idModulo;
                    const modul: any = modulosData.modulos.find(
                        (mod) => mod.nombreModulo === modulos.nombre,
                    );

                    if (modul.blobFoto !== VACIO) {
                        const fileRef = ref(
                            storage,
                            `/cursos/${generalData.idCurso}/${idModulo}/${modul.nombreFoto}`,
                        );
                        storagePromise.push(
                            uploadBytes(fileRef, modul.blobFoto),
                        );
                    }
                });

                asignarModulosData = response.data.asignarModulos;
                return Promise.all(storagePromise);
            })
            .then(() => {
                return new Promise<ModulosCursos[]>((resolve, reject) => {
                    try {
                        setTimeout(() => {
                            const newData = asignarModulosData.map(
                                (element) => {
                                    const modSelect: any =
                                        modulosData.modulos.find(
                                            (mod) =>
                                                mod.nombreModulo ===
                                                element.nombre,
                                        );

                                    const newModul = {
                                        ...modSelect,
                                        idModulo: element.idModulo,
                                        valorFoto: element.foto,
                                        saved: true,
                                    };

                                    return newModul;
                                },
                            );

                            resolve(newData);
                        }, 1000);
                    } catch (error) {
                        reject(error);
                    }
                });
            })
            .then((data: ModulosCursos[]) => {
                setModulosData({ ...modulosData, modulos: data });
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al guardar modulos`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const generateDataRequestSesionForModules = (sesion): NewSesion => {
        return {
            idSesion: sesion.idSesion,
            foto: sesion.valorFoto,
            nombre: sesion.titulo,
            descripcionCorta: sesion.descripcionCorta,
            descripcionLarga: sesion.descripcionLarga,
            documento: sesion.valorDocumento,
            examen: sesion.valorExamen,
            nombreDocumento: sesion.nombreDocumento,
            nombreExamen: sesion.nombreExamen,
            nombreFoto: sesion.nombreFoto,
            fechaAlta: sesion.fechaAlta,
            videos: sesion.videos
                .map((video) => {
                    return {
                        idVideo: video.idVideo,
                        nombre: video.nombreLargoVideo,
                        duracionVideo: video.duracionVideo.toString(),
                        valorVideo: video.valorVideo,
                    };
                })
                .filter((vid) => vid.idVideo !== VACIO),
        };
    };

    const generateDataRequestSesion = (sesion): NewSesion => {
        const urlFoto = PHOTO_SESION_URL.replace(
            REPLACE_IDCURSO,
            generalData.idCurso,
        );
        const urlDoc = DOCUMENTO_SESION_URL.replace(
            REPLACE_IDCURSO,
            generalData.idCurso,
        );

        return {
            idSesion: sesion.idSesion,
            foto:
                sesion.blobFoto !== VACIO
                    ? urlFoto.replace(REPLACE_NOMBREFOTO, sesion.nombreFoto)
                    : sesion.valorFoto,
            nombre: sesion.titulo,
            descripcionCorta: sesion.descripcionCorta,
            descripcionLarga: sesion.descripcionLarga,
            documento:
                sesion.valorDocumento !== VACIO &&
                typeof sesion.valorDocumento !== 'string'
                    ? urlDoc.replace(
                          REPLACE_NOMBREDOCUMENTO,
                          sesion.nombreDocumento,
                      )
                    : sesion.valorDocumento,
            examen: sesion.valorExamen,
            nombreDocumento: sesion.nombreDocumento,
            nombreExamen: sesion.nombreExamen,
            nombreFoto: sesion.nombreFoto,
            fechaAlta: sesion.fechaAlta,
            videos: sesion.videos.map((video) => {
                return {
                    idVideo: video.idVideo,
                    nombre: video.nombreLargoVideo,
                    duracionVideo: video.duracionVideo.toString(),
                    valorVideo: `${VIDEO_SESION_URL.replace(
                        REPLACE_IDCURSO,
                        generalData.idCurso,
                    ).replace(REPLACE_NOMBREVIDEO, video.nombreLargoVideo)}`,
                };
            }),
        };
    };

    const saveSesion = (numMod: number, numSes: number) => {
        const modulo = modulosData.modulos[numMod];
        const sesion = modulo.sesiones[numSes];
        const requestSesion = generateDataRequestSesion(sesion);

        setOpenSpinner(true);
        setSesionModule({
            variables: {
                idCurso: generalData.idCurso,
                idModulo: modulo.idModulo,
                data: requestSesion,
            },
        })
            .then((response) => {
                const storagePromise: any[] = [];
                const videosPromise: any[] = [];

                const idSesionResponse = response.data.asignarSesion.idSesion;

                if (sesion.blobFoto !== VACIO) {
                    const fileRef = ref(
                        storage,
                        `/cursos/${generalData.idCurso}/${modulo.idModulo}/${idSesionResponse}/${sesion.nombreFoto}`,
                    );
                    storagePromise.push(uploadBytes(fileRef, sesion.blobFoto));
                }

                if (
                    sesion.valorDocumento !== VACIO &&
                    typeof sesion.valorDocumento !== 'string'
                ) {
                    const fileRef = ref(
                        storage,
                        `/cursos/${generalData.idCurso}/${modulo.idModulo}/${idSesionResponse}/${sesion.nombreDocumento}`,
                    );
                    storagePromise.push(
                        uploadBytes(fileRef, sesion.valorDocumento),
                    );
                }

                sesion.videos.forEach((video: NewVideo) => {
                    if (
                        video.valorVideo !== VACIO &&
                        typeof video.valorVideo !== 'string'
                    ) {
                        videosPromise.push({
                            child: `/cursos/${generalData.idCurso}/${modulo.idModulo}/${idSesionResponse}/${video.nombreLargoVideo}`,
                            valorVideo: video.valorVideo,
                        });
                    }
                });

                return Promise.all([
                    storagePromise,
                    videosPromise,
                    response.data.asignarSesion,
                ]);
            })
            .then((data: any[]) => {
                const promVideos =
                    data[1].length === 0
                        ? []
                        : data[1].map((video: any) => {
                              const fileRef = ref(storage, video.child);
                              return uploadBytes(fileRef, video.valorVideo);
                          });
                return Promise.all([data[2], ...promVideos]);
            })
            .then((data: any[]) => {
                return new Promise<ModulosCursos[]>((resolve, reject) => {
                    try {
                        const response = data[0];

                        setTimeout(() => {
                            const newData = modulosData.modulos.map((mod) =>
                                mod.idModulo !== modulo.idModulo
                                    ? mod
                                    : {
                                          ...mod,
                                          sesiones: mod.sesiones.map((ses) =>
                                              ses.titulo === response.nombre
                                                  ? {
                                                        ...ses,
                                                        fechaAlta:
                                                            response.fechaAlta,
                                                        idSesion:
                                                            response.idSesion,
                                                        valorFoto:
                                                            response.foto,
                                                        valorDocumento:
                                                            response.documento,
                                                        visible: false,
                                                        videos: ses.videos.map(
                                                            (video) => {
                                                                const vid =
                                                                    response.videos.find(
                                                                        (
                                                                            item,
                                                                        ) =>
                                                                            item.nombre ===
                                                                            video.nombreLargoVideo,
                                                                    );

                                                                return {
                                                                    ...video,
                                                                    idVideo:
                                                                        vid.idVideo,
                                                                    valorVideo:
                                                                        vid.valorVideo,
                                                                };
                                                            },
                                                        ),
                                                    }
                                                  : ses,
                                          ),
                                      },
                            );

                            resolve(newData);
                        }, 1000);
                    } catch (error) {
                        reject(error);
                    }
                });
            })
            .then((data: ModulosCursos[]) => {
                setModulosData({ ...modulosData, modulos: data });
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al crear sesión`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const saveAdmins = () => {
        const dataConfig = {
            idCurso: generalData.idCurso,
            administradores: adminsCourse.map((admin) => ({
                idAdministrador: admin.idAdministrador,
                permisos: admin.permisos,
            })),
        };

        setOpenSpinner(true);
        setConfigAdmin({ variables: { data: dataConfig } })
            .then(() => {
                setAdministradoresControl({
                    ...administradoresControl,
                    expanded: false,
                    complete: true,
                });
                setUsuariosControl({ ...usuariosControl, expanded: true });

                if (!administradoresControl.complete) {
                    setProgress(progress + 25);
                }

                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al asignar administradores`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const saveUsers = () => {
        const dataUsers: SetUsuarioCurso = {
            idCurso: generalData.idCurso,
            usuarios: usersCourse.map(({ idUsuario, checked }) => ({
                idUsuario,
                checked,
            })),
        };

        setOpenSpinner(true);
        setConfigUser({ variables: { data: dataUsers } })
            .then(() => {
                setGeneralData({ ...generalData, expanded: false });
                setModulosData({ ...modulosData, expanded: false });
                setAdministradoresControl({
                    ...administradoresControl,
                    expanded: false,
                });
                setUsuariosControl({
                    ...usuariosControl,
                    expanded: false,
                    complete: true,
                });

                if (!usuariosControl.complete) {
                    setProgress(progress + 25);
                }
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al asignar usuarios`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const onChangeGeneralForm =
        (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (generalData.complete) {
                setGeneralData({ ...generalData, complete: false });
            }

            setGeneralData({ ...generalData, [prop]: event.target.value });
        };

    const onChangeModulosForm =
        (prop: string, numModulo: number) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.map((mod, index) =>
                    index === numModulo
                        ? { ...mod, [prop]: event.target.value }
                        : mod,
                ),
            });
        };

    const onChangeSesionesForm =
        (prop: keyof SesionesCursos, numModulo: number, numSesion: number) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.map((mod, ind) =>
                    ind === numModulo
                        ? {
                              ...mod,
                              sesiones: mod.sesiones.map((ses, posS) =>
                                  posS === numSesion
                                      ? {
                                            ...ses,
                                            [prop]: event.target.value,
                                        }
                                      : ses,
                              ),
                          }
                        : mod,
                ),
            });
        };

    const uploadImg = (event) => {
        const file = event.target.files[0];
        const name = event.target.files[0].name;
        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = () => {
            setGeneralData({
                ...generalData,
                valorFoto: fileReader.result,
                nombreFoto: name,
                blobFoto: file,
            });

            event.target.value = null;
        };

        fileReader.readAsDataURL(file);
    };

    const uploadImgModulo = (event) => {
        const file = event.target.files[0];
        const fileReader: FileReader = new FileReader();
        const name = event.target.files[0].name;

        fileReader.onloadend = () => {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.map((mod, index) =>
                    index === posModuloImg
                        ? {
                              ...mod,
                              valorFoto: fileReader.result,
                              nombreFoto: name,
                              blobFoto: file,
                          }
                        : mod,
                ),
            });

            event.target.value = null;
        };

        fileReader.readAsDataURL(file);
    };

    const uploadImgSesion = (event, posMod, posSes) => {
        const file = event.target.files[0];
        const name = event.target.files[0].name;
        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = () => {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.map((mod, index) =>
                    index !== posMod
                        ? mod
                        : {
                              ...mod,
                              sesiones: mod.sesiones.map((ses, pos) =>
                                  pos !== posSes
                                      ? ses
                                      : {
                                            ...ses,
                                            nombreFoto: name,
                                            valorFoto: fileReader.result,
                                            blobFoto: file,
                                        },
                              ),
                          },
                ),
            });

            event.target.value = null;
        };

        fileReader.readAsDataURL(file);
    };

    const uploadDocSesion = (event, posMod, posSes) => {
        const file = event.target.files[0];
        const name = event.target.files[0].name;
        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = (ev: any) => {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.map((mod, index) =>
                    index !== posMod
                        ? mod
                        : {
                              ...mod,
                              sesiones: mod.sesiones.map((ses, pos) =>
                                  pos !== posSes
                                      ? ses
                                      : {
                                            ...ses,
                                            valorDocumento: file,
                                            nombreDocumento: name,
                                        },
                              ),
                          },
                ),
            });

            event.target.value = null;
        };

        fileReader.readAsBinaryString(file);
    };

    const uploadExamenSesion = (event, posMod, posSes) => {
        const file = event.target.files[0];
        const name = event.target.files[0].name;

        if (file) {
            const fileReader: FileReader = new FileReader();

            fileReader.onload = (ev: any) => {
                const workbook: XLSX.WorkBook = XLSX.read(ev.target.result, {
                    type: 'binary',
                });

                const firstSheet: string = workbook.SheetNames[0];
                const excelRows: any = workbook.Sheets[firstSheet];
                const data: any[] = XLSX.utils.sheet_to_json(excelRows, {
                    header: 1,
                });

                const examen = data.reduce(
                    (init, data: string[], pos) =>
                        pos !== 0
                            ? [
                                  ...init,
                                  data.reduce(
                                      (initial: any, current, index) =>
                                          index === 0
                                              ? {
                                                    ...initial,
                                                    pregunta:
                                                        current.toString(),
                                                }
                                              : index === 1
                                                ? {
                                                      ...initial,
                                                      respuestas: [
                                                          ...initial.respuestas,
                                                          {
                                                              respuesta:
                                                                  current.toString(),
                                                              correcta: true,
                                                          },
                                                      ],
                                                  }
                                                : {
                                                      ...initial,
                                                      respuestas: [
                                                          ...initial.respuestas,
                                                          {
                                                              respuesta:
                                                                  current.toString(),
                                                              correcta: false,
                                                          },
                                                      ],
                                                  },
                                      { pregunta: '', respuestas: [] },
                                  ),
                              ]
                            : init,
                    [],
                );

                setModulosData({
                    ...modulosData,
                    modulos: modulosData.modulos.map((mod, index) =>
                        index !== posMod
                            ? mod
                            : {
                                  ...mod,
                                  sesiones: mod.sesiones.map((ses, pos) =>
                                      pos !== posSes
                                          ? ses
                                          : {
                                                ...ses,
                                                valorExamen: examen,
                                                nombreExamen: name,
                                            },
                                  ),
                              },
                    ),
                });

                event.target.value = null;
            };

            fileReader.readAsBinaryString(file);
        }
    };

    const createNewModule = () => {
        setModulosData({
            ...modulosData,
            modulos: [...modulosData.modulos, initNewModulo],
        });
    };

    const createNewSesion = (posMod) => {
        setModulosData({
            ...modulosData,
            modulos: modulosData.modulos.map((mod, indx) =>
                indx === posMod
                    ? {
                          ...mod,
                          sesiones: [...mod.sesiones, initNewSesion],
                      }
                    : mod,
            ),
        });
    };

    const handleDeleteChipVideo = (
        modI: number,
        sesI: number,
        vidI: number,
        video: NewVideo,
        module: ModulosCursos,
        sesion: SesionesCursos,
    ) => {
        if (video.idVideo !== VACIO) {
            setOpenSpinner(true);
            deleteVideo({
                variables: {
                    idCurso: generalData.idCurso,
                    idModulo: module.idModulo,
                    idSesion: sesion.idSesion,
                    idVideo: video.idVideo,
                },
            })
                .then(() => {
                    filterVideo(modI, sesI, vidI);
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(`Error al eliminar video`, STATUS.error);
                    setOpenSpinner(false);
                });
        } else {
            filterVideo(modI, sesI, vidI);
        }
    };

    const filterVideo = (modI: number, sesI: number, vidI: number) => {
        setModulosData({
            ...modulosData,
            modulos: modulosData.modulos.map((mod, index) =>
                index !== modI
                    ? mod
                    : {
                          ...mod,
                          sesiones: mod.sesiones.map((ses, pos) =>
                              pos !== sesI
                                  ? ses
                                  : {
                                        ...ses,
                                        videos: ses.videos.filter(
                                            (vid, posI) => posI !== vidI,
                                        ),
                                    },
                          ),
                      },
            ),
        });
    };

    const handleAddVideo = (event, posMod, posSes) => {
        const file = event.target.files[0];
        const name = event.target.files[0].name;
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = (ev: any) => {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.map((mod, index) =>
                    index !== posMod
                        ? mod
                        : {
                              ...mod,
                              sesiones: mod.sesiones.map((ses, pos) =>
                                  pos !== posSes
                                      ? ses
                                      : {
                                            ...ses,
                                            videos: [
                                                ...ses.videos,
                                                {
                                                    idVideo: VACIO,
                                                    nombreLargoVideo: name,
                                                    nombreCortoVideo: `${name.substring(
                                                        0,
                                                        8,
                                                    )}...`,
                                                    valorVideo: file,
                                                    duracionVideo: 0,
                                                },
                                            ],
                                        },
                              ),
                          },
                ),
            });

            event.target.value = null;
        };

        fileReader.readAsBinaryString(file);
    };

    const handleDeleteModule = (modI) => {
        const modulo: any = modulosData.modulos.find(
            (mod, pos) => pos === modI,
        );

        if (modulo.idModulo === undefined || modulo.idModulo === VACIO) {
            setModulosData({
                ...modulosData,
                modulos: modulosData.modulos.filter((mod, pos) => pos !== modI),
            });
        } else {
            setOpenSpinner(true);
            deleteModule({
                variables: {
                    idCurso: generalData.idCurso,
                    idModulo: modulo.idModulo,
                },
            })
                .then(() => {
                    setModulosData({
                        ...modulosData,
                        modulos: modulosData.modulos.filter(
                            (mod, pos) => pos !== modI,
                        ),
                    });
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(`Error al eliminar modulo`, STATUS.error);
                    setOpenSpinner(false);
                });
        }
    };

    const sesionVisible = (modulo: ModulosCursos, sesion: SesionesCursos) => {
        setModulosData({
            ...modulosData,
            modulos: modulosData.modulos.map((mod) =>
                mod.idModulo === modulo.idModulo
                    ? {
                          ...mod,
                          sesiones: mod.sesiones.map((ses) =>
                              ses.idSesion !== sesion.idSesion
                                  ? ses
                                  : {
                                        ...ses,
                                        visible: !sesion.visible,
                                    },
                          ),
                      }
                    : mod,
            ),
        });
    };

    const filterSesions = (
        modulo: ModulosCursos,
        sesion: SesionesCursos,
        posSesion: number,
    ) => {
        setModulosData({
            ...modulosData,
            modulos: modulosData.modulos.map((mod) =>
                mod.idModulo === modulo.idModulo
                    ? {
                          ...mod,
                          sesiones: mod.sesiones.filter(
                              (ses, ind) =>
                                  (ses.idSesion !== sesion.idSesion &&
                                      sesion.idSesion !== VACIO) ||
                                  ind !== posSesion,
                          ),
                      }
                    : mod,
            ),
        });
    };

    const eliminarSesion = (
        modulo: ModulosCursos,
        sesion: SesionesCursos,
        posSesion: number,
    ) => {
        if (sesion.idSesion === VACIO) {
            filterSesions(modulo, sesion, posSesion);
        } else {
            setOpenSpinner(true);
            deleteSesion({
                variables: {
                    idCurso: generalData.idCurso,
                    idModulo: modulo.idModulo,
                    idSesion: sesion.idSesion,
                },
            })
                .then(() => {
                    filterSesions(modulo, sesion, posSesion);
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(`Error al eliminar sesión`, STATUS.error);
                    setOpenSpinner(false);
                });
        }
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const continuar = () => {
        const modulos = modulosData.modulos.filter(
            (mod) => mod.idModulo !== VACIO,
        );
        const cantMod = modulos.length;
        const cantSes = modulos.reduce(
            (init, current) =>
                (init += current.sesiones.filter(
                    (ses) => ses.idSesion !== VACIO,
                ).length),
            0,
        );

        if (cantMod > 0 && cantSes > 0) {
            setModulosData({ ...modulosData, expanded: false, complete: true });
            setAdministradoresControl({
                ...administradoresControl,
                expanded: true,
            });

            if (!modulosData.complete) {
                setProgress(progress + 25);
            }
        } else {
            openSnackBar(
                `Por favor configura mínimo 1 módulo y 1 sesión`,
                STATUS.info,
            );
        }
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    if (queryCourses.loading) {
        return (
            <Spinner
                class={
                    queryCourses.loading
                        ? 'displaySpinner'
                        : 'displayNoneSpinner'
                }
            />
        );
    }

    return (
        <div className={classes.root}>
            <Regresar route='/ccap/admin/cursos/lista' />

            <Typography
                className={classes.title}
                variant='h6'
                id='titleNewCourse'
                component='div'
            >
                {message}
            </Typography>

            <LinearProgressComp progress={progress} />

            <Accordion expanded={generalData.expanded}>
                <AccordionSummary
                    onClick={() =>
                        setGeneralData({
                            ...generalData,
                            expanded: !generalData.expanded,
                        })
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1c-content'
                    id='panel1c-header'
                >
                    <CheckCircleIcon
                        style={colorDone}
                        className={
                            generalData.complete
                                ? classes.display
                                : classes.displayNone
                        }
                    />
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                            <b>Información general</b>
                        </Typography>
                    </div>
                </AccordionSummary>

                <Divider />

                <AccordionDetails className={classes.details}>
                    <div className='AccordionDetailsParent'>
                        <div className='AccordionDetailsChild containerimgCur'>
                            <Avatar
                                alt='Foto'
                                src={generalData.valorFoto}
                                className={classes.largePhoto}
                            />

                            <input
                                accept='image/*'
                                className={generalClassses.fotoInput}
                                id='foto'
                                onChange={uploadImg}
                                type='file'
                            />

                            <label htmlFor='foto'>
                                <Button component='span'>
                                    <PublishIcon /> Cargar
                                </Button>
                            </label>
                        </div>

                        <div className='AccordionDetailsChild div-child AccordionDetailsChildSecond'>
                            <TextField
                                required
                                id='nombreCurso'
                                label='Nombre de curso'
                                variant='outlined'
                                value={generalData.nombreCurso}
                                onChange={onChangeGeneralForm('nombreCurso')}
                                className={generalClassses.formControl}
                            />
                            <TextField
                                required
                                id='descripcionCorta'
                                label='Descripción'
                                variant='outlined'
                                value={generalData.descripcionCorta}
                                onChange={onChangeGeneralForm(
                                    'descripcionCorta',
                                )}
                                className={generalClassses.formControl}
                            />
                            <FormControl
                                required
                                id='tipe'
                                variant='outlined'
                                className={generalClassses.formControl}
                            >
                                <InputLabel id='demo-simple-select-outlined-label'>
                                    Tipo
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-outlined-label'
                                    id='demo-simple-select-outlined'
                                    value={generalData.tipo}
                                    onChange={handleChangeBuscador}
                                    label='Opción'
                                >
                                    <MenuItem
                                        key='ac'
                                        className={generalClassses.menuItem}
                                        value='AC'
                                    >
                                        Aire acondicionado
                                    </MenuItem>
                                    <MenuItem
                                        key='vent'
                                        className={generalClassses.menuItem}
                                        value='VENT'
                                    >
                                        Ventilación
                                    </MenuItem>
                                    <MenuItem
                                        key='cal'
                                        className={generalClassses.menuItem}
                                        value='CAL'
                                    >
                                        Calefacción
                                    </MenuItem>
                                    <MenuItem
                                        key='ref'
                                        className={generalClassses.menuItem}
                                        value='REF'
                                    >
                                        Refrigeración
                                    </MenuItem>
                                    <MenuItem
                                        key='otro'
                                        className={generalClassses.menuItem}
                                        value='Otro'
                                    >
                                        Otro
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className='AccordionDetailsChild div-child'>
                            <TextField
                                required
                                className={classes.textArea}
                                id='descripcionLarga'
                                label='Descripción larga'
                                multiline
                                value={generalData.descripcionLarga}
                                onChange={onChangeGeneralForm(
                                    'descripcionLarga',
                                )}
                                minRows={8}
                                variant='outlined'
                            />
                        </div>
                    </div>
                </AccordionDetails>

                <AccordionActions>
                    <Button
                        disabled={
                            generalData.descripcionCorta === VACIO ||
                            generalData.descripcionLarga === VACIO ||
                            generalData.nombreCurso === VACIO ||
                            generalData.tipo === VACIO ||
                            generalData.valorFoto === VACIO
                        }
                        onClick={saveGenerals}
                        size='small'
                        color='primary'
                    >
                        GUARDAR
                    </Button>
                </AccordionActions>
            </Accordion>

            <Accordion
                expanded={modulosData.expanded}
                disabled={modulosData.disabled}
            >
                <AccordionSummary
                    onClick={() =>
                        setModulosData({
                            ...modulosData,
                            expanded: !modulosData.expanded,
                        })
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panelModulos-content'
                    id='panelModulos-header'
                >
                    <CheckCircleIcon
                        style={colorDone}
                        className={
                            modulosData.complete
                                ? classes.display
                                : classes.displayNone
                        }
                    />
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                            <b>Modulos y contenido</b>
                        </Typography>
                    </div>
                </AccordionSummary>

                <Divider />

                <div className='btnAddModulo'>
                    <Button
                        color='primary'
                        className={classes.btnAddMod}
                        onClick={createNewModule}
                    >
                        <AddCircleOutlineIcon className={classes.iconAddMod} />
                        Agregar nuevo módulo
                    </Button>
                </div>
                {modulosData.modulos.map((mod, index) => (
                    <AccordionDetails
                        key={index}
                        className={clsx(
                            classes.details,
                            classes.detailsModulos,
                        )}
                    >
                        <div
                            className={
                                index !== 0
                                    ? 'containerModule'
                                    : 'containerModuleWithAllPadding'
                            }
                        >
                            <Typography className={classes.heading}>{`Modulo ${
                                index + 1
                            }`}</Typography>
                            <div
                                className={
                                    index !== 0
                                        ? 'divIconsModulos'
                                        : 'divIconsModulosDisplayNone'
                                }
                            >
                                <Tooltip title='Eliminar módulo'>
                                    <IconButton
                                        aria-label='delete'
                                        onClick={() =>
                                            handleDeleteModule(index)
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='AccordionModulosParent'>
                            <div className='AccordionModulosChildTitle'>
                                <div className='containerimgMod'>
                                    <Avatar
                                        alt='Foto'
                                        src={mod.valorFoto}
                                        className={classes.largePhoto}
                                    />
                                    <input
                                        id={`fotoModulo${index}`}
                                        accept='image/*'
                                        className={generalClassses.fotoInput}
                                        onChange={uploadImgModulo}
                                        type='file'
                                    />

                                    <label htmlFor={`fotoModulo${index}`}>
                                        <Button
                                            onClick={() =>
                                                setPosModuloImg(index)
                                            }
                                            component='span'
                                        >
                                            <PublishIcon />
                                            Cargar
                                        </Button>
                                    </label>
                                </div>

                                <div className='titleModule'>
                                    <div className='titleForm'>
                                        <Typography className={classes.heading}>
                                            Información general:
                                        </Typography>
                                    </div>

                                    <div className='formTitle'>
                                        <div className='formTitleModuleChild'>
                                            <TextField
                                                required
                                                id={`nombreModulo${index}`}
                                                label='Nombre del modulo'
                                                variant='outlined'
                                                value={mod.nombreModulo}
                                                onChange={onChangeModulosForm(
                                                    'nombreModulo',
                                                    index,
                                                )}
                                                className={clsx(
                                                    generalClassses.formControl,
                                                    classes.formControlLeft,
                                                )}
                                            />
                                        </div>

                                        <div className='formTitleModuleChild'>
                                            <TextField
                                                required
                                                id={`descripcionCortaModulo${index}`}
                                                label='Descripción'
                                                variant='outlined'
                                                value={mod.descripcionCorta}
                                                onChange={onChangeModulosForm(
                                                    'descripcionCorta',
                                                    index,
                                                )}
                                                className={clsx(
                                                    generalClassses.formControl,
                                                    classes.formControlRigth,
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className='formTitleBtn'>
                                        <Button
                                            disabled={
                                                mod.nombreModulo === VACIO ||
                                                mod.descripcionCorta ===
                                                    VACIO ||
                                                mod.valorFoto === VACIO
                                            }
                                            onClick={saveModule}
                                        >
                                            GUARDAR MODULO Y CONFIGURAR SESIONES
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            {mod.idModulo !== VACIO ? (
                                <div className='AccordionModulosChild'>
                                    <div className='titleModule titleSesion'>
                                        <Typography className={classes.heading}>
                                            Sesiones:
                                        </Typography>
                                        <Button
                                            size='small'
                                            color='primary'
                                            className={classes.btnAddMod}
                                            onClick={() =>
                                                createNewSesion(index)
                                            }
                                        >
                                            <AddCircleOutlineIcon
                                                className={classes.iconAddMod}
                                            />
                                            Agregar nueva sesión
                                        </Button>
                                    </div>

                                    <div className='containerSesionChildDetail'>
                                        {mod.sesiones.map((sesion, posSes) => (
                                            <div
                                                key={posSes}
                                                className={
                                                    mod.sesiones.length > 1 &&
                                                    posSes !==
                                                        mod.sesiones.length - 1
                                                        ? `moduleSesionContainer moduleSesionContainerMarginBottom`
                                                        : `moduleSesionContainer`
                                                }
                                            >
                                                <div className='AccordionDetailsSesion'>
                                                    <div className='actionsSesion'>
                                                        <Avatar
                                                            src={
                                                                sesion.valorFoto !==
                                                                VACIO
                                                                    ? sesion.valorFoto
                                                                    : VACIO
                                                            }
                                                            color='secondary'
                                                            aria-label='recipe'
                                                            className={
                                                                classes.avatar
                                                            }
                                                        >
                                                            {posSes + 1}
                                                        </Avatar>
                                                        <div
                                                            className={
                                                                posSes !== 0
                                                                    ? 'btnBorrarSesion2'
                                                                    : 'divIconsModulosDisplayNone'
                                                            }
                                                        >
                                                            <IconButton
                                                                color='primary'
                                                                aria-label='delete sesion'
                                                                component='span'
                                                                onClick={() =>
                                                                    eliminarSesion(
                                                                        mod,
                                                                        sesion,
                                                                        posSes,
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    {sesion.idSesion !== '' &&
                                                    !sesion.visible ? (
                                                        <div>
                                                            <div
                                                                className={
                                                                    classes.divSuccess
                                                                }
                                                            >
                                                                <DoneAllIcon />
                                                                <Typography
                                                                    className={
                                                                        classes.typografySuccess
                                                                    }
                                                                >
                                                                    <b>
                                                                        Guardada
                                                                    </b>
                                                                </Typography>
                                                            </div>
                                                            <Typography>
                                                                <b>
                                                                    Nombre de la
                                                                    sesión:
                                                                </b>
                                                                {` ${sesion.titulo}`}
                                                            </Typography>
                                                            <Typography>
                                                                <b>
                                                                    Descripción:
                                                                </b>
                                                                {` ${sesion.descripcionCorta}`}
                                                            </Typography>
                                                            <Typography>
                                                                <b>
                                                                    Descripción
                                                                    larga:
                                                                </b>
                                                                {` ${sesion.descripcionLarga}`}
                                                            </Typography>
                                                            <Typography>
                                                                <b>Imagen:</b>
                                                                {` ${sesion.nombreFoto}`}
                                                            </Typography>
                                                            <Typography>
                                                                <b>
                                                                    Documento:
                                                                </b>
                                                                {` ${sesion.nombreDocumento}`}
                                                            </Typography>
                                                            <Typography>
                                                                <b>Examen:</b>
                                                                {` ${sesion.nombreExamen}`}
                                                            </Typography>
                                                            <Typography>
                                                                <b>Videos:</b>
                                                                {` ${sesion.videos.length}`}
                                                            </Typography>
                                                            <div className='divEditVideos'>
                                                                <IconButton
                                                                    color='primary'
                                                                    aria-label='edit sesion'
                                                                    component='span'
                                                                    onClick={() =>
                                                                        sesionVisible(
                                                                            mod,
                                                                            sesion,
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <TextField
                                                                required
                                                                id={`nombreSesion${index}${posSes}`}
                                                                label='Nombre de la sesión'
                                                                variant='outlined'
                                                                value={
                                                                    sesion.titulo
                                                                }
                                                                onChange={onChangeSesionesForm(
                                                                    'titulo',
                                                                    index,
                                                                    posSes,
                                                                )}
                                                                className={
                                                                    generalClassses.formControl
                                                                }
                                                            />

                                                            <TextField
                                                                required
                                                                id={`descripcionCortaSesion${index}${posSes}`}
                                                                label='Descripción corta'
                                                                variant='outlined'
                                                                value={
                                                                    sesion.descripcionCorta
                                                                }
                                                                onChange={onChangeSesionesForm(
                                                                    'descripcionCorta',
                                                                    index,
                                                                    posSes,
                                                                )}
                                                                className={
                                                                    generalClassses.formControl
                                                                }
                                                            />

                                                            <TextField
                                                                required
                                                                className={
                                                                    classes.textArea
                                                                }
                                                                id={`descripcionLargaSesion${index}${posSes}`}
                                                                label='Descripción larga'
                                                                multiline
                                                                value={
                                                                    sesion.descripcionLarga
                                                                }
                                                                onChange={onChangeSesionesForm(
                                                                    'descripcionLarga',
                                                                    index,
                                                                    posSes,
                                                                )}
                                                                minRows={8}
                                                                variant='outlined'
                                                            />

                                                            <input
                                                                id={`fotoSesion-${index}-${posSes}`}
                                                                accept='image/*'
                                                                className={
                                                                    generalClassses.fotoInput
                                                                }
                                                                onChange={(e) =>
                                                                    uploadImgSesion(
                                                                        e,
                                                                        index,
                                                                        posSes,
                                                                    )
                                                                }
                                                                type='file'
                                                            />

                                                            <FormControl
                                                                required
                                                                disabled
                                                                variant='outlined'
                                                                className={
                                                                    generalClassses.formControl
                                                                }
                                                            >
                                                                <InputLabel
                                                                    htmlFor={`nombreFoto-${index}-${posSes}`}
                                                                >
                                                                    Imagen
                                                                </InputLabel>
                                                                <OutlinedInput
                                                                    id={`nombreFoto-${index}-${posSes}`}
                                                                    type='text'
                                                                    value={
                                                                        sesion.nombreFoto
                                                                    }
                                                                    endAdornment={
                                                                        <InputAdornment position='start'>
                                                                            <label
                                                                                htmlFor={`fotoSesion-${index}-${posSes}`}
                                                                            >
                                                                                <IconButton
                                                                                    component='span'
                                                                                    aria-label='nombreFoto'
                                                                                    color='primary'
                                                                                    edge='end'
                                                                                >
                                                                                    <PublishIcon color='primary' />
                                                                                </IconButton>
                                                                            </label>
                                                                        </InputAdornment>
                                                                    }
                                                                    labelWidth={
                                                                        54
                                                                    }
                                                                />
                                                            </FormControl>

                                                            <input
                                                                id={`documentoSesion-${index}-${posSes}`}
                                                                accept='.pdf'
                                                                className={
                                                                    generalClassses.fotoInput
                                                                }
                                                                onChange={(e) =>
                                                                    uploadDocSesion(
                                                                        e,
                                                                        index,
                                                                        posSes,
                                                                    )
                                                                }
                                                                type='file'
                                                            />

                                                            <FormControl
                                                                required
                                                                disabled
                                                                variant='outlined'
                                                                className={
                                                                    generalClassses.formControl
                                                                }
                                                            >
                                                                <InputLabel
                                                                    htmlFor={`nombreDocumento-${index}-${posSes}`}
                                                                >
                                                                    Documento
                                                                </InputLabel>
                                                                <OutlinedInput
                                                                    id={`nombreDocumento-${index}-${posSes}`}
                                                                    type='text'
                                                                    value={
                                                                        sesion.nombreDocumento
                                                                    }
                                                                    endAdornment={
                                                                        <InputAdornment position='start'>
                                                                            <label
                                                                                htmlFor={`documentoSesion-${index}-${posSes}`}
                                                                            >
                                                                                <IconButton
                                                                                    // onClick={() => setFocusSesion(index)}
                                                                                    component='span'
                                                                                    aria-label='nombreDocumento'
                                                                                    color='primary'
                                                                                    edge='end'
                                                                                >
                                                                                    <PublishIcon color='primary' />
                                                                                </IconButton>
                                                                            </label>
                                                                        </InputAdornment>
                                                                    }
                                                                    labelWidth={
                                                                        82
                                                                    }
                                                                />
                                                            </FormControl>

                                                            <input
                                                                id={`examenSesion-${index}-${posSes}`}
                                                                accept='.xlsx,.xls'
                                                                className={
                                                                    generalClassses.fotoInput
                                                                }
                                                                onChange={(e) =>
                                                                    uploadExamenSesion(
                                                                        e,
                                                                        index,
                                                                        posSes,
                                                                    )
                                                                }
                                                                type='file'
                                                            />

                                                            <FormControl
                                                                required
                                                                disabled
                                                                variant='outlined'
                                                                className={
                                                                    generalClassses.formControl
                                                                }
                                                            >
                                                                <InputLabel
                                                                    htmlFor={`nombreExamen-${index}-${posSes}`}
                                                                >
                                                                    Examen
                                                                </InputLabel>
                                                                <OutlinedInput
                                                                    id={`nombreExamen-${index}-${posSes}`}
                                                                    type='text'
                                                                    value={
                                                                        sesion.nombreExamen
                                                                    }
                                                                    endAdornment={
                                                                        <InputAdornment position='start'>
                                                                            <label
                                                                                htmlFor={`examenSesion-${index}-${posSes}`}
                                                                            >
                                                                                <IconButton
                                                                                    component='span'
                                                                                    aria-label='nombreExamen'
                                                                                    color='primary'
                                                                                    edge='end'
                                                                                >
                                                                                    <PublishIcon color='primary' />
                                                                                </IconButton>
                                                                            </label>
                                                                        </InputAdornment>
                                                                    }
                                                                    labelWidth={
                                                                        58
                                                                    }
                                                                />
                                                            </FormControl>

                                                            <div className='divVideos'>
                                                                <div className='lblVideos'>
                                                                    <input
                                                                        id={`videoSesion-${index}-${posSes}`}
                                                                        accept='video/*'
                                                                        className={
                                                                            generalClassses.fotoInput
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) =>
                                                                            handleAddVideo(
                                                                                e,
                                                                                index,
                                                                                posSes,
                                                                            )
                                                                        }
                                                                        type='file'
                                                                    />

                                                                    <label
                                                                        htmlFor={`videoSesion-${index}-${posSes}`}
                                                                    >
                                                                        <Button
                                                                            color='secondary'
                                                                            variant='contained'
                                                                            disableElevation
                                                                            component='span'
                                                                            className={
                                                                                classes.fabVideo
                                                                            }
                                                                        >
                                                                            <AddIcon />
                                                                            AGREGAR
                                                                            VIDEO
                                                                        </Button>
                                                                    </label>
                                                                </div>

                                                                <div>
                                                                    {sesion.videos.map(
                                                                        (
                                                                            video,
                                                                            indV,
                                                                        ) => (
                                                                            <Tooltip
                                                                                key={
                                                                                    indV
                                                                                }
                                                                                title={
                                                                                    video.nombreLargoVideo
                                                                                }
                                                                            >
                                                                                <Chip
                                                                                    label={
                                                                                        video.nombreCortoVideo
                                                                                    }
                                                                                    className={
                                                                                        classes.chipVideo
                                                                                    }
                                                                                    onDelete={() =>
                                                                                        handleDeleteChipVideo(
                                                                                            index,
                                                                                            posSes,
                                                                                            indV,
                                                                                            video,
                                                                                            mod,
                                                                                            sesion,
                                                                                        )
                                                                                    }
                                                                                    color='secondary'
                                                                                />
                                                                            </Tooltip>
                                                                        ),
                                                                    )}
                                                                </div>

                                                                <div className='btnSaveSesion'>
                                                                    <Button
                                                                        disabled={
                                                                            sesion.titulo ===
                                                                                VACIO ||
                                                                            sesion.descripcionCorta ===
                                                                                VACIO ||
                                                                            sesion.descripcionLarga ===
                                                                                VACIO ||
                                                                            sesion.valorDocumento ===
                                                                                VACIO ||
                                                                            sesion.valorDocumento ===
                                                                                VACIO ||
                                                                            sesion
                                                                                .valorExamen
                                                                                .length ===
                                                                                0 ||
                                                                            sesion
                                                                                .videos
                                                                                .length ===
                                                                                0
                                                                        }
                                                                        onClick={() =>
                                                                            saveSesion(
                                                                                index,
                                                                                posSes,
                                                                            )
                                                                        }
                                                                    >
                                                                        GUARDAR
                                                                        SESIÓN
                                                                    </Button>
                                                                </div>

                                                                <div
                                                                    className={
                                                                        sesion.idSesion !==
                                                                            '' &&
                                                                        sesion.visible
                                                                            ? 'btnSaveSesion'
                                                                            : 'divIconsModulosDisplayNone'
                                                                    }
                                                                >
                                                                    <Button
                                                                        onClick={() =>
                                                                            sesionVisible(
                                                                                mod,
                                                                                sesion,
                                                                            )
                                                                        }
                                                                    >
                                                                        REGRESAR
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <Divider />
                    </AccordionDetails>
                ))}

                <AccordionActions>
                    <Button onClick={continuar} size='small' color='primary'>
                        CONTINUAR
                    </Button>
                </AccordionActions>
            </Accordion>

            <Accordion
                expanded={
                    user.tipo === ROLES_USUARIOS.SADM
                        ? administradoresControl.expanded
                        : user.cursos && user.cursos !== null
                          ? user.cursos.find(
                                (cur) => cur.idCurso === generalData.idCurso,
                            )
                              ? user.cursos
                                    .filter(
                                        (cur) =>
                                            cur.idCurso === generalData.idCurso,
                                    )[0]
                                    .permisos.find(
                                        (per) => per === 'PERADMCUR06',
                                    ) !== undefined &&
                                administradoresControl.expanded
                              : administradoresControl.expanded
                          : administradoresControl.expanded
                }
                disabled={
                    user.tipo === ROLES_USUARIOS.SADM
                        ? administradoresControl.disabled
                        : user.cursos && user.cursos !== null
                          ? user.cursos.find(
                                (cur) => cur.idCurso === generalData.idCurso,
                            )
                              ? user.cursos
                                    .filter(
                                        (cur) =>
                                            cur.idCurso === generalData.idCurso,
                                    )[0]
                                    .permisos.find(
                                        (per) => per === 'PERADMCUR06',
                                    ) === undefined ||
                                administradoresControl.disabled
                              : administradoresControl.disabled
                          : administradoresControl.disabled
                }
            >
                <AccordionSummary
                    onClick={() =>
                        setAdministradoresControl({
                            ...administradoresControl,
                            expanded: !administradoresControl.expanded,
                        })
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel-administradores-content'
                    id='panel-administradores-header'
                >
                    <CheckCircleIcon
                        style={colorDone}
                        className={
                            administradoresControl.complete
                                ? classes.display
                                : classes.displayNone
                        }
                    />
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                            <b>Asignar administradores</b>
                        </Typography>
                    </div>
                </AccordionSummary>

                <Divider />

                <AccordionDetails className={classes.details}>
                    <AsignarAdministrador
                        idCurso={generalData.idCurso}
                        adminsCourse={adminsCourse}
                        setAdminsCourse={setAdminsCourse}
                    />
                </AccordionDetails>

                <AccordionActions>
                    <Button onClick={saveAdmins} size='small' color='primary'>
                        GUARDAR
                    </Button>
                </AccordionActions>
            </Accordion>

            <Accordion
                expanded={
                    user.tipo === ROLES_USUARIOS.SADM
                        ? usuariosControl.expanded
                        : user.cursos && user.cursos !== null
                          ? user.cursos.find(
                                (cur) => cur.idCurso === generalData.idCurso,
                            )
                              ? user.cursos
                                    .filter(
                                        (cur) =>
                                            cur.idCurso === generalData.idCurso,
                                    )[0]
                                    .permisos.find(
                                        (per) => per === 'PERADMCUR04',
                                    ) !== undefined && usuariosControl.expanded
                              : usuariosControl.expanded
                          : usuariosControl.expanded
                }
                disabled={
                    user.tipo === ROLES_USUARIOS.SADM
                        ? usuariosControl.disabled
                        : user.cursos && user.cursos !== null
                          ? user.cursos.find(
                                (cur) => cur.idCurso === generalData.idCurso,
                            )
                              ? user.cursos
                                    .filter(
                                        (cur) =>
                                            cur.idCurso === generalData.idCurso,
                                    )[0]
                                    .permisos.find(
                                        (per) => per === 'PERADMCUR04',
                                    ) === undefined || usuariosControl.disabled
                              : usuariosControl.disabled
                          : usuariosControl.disabled
                }
            >
                <AccordionSummary
                    onClick={() =>
                        setUsuariosControl({
                            ...usuariosControl,
                            expanded: !usuariosControl.expanded,
                        })
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel-usuarios-content'
                    id='panel-usuarios-header'
                >
                    <CheckCircleIcon
                        style={colorDone}
                        className={
                            usuariosControl.complete
                                ? classes.display
                                : classes.displayNone
                        }
                    />
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                            <b>Asignar usuarios</b>
                        </Typography>
                    </div>
                </AccordionSummary>

                <Divider />

                <AccordionDetails className={classes.details}>
                    <AsignarUsuarios
                        idCurso={generalData.idCurso}
                        usersCourse={usersCourse}
                        setUsersCourse={setUsersCourse}
                    />
                </AccordionDetails>

                <AccordionActions>
                    <Button onClick={saveUsers} size='small' color='primary'>
                        GUARDAR
                    </Button>
                </AccordionActions>
            </Accordion>

            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <Snackbars options={snackbar} />
        </div>
    );
}
