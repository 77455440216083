import {
    GET_ADMINISTRADORES,
    ADD_ADMINISTRADOR,
    ADD_ALL_ADMINS,
    UPDATE_ADMIN,
    DELETE_ADMIN_ACTION,
    INH_ADMIN_ACTION,
    HAB_ADMIN_ACTION,
    EDITAR_PERMISOS_ACTION,
    DELETE_ADMIN_COURSE_ACTION,
    UPDATE_PERMISOS_ADMIN_CURSO,
} from './administradores-actions';
import { ActionReducer, InitialStateAdmins } from '../../interfaces';
import { Administrador } from './administradores.interfaces';

const initialState: InitialStateAdmins = {
    inicial: true,
    administradores: [],
};

const adminReducer = (state = initialState, actions: ActionReducer) => {
    switch (actions.type) {
        case UPDATE_PERMISOS_ADMIN_CURSO:
            return {
                ...state,
                administradores: state.administradores.map(
                    (admin: Administrador) =>
                        admin.idAdministrador === actions.idAdministrador
                            ? {
                                  ...admin,
                                  cursos: admin.cursos.map((cur) =>
                                      cur.idCurso !== actions.idCurso
                                          ? cur
                                          : {
                                                ...cur,
                                                permisos:
                                                    actions.permisosAdminCurso,
                                            },
                                  ),
                              }
                            : admin,
                ),
            };

        case DELETE_ADMIN_COURSE_ACTION:
            return {
                ...state,
                administradores: state.administradores.map(
                    (admin: Administrador) =>
                        admin.idAdministrador === actions.idAdministrador
                            ? {
                                  ...admin,
                                  cursos: admin.cursos.filter(
                                      (cur) => cur.idCurso !== actions.idCurso,
                                  ),
                              }
                            : admin,
                ),
            };

        case GET_ADMINISTRADORES:
            return state;

        case ADD_ADMINISTRADOR:
            return {
                ...state,
                administradores: [...state.administradores, actions.payload],
            };

        case ADD_ALL_ADMINS:
            return actions.payload;

        case DELETE_ADMIN_ACTION:
            return {
                ...state,
                administradores: state.administradores.filter(
                    (admin: Administrador) =>
                        admin.idAdministrador !== actions.idAdministrador,
                ),
            };

        case INH_ADMIN_ACTION:
            return {
                ...state,
                administradores: state.administradores.map(
                    (admin: Administrador) =>
                        admin.idAdministrador === actions.idAdministrador
                            ? { ...admin, banInactivo: true }
                            : admin,
                ),
            };

        case HAB_ADMIN_ACTION:
            return {
                ...state,
                administradores: state.administradores.map(
                    (admin: Administrador) =>
                        admin.idAdministrador === actions.idAdministrador
                            ? { ...admin, banInactivo: false }
                            : admin,
                ),
            };

        case UPDATE_ADMIN:
            return {
                ...state,
                administradores: state.administradores.map((admin: any) =>
                    admin.idAdministrador === actions.payload.idAdministrador
                        ? {
                              ...admin,
                              nombres: actions.payload.nombres,
                              apellidos: actions.payload.apellidos,
                              correo: actions.payload.correo,
                              puesto: actions.payload.puesto,
                              celular: actions.payload.celular,
                              nombreCompleto: `${actions.payload.nombres} ${actions.payload.apellidos}`,
                          }
                        : admin,
                ),
            };

        case EDITAR_PERMISOS_ACTION:
            return {
                ...state,
                administradores: state.administradores.map(
                    (admin: Administrador) =>
                        admin.idAdministrador ===
                        actions.editPermisosAdmin.idAdministrador
                            ? {
                                  ...admin,
                                  permisos: actions.editPermisosAdmin.permisos,
                              }
                            : admin,
                ),
            };

        default:
            return state;
    }
};

export default adminReducer;
