import { makeStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import { green, yellow } from '@material-ui/core/colors';

export const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: yellow[600],
        },
    },
    completed: {
        '& $line': {
            borderColor: green[500],
        },
    },
    line: {
        borderColor: yellow[100],
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

export const useQontoStepIconStyles = makeStyles({
    root: {
        color: yellow[100],
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: yellow[600],
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: green[500],
        zIndex: 1,
        fontSize: 18,
    },
});
