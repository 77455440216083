import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from '../header-ccap.styles-matui';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import { useCookies } from 'react-cookie';
import {
    NAME_COOKIE_USERS,
    VACIO,
    NAME_COOKIE_ADMINS,
    ROLES_USUARIOS,
    PERFIL,
    PASSWORD,
    CURSOS,
    USUARIOS,
    REPORTES,
    ADMINISTRADORES,
    LOGOUT,
    CURSOS_ADMIN,
    HOME,
    PERFIL_ADMIN,
    DATA_USER_INIT,
} from '../../../constants';
import { DataControlModalParent } from '../../../interfaces';
import ModalCambiarPassword from '../../cambiar-password/cambiar-password.compt';
import SchoolIcon from '@material-ui/icons/School';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeIcon from '@material-ui/icons/Home';
import ModalEditarAdministrador from '../../administradores/editar-admin/editar-admin.compt';
import { EditAdministrador } from '../../administradores/administradores.interfaces';
import { setUserLoginAction } from '../../login/login.actions';
import { deleteDataLogin, persistDataLogin } from '../../login/login.service';
import { useFirebaseApp } from 'reactfire';
import ModalEditarUsuario from '../../usuarios/editar-usuario/editar-usuario.compt';
import { EditUsuario } from '../../usuarios/usuarios.interfaces';
import { Reducers } from '../../../store';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { appUrl } from '../../../config';

export default function MenuHeader() {
    const firebase = useFirebaseApp(); // Access the initialized Firebase app
    const storage = getStorage(firebase); // Get the Storage instance
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [, removeCookie] = useCookies();
    const [openDrawer, setOpenDrawer] = useState(false);
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as any;
    const [chgPass, setChgPass] = useState<DataControlModalParent>({
        open: false,
    });
    const dataEditInit: EditAdministrador = {
        idAdministrador: VACIO,
        nombres: VACIO,
        apellidos: VACIO,
        correo: VACIO,
        celular: VACIO,
        puesto: VACIO,
        banPerfil: false,
    };

    const dataEditInitUser: EditUsuario = DATA_USER_INIT;

    const [editarData, setEditarData] = useState<DataControlModalParent>({
        open: false,
        dataEditAdmin: dataEditInit,
    });
    const [editarDataUser, setEditarDataUser] =
        useState<DataControlModalParent>({
            open: false,
            dataEditUser: dataEditInitUser,
        });

    const toggleDrawer =
        () => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpenDrawer((previusValue) => !previusValue);
        };

    const closeModalRecuperarPassword = (value: boolean) => {
        setChgPass({ open: false, closeParent: closeModalRecuperarPassword });
    };

    const handleModalRecuperarPassword = (uid: string) => {
        setChgPass({
            open: true,
            closeParent: closeModalRecuperarPassword,
            uid,
        });
    };

    const closeModalEdicionUser = (data: any) => {
        setEditarDataUser({
            open: false,
            closeParent: closeModalEdicionUser,
            dataEditUser: dataEditInitUser,
        });

        if (data) {
            const newdataUser = {
                ...user,
                ...data,
                nombreCompleto: `${data.nombres} ${data.apellidos}`,
            };

            if (
                data.fileFoto &&
                data.fileFoto !== undefined &&
                data.fileFoto !== null
            ) {
                const fileRef = ref(
                    storage,
                    `/perfiles/${user.idUsuario}/${data.nombreFoto}`,
                );
                uploadBytes(fileRef, data.fileFoto)
                    .then(() => {
                        persistDataLogin(newdataUser, NAME_COOKIE_USERS);
                        dispatch({
                            type: setUserLoginAction.type,
                            data: newdataUser,
                        });
                    })
                    .catch(() => console.error('error la cargar foto'));
            } else {
                persistDataLogin(newdataUser, NAME_COOKIE_USERS);
                dispatch({ type: setUserLoginAction.type, data: newdataUser });
            }
        }
    };

    const closeModalEdicion = (data: any) => {
        setEditarData({
            open: false,
            closeParent: closeModalEdicion,
            dataEditAdmin: dataEditInit,
        });

        if (data) {
            const newdataUser = {
                ...user,
                ...data,
                nombreCompleto: `${data.nombres} ${data.apellidos}`,
            };

            if (
                data.fileFoto &&
                data.fileFoto !== undefined &&
                data.fileFoto !== null
            ) {
                const fileRef = ref(
                    storage,
                    `/perfiles/${user.idAdministrador}/${data.nombreFoto}`,
                );
                uploadBytes(fileRef, data.fileFoto)
                    .then(() => {
                        persistDataLogin(newdataUser, NAME_COOKIE_ADMINS);
                        dispatch({
                            type: setUserLoginAction.type,
                            data: newdataUser,
                        });
                    })
                    .catch(() => console.error('error la cargar foto'));
            } else {
                persistDataLogin(newdataUser, NAME_COOKIE_ADMINS);
                dispatch({ type: setUserLoginAction.type, data: newdataUser });
            }
        }
    };

    const handleModalEditarUser = () => {
        setEditarDataUser({
            open: true,
            closeParent: closeModalEdicionUser,
            dataEditUser: {
                ...user,
                banPerfil: true,
            },
        });
    };

    const handleModalEditar = () => {
        setEditarData({
            open: true,
            closeParent: closeModalEdicion,
            dataEditAdmin: {
                idAdministrador: user.idAdministrador,
                nombres: user.nombres,
                apellidos: user.apellidos,
                correo: user.correo,
                celular: user.celular,
                puesto: user.puesto,
                banPerfil: true,
                foto: user.foto,
            },
        });
    };

    const handleOptionMenu = (option: string) => {
        switch (option) {
            case LOGOUT:
                removeCookie(NAME_COOKIE_USERS, VACIO, {
                    path: '/',
                    expires: new Date(1970, 1, 1),
                });
                removeCookie(NAME_COOKIE_USERS, VACIO, {
                    path: '/ccap/users',
                    expires: new Date(1970, 1, 1),
                });
                deleteDataLogin(NAME_COOKIE_USERS);

                removeCookie(NAME_COOKIE_ADMINS, VACIO, {
                    path: '/',
                    expires: new Date(1970, 1, 1),
                });
                removeCookie(NAME_COOKIE_ADMINS, VACIO, {
                    path: '/ccap/admin',
                    expires: new Date(1970, 1, 1),
                });
                deleteDataLogin(NAME_COOKIE_ADMINS);

                localStorage.clear();
                window.location.href = appUrl;
                break;

            case PASSWORD:
                handleModalRecuperarPassword(user.idAdministrador);
                break;

            case PERFIL:
                handleModalEditarUser();
                break;

            case CURSOS:
                navigate('/ccap/users/cursos');
                break;

            case CURSOS_ADMIN:
                navigate('/ccap/admin/cursos/lista');
                break;

            case USUARIOS:
                navigate('/ccap/admin/usuarios');
                break;

            case REPORTES:
                navigate('/ccap/admin/reportes');
                break;

            case ADMINISTRADORES:
                navigate('/ccap/admin/administradores');
                break;

            case HOME:
                navigate('/ccap/admin/menu');
                break;

            case PERFIL_ADMIN:
                handleModalEditar();
                break;

            default:
                break;
        }
    };

    return (
        <div>
            <IconButton
                onClick={toggleDrawer()}
                size='medium'
                edge='start'
                className={classes.menuButton}
                color='secondary'
                aria-label='mvac'
            >
                <MenuIcon />
            </IconButton>

            <Drawer anchor='left' open={openDrawer} onClose={toggleDrawer()}>
                <div
                    className={classes.list}
                    role='presentation'
                    onClick={toggleDrawer()}
                    onKeyDown={toggleDrawer()}
                >
                    <List>
                        <ListItem
                            button
                            onClick={() => handleOptionMenu(HOME)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<HomeIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(PERFIL)}
                            className={
                                user.tipo !== ROLES_USUARIOS.USRO
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<PersonIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Perfil' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(PERFIL_ADMIN)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<PersonIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Perfil' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(PASSWORD)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<LockIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Contraseña' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(CURSOS)}
                            className={
                                user.tipo !== ROLES_USUARIOS.USRO
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<SchoolIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Cursos' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(USUARIOS)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<PeopleAltIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Usuarios' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(CURSOS_ADMIN)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<SchoolIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Cursos' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(REPORTES)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<AssessmentIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Reportes' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(ADMINISTRADORES)}
                            className={
                                user.tipo !== ROLES_USUARIOS.ADMN &&
                                user.tipo !== ROLES_USUARIOS.SADM
                                    ? classes.displayNone
                                    : VACIO
                            }
                        >
                            <ListItemIcon>
                                {<SupervisorAccountIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Administradores' />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => handleOptionMenu(LOGOUT)}
                        >
                            <ListItemIcon>
                                {<ExitToAppIcon color='primary' />}
                            </ListItemIcon>
                            <ListItemText primary='Salir' />
                        </ListItem>
                    </List>
                </div>
            </Drawer>

            <ModalCambiarPassword options={chgPass} />
            <ModalEditarUsuario
                open={editarDataUser.open}
                closeParent={editarDataUser.closeParent}
                dataUser={editarDataUser.dataEditUser}
            />
            <ModalEditarAdministrador
                open={editarData.open}
                closeParent={editarData.closeParent}
                dataAdmin={editarData.dataEditAdmin}
            />
        </div>
    );
}
