import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        modal: {
            padding: '16px',
        },
        titleForm: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
            margin: '10px 0px',
            color: '#263680',
            fontSize: '25px',
        },
        formControl: {
            maxWidth: '400px',
            width: '100%',
            marginBottom: '16px',
        },
        buttonLogin: {
            maxWidth: '400px',
            width: '100%',
            padding: '6px',
            fontSize: '30px',
        },
        dialogActions: {
            padding: '0px 16px',
            margin: '0px',
            width: 'calc(100% - 32px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '32px',
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'calc(100% - 32px)',
            padding: '16px',
            margin: '0px',
        },
        iconForm: {
            fontSize: '100px',
        },
    }),
);
