import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './components/administradores/administradores-reducer';
import userReducer from './components/usuarios/usuarios-reducer';
import cursosReducer from './components/cursos/cursos-reducer';
import loginReducer from './components/login/login.reducer';
import courseSelectReducer from './components/courses-users/courses-reducer';

export interface Reducers {
    adminReducer: any;
    userReducer: any;
    cursosReducer: any;
    loginReducer: any;
    courseSelectReducer: any;
}

const rootReducer = combineReducers<Reducers>({
    adminReducer,
    userReducer,
    cursosReducer,
    loginReducer,
    courseSelectReducer,
});

export default configureStore({ reducer: rootReducer });
