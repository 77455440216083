import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            padding: '16px',
        },
        button: {
            marginBottom: '8px',
        },
        title: {
            textAlign: 'center',
        },
    }),
);
