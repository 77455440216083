import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import AppRputing from './AppRouting';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme.styles';
import { Provider } from 'react-redux';
import store from './store';
import { FirebaseAppProvider } from 'reactfire';
import { CookiesProvider } from 'react-cookie';
import './index.sass';
import { firebaseConfig } from './config';

const domNode = document.getElementById('root') as HTMLElement;
const root = createRoot(domNode);

root.render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                    <CookiesProvider>
                        <AppRputing />
                    </CookiesProvider>
                </FirebaseAppProvider>
            </Provider>
        </ThemeProvider>
    </ApolloProvider>,
);

reportWebVitals();
