export const persistDataLogin = (data: any, name: string) => {
    localStorage.setItem(name, JSON.stringify(data));
};

export const deleteDataLogin = (name: string) => {
    localStorage.removeItem(name);
};

export const getDataLogin = (name: string) => {
    return localStorage.getItem(name);
};
