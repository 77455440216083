import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/core';
import { themeButtons } from '../../theme.styles';
import { useStyles } from './modal-confirmaciones.styles-matui';
import './modal-confirmaciones.sass';

export default function ModalConfirmaciones(props) {
    const classes = useStyles();

    const handleCancel = () => {
        props.options.closeParent(
            false,
            props.options.accion,
            props.options.uid,
        );
    };

    const handleAccept = () => {
        props.options.closeParent(
            true,
            props.options.accion,
            props.options.course
                ? {
                      uid: props.options.uid,
                      course: props.options.course,
                  }
                : props.options.uid,
        );
    };

    return (
        <div>
            <Dialog
                className={classes.modal}
                open={props.options.open}
                onClose={handleCancel}
                aria-describedby='alert-dialog-title'
            >
                <DialogTitle className={classes.title} id='alert-dialog-title'>
                    {props.options.descripcion}
                </DialogTitle>
                <DialogActions>
                    <ThemeProvider theme={themeButtons}>
                        <div className='containerActionsConfirmaciones'>
                            <div>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    onClick={handleCancel}
                                    color='secondary'
                                    autoFocus
                                >
                                    Cancelar
                                </Button>
                            </div>
                            <div>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    onClick={handleAccept}
                                    color='primary'
                                >
                                    Aceptar
                                </Button>
                            </div>
                        </div>
                    </ThemeProvider>
                </DialogActions>
            </Dialog>
        </div>
    );
}
