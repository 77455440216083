import { VACIO } from '../../constants';

export const GET_USUARIOS = 'get_users';
export const ADD_USUARIO = 'add_user';
export const ADD_ALL_USERS = 'add_all_user';
export const UPDATE_USER = 'update_user';
export const DELETE_USER_ACTION = 'delete_user';
export const INH_USER_ACTION = 'inh_user';
export const HAB_USER_ACTION = 'hab_user';
export const DELETE_CURSO_USER_ACTION = 'delete_course_user';
export const INH_CURSO_USER_ACTION = 'inh_course_user';
export const HAB_CURSO_USER_ACTION = 'hab_course_user';
export const HAB_CERTIFICADO = 'hab_certificado';

export const delCurUser = {
    type: DELETE_CURSO_USER_ACTION,
    idUsuario: VACIO,
    idCurso: VACIO,
};

export const inhCurUser = {
    type: INH_CURSO_USER_ACTION,
    idUsuario: VACIO,
    idCurso: VACIO,
};

export const habCurUser = {
    type: HAB_CURSO_USER_ACTION,
    idUsuario: VACIO,
    idCurso: VACIO,
};

export const habCertificadoCur = {
    type: HAB_CERTIFICADO,
    idUsuario: VACIO,
    idCurso: VACIO,
};

export const getAllUsers = {
    type: GET_USUARIOS,
};

export const addUsuario = {
    type: ADD_USUARIO,
};

export const addAllUsers = {
    type: ADD_ALL_USERS,
    payload: [],
};

export const updateUser = {
    type: UPDATE_USER,
    payload: {},
};

export const delUserAction = {
    type: DELETE_USER_ACTION,
    idUsuario: '',
};

export const inhUserAction = {
    type: INH_USER_ACTION,
    idUsuario: '',
};

export const habUserAction = {
    type: HAB_USER_ACTION,
    idUsuario: '',
};
