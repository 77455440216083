import { useSelector } from 'react-redux';
import Regresar from '../regresar/regresar.compt';
import { Reducers } from '../../store';
import { Administrador } from '../administradores/administradores.interfaces';
import { Navigate } from 'react-router-dom';

export default function NotFoundComponent() {
    const user = useSelector<Reducers>(
        (store) => store.loginReducer,
    ) as Administrador;

    if (!user || !Object.keys(user).length) {
        return <Navigate to='/' />;
    }

    return (
        <div>
            <Regresar
                route={
                    user.idAdministrador
                        ? '/ccap/admin/menu'
                        : '/ccap/users/cursos'
                }
            />
            Página no encontrada
        </div>
    );
}
